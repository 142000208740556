import React from 'react';

export default function AddProductIcon(props) {
  return (
    <svg 
      width="24" 
      height="24" 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 100 100"
      {...props}
    >
      <rect x="10" y="10" width="80" height="80" rx="10" fill="#b4141a" />

      <g transform="scale(2.5), translate(20, 0)">
        <circle cx="10" cy="10" r="10" fill="green" />
        <line x1="10" y1="5" x2="10" y2="15" stroke="white" stroke-width="2" />
        <line x1="5" y1="10" x2="15" y2="10" stroke="white" stroke-width="2" />
      </g>
    </svg>
  );
}
