import { UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Form, Modal, Select, Upload, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { UserContext } from '../../../App';
import { SERVER_URL } from '../../../config';
import { useTimesheetContext, useTimesheetContextDispatch } from '../../../context/TimesheetContext';
import moment from 'moment';

const { Option } = Select;

export default function TerminateEmployeeModal({open, employee, company, selectedDate,handleSuccess, handleClose}) {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [isError, setIsError] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [createTerminationDocChecked, setCreateTerminationDocChecked] = useState(false);
  const [isModalClosable, setIsModalClosable] = useState(true);

  const initialValues = {};

  const { terminateEmploymentFormData, extendEmploymentFormData } = useTimesheetContext();
  const { setTerminateEmploymentData, resetExtendEmploymentData } = useTimesheetContextDispatch();

  const onFinish = (formData) => {
    let attachments = [];
    for (let i = 0; i < files.length; i++) {
      attachments.push({
        location: files[i].location,
        originalName: files[i].originalName,
      });
    }
    formData.fileList = fileList;
    formData.files = files;
    formData.createTerminationDoc = createTerminationDocChecked;
    formData.attachments = attachments;
    onSubmit(formData);

  }

  const onSubmit = async (formData) => {
    formData.user = currentuser.data;
    formData.employeeObj = employee;

    if (!!Object.keys(extendEmploymentFormData).length) {
      resetExtendEmploymentData();
    }

    setTerminateEmploymentData(formData);
    handleSuccess();
    handleClose();
  }

  useEffect(() => {

    return () => {
      form.resetFields();
      setFiles([]);
      setFileList([]);
    }
  }, [form]);

  useEffect(() => {
    if (!!Object.keys(terminateEmploymentFormData).length) {
      setCreateTerminationDocChecked(terminateEmploymentFormData.createTerminationDoc);
      setFileList(terminateEmploymentFormData.fileList);
      setFiles((prevState) => [...prevState, ...terminateEmploymentFormData.files]);
    }
    form.setFieldsValue({
      company: company._id,
      employee: employee._id,
      endDate: moment(selectedDate, 'YYYY-MM-DD'),
    })
    
  }, [employee, company]);

  return (
    <Modal
      centered
      width={800}
      open={open}
      closable={isModalClosable}
      onCancel={handleClose}
      title={`Terminate ${employee.firstName} ${employee.lastName}`}
      footer={[
        <Button
            key='cancel'
            type='danger'
            id="terminateCancel"
            onClick={handleClose}
            style={{marginLeft: '10px'}}
        >
            Cancel
        </Button>,
        <Button
            type='primary'
            id="terminateSubmit"
            onClick={() => {
                onFinish(form.getFieldsValue());
              }
            }
        >
            Apply
        </Button>
      ]}
    >
      <Form
        name='basic'
        initialValues={initialValues}
        onFinish={(values) => {
          document.getElementById('terminate').disabled = true;
          onFinish(values);
        }}
        layout='horizontal'
        form={form}
      >
          <Form.Item
            label='Choose company'
            name='company'
            hidden={true}
          >
            <Select
              allowClear={true}
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => {
                localStorage.setItem('selectedCompany', value);
              }}
              style={{ marginLeft: '3px', width: '99.5%' }}
              disabled
            >
              {company ?
                  <Option value={company._id} key={company._id}>
                    {company.name}
                  </Option> : 
                  <Option>
                    Unknown company
                  </Option>
                }
            </Select>
          </Form.Item>
        <Form.Item
          label='Choose employee'
          name='employee'
          hidden={true}
        >
          <Select
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) =>
              option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled
          >
            {employee ?
              <Option value={employee._id} key={employee._id}>
                {employee.firstName} {employee.lastName} - {employee.JMBG}
              </Option>
              : 
              <Option>
                Unknown Employee  
              </Option>}
          </Select>
        </Form.Item>

        <Form.Item
          label='End date of work'
          name='endDate'
          hidden={true}
          rules={[
            {
              required: true,
              message: 'Please select end date!',
            },
          ]}
        >
          <DatePicker format='DD-MM-YYYY' style={{ marginLeft: '6px' }} />
        </Form.Item>
        
        <div style={{ display: 'flex' }}>
          <Form.Item name='fileOption' label='Attach file' className='attach'>
            <Upload
              fileList={fileList.length === 0 && !isError ? undefined : isError ? [] : [...fileList]}
              listType='picture'
              action={`${SERVER_URL}/upload-s3?employee=${JSON.stringify({
                employeeID: employee && Object.keys(employee)?.length !== 0 && employee.JMBG,
                employeeName:
                  employee &&
                  Object.keys(employee)?.length !== 0 &&
                  employee.firstName + '_' + employee.lastName,
              })}`}
              name='file'
              headers={{
                Authorization: `Bearer ${currentuser.data.token}`,
                uri: 'static/pdf/',
                timestamp: Date.now().toString(),
              }}
              onChange={(info) => {
                if (!info.file.status) {
                  setIsError(true);
                }
                if (info.file.status === 'done') {
                  setIsError(false);
                  setFiles([...files, { location: info.file.response.key, originalName: info.file.name, typeOfFile: 'terminationUpload' }]);
                  for (const file of info.fileList) {
                    let nameArray = file.name.split('.');
                    switch (nameArray[nameArray.length - 1]) {
                      case 'pdf':
                        file.thumbUrl = '/pdf.png';
                        break;
                      case 'docx':
                        file.thumbUrl = '/docx.png';
                        break;
                      case 'png':
                        file.thumbUrl = '/png.png';
                        break;
                      case 'jpg':
                        file.thumbUrl = '/jpg.png';
                        break;
                      case 'xlsx':
                        file.thumbUrl = '/xlsx.png';
                        break;

                      default:
                        file.thumbUrl = '/other.png';
                        break;
                    }
                  }
                  notification.success({
                    message: 'Upload successfull',
                    placement: 'bottomRight',
                  });
                } else if (info.file.status === 'error') {
                  notification.error({
                    message: 'Upload failed',
                    placement: 'bottomRight',
                  });
                }
                setFileList(info.fileList.filter((item) => item.status && item.status !== 'error'));
              }}
              beforeUpload={(file) => {
                setIsError(false);
                if (Object.keys(employee).length === 0) {
                  notification.error({
                    message: 'You must choose employee before upload.',
                    placement: 'bottomRight',
                  });
                  return false;
                }
                if (file.size / 1000000 > 5) {
                  notification.error({
                    message: 'File is larger than 5 MB!',
                    placement: 'bottomRight',
                  });
                  return false;
                }
                return true;
              }}
              onRemove={async (file) => {
                notification.info({
                  message: 'Removing file in progress.',
                  placement: 'bottomRight',
                });
                let newFileList = [...fileList];
                let newFiles = [...files];
                let foundFile;
                for (let i = 0; i < fileList.length; i++) {
                  // This part of code is never going to work
                  if (fileList[i].name === file.name) {
                    foundFile = fileList[i];
                    newFileList.splice(i, 1);
                    // data is array of objects, this can not work....

                    // data is array of objects, this can not work....
                          // TODO: Investigate what was this supposed to do - possibly new attachments to be updated after removal
                    // if (data && data.attachments && data.attachments.length !== 0) {
                    //   data.attachments.splice(i, 1);
                    //   await Axios.put(
                    //     `${SERVER_URL}/data/${data._id}`,
                    //     { attachments: data.attachments },
                    //     {
                    //       withCredentials: false,
                    //       headers: { Authorization: `Bearer ${currentuser.data.token}` },
                    //     },
                    //   );
                    // }
                  }
                }
                for (let i = 0; i < files.length; i++) {
                  if (files[i].originalName === file.name) {
                    newFiles.splice(i, 1);
                  }
                }
                let linkParts = foundFile && foundFile.response && foundFile.response.key.split('/');
                if (linkParts && linkParts.length !== 0) {
                  await Axios.post(`${SERVER_URL}/remove-file/${linkParts[0]}/${linkParts[1]}`, {
                    withCredentials: false,
                  });
                }
                setFileList(newFileList);
                setFiles(newFiles);
              }}
            >
              <Button
                id='uploadButton'
                icon={<UploadOutlined />}
                style={{ marginLeft: '65px', marginBottom: '10px' }}
              >
                Upload
              </Button>
            </Upload>
          </Form.Item>
        </div>
        <Form.Item name='createTerminationDoc' value={createTerminationDocChecked}>
          <Checkbox
            checked={createTerminationDocChecked}
            onChange={(e) => setCreateTerminationDocChecked(e.target.checked)}
          >
            <span>Create termination document</span>
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  )
}
