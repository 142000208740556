import React, { useEffect, useContext } from 'react';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import InvoiceForm from '../../components/forms/InvoiceForm';

const EditInvoice = (props) => {
  const currentuser = useContext(UserContext);
  const [invoiceData, fetchInvoiceData] = useAxios('', [], currentuser.data.token, 'get');
  const { _id } = props.match.params;

  const selectedEInvoiceCompanyId = (() => {
    const storedCompanyId = localStorage.getItem('selectedEInvoiceCompany');
    const companies = Array.isArray(currentuser?.data?.company) ? currentuser.data.company : [currentuser?.data?.company].filter(Boolean);
    const companyIds = companies.length > 0 ? companies.map(item => item._id) : [];

    return companyIds.includes(storedCompanyId)
        ? storedCompanyId
        : companies.length > 0 ? companies[0]?._id : null;
  })();

  useEffect(() => {
    if (selectedEInvoiceCompanyId) {
      fetchInvoiceData(`${SERVER_URL}/view-sales/${_id}?filter=${JSON.stringify(selectedEInvoiceCompanyId)}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
    }
  }, [selectedEInvoiceCompanyId]);

  return <InvoiceForm isNew={false} SERVER_URL={SERVER_URL} data={invoiceData} invoiceId={_id} />;
};

export default EditInvoice;
