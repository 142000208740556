import { Button, notification, Spin } from 'antd';
import { formatDataTime, formatLocalNumber } from '../../../../helpers/dates';
import { getInputFieldValue, getValueByKey, hasRequestPermission, useLocalTranslations } from '../../../../utils';
import { useHistory, useLocation } from 'react-router-dom';
import { cn } from '../../../../libs';
import { acceptOfficialTravelById } from '../../../../services/official-travel.js';
import { useContext, useMemo, useState } from 'react';
import { UserContext } from '../../../../App.js';
import StepControl from '../StepControl.js';

export default function FinishTravelOrder({ formInit, onChangeStep, stepLang, currentStep }) {
  const user = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();

  const hasEditDisabled = hasRequestPermission(pathname, 'edit');
  const hasViewDisabled = hasRequestPermission(pathname, 'view');

  const { lt } = useLocalTranslations(stepLang);

  function handleBack() {
    history.goBack();
  }

  const tableKeys = useMemo(() => {
    if (stepLang === 'rs') {
      return ['Br.', 'BROJ FAKTURE', 'TIP FAKTURE', 'OPIS', 'IZNOS', 'VALUA', 'PLAĆENO KARTICOM'];
    }

    return ['NO.', 'INVOICE NUMBER', 'INVOICE TYPE', 'DESCRIPTION', 'AMOUNT', ' CURRENCY', 'PAID BY CARD'];
  }, [stepLang]);


  if (!formInit) {
    return null;
  }

  const { typeOfLeave } = formInit;

  // TODO: Travel Order
  const travelOrder = getValueByKey('travelOrder', formInit);

  // TODO: Travel Calculation
  const travelCalculation = getValueByKey('travelOrderCalculation', formInit);

  // TODO: Travel Invoices
  const travelInvoices = getValueByKey('travelInvoices', formInit);
  const processDataSource = (travelInvoices) => {
    const sortedData = travelInvoices?.sort((a, b) => {
      const order = ['Accommodation', 'Transportation', 'Other'];
      return order.indexOf(a.travelInvoiceType) - order.indexOf(b.travelInvoiceType);
    });

    const groupedData = sortedData.reduce((acc, invoice) => {
      if (!acc[invoice.travelInvoiceCurrency]) {
        acc[invoice.travelInvoiceCurrency] = [];
      }
      acc[invoice.travelInvoiceCurrency].push(invoice);
      return acc;
    }, {});

    const summaryData = {};
    for (const [currency, invoices] of Object.entries(groupedData)) {
      summaryData[currency] = invoices.reduce((acc, invoice) => {
        if (!acc[invoice.travelInvoiceType]) {
          acc[invoice.travelInvoiceType] = 0;
        }
        acc[invoice.travelInvoiceType] += parseFloat(invoice.travelInvoiceAmount || 0);
        return acc;
      }, {});
    }

    const result = [];
    let index = 1;

    for (const [currency, invoices] of Object.entries(groupedData)) {
      let typeOrder = ['Accommodation', 'Transportation', 'Other'];

      typeOrder.forEach((type) => {
        invoices
          .filter((invoice) => invoice.travelInvoiceType === type)
          .forEach((invoice) => {
            result.push({ ...invoice, index: index++ });
          });

        if (summaryData[currency][type] !== undefined) {
          result.push({
            key: `summary-${currency}-${type}`,
            travelInvoiceNumber: lt('travelGlobal.costSummary'),
            travelInvoiceType: `${type}`,
            travelInvoiceDescription: '',
            travelInvoiceAmount: summaryData[currency][type],
            travelInvoiceCurrency: currency,
            isTravelInvoicePaidByCard: '',
            index: '',
            isSummary: true,
          });
        }
      });
    }

    return result;
  };
  const dataSource = processDataSource(travelInvoices);
  const currencies = [...new Set(travelInvoices?.map((invoice) => invoice.travelInvoiceCurrency))];

  const dataByCurrency = currencies.reduce((acc, currency) => {
    acc[currency] = dataSource.filter((item) => item.travelInvoiceCurrency === currency);
    return acc;
  }, {});

  

  const isRsdCurrencty = dataByCurrency['RSD'];
  const isOtherCurrency = currencies.some((currency) => currency !== 'RSD');

  const hasDataNotFound = isRsdCurrencty || isOtherCurrency;

  const isMarkRow = (idx) => {
    if (idx !== '') return false;
    return true;
  };

  const hasStrongTableLabel = (idx, label) => {
    if (isMarkRow(idx)) {
      return <strong>{label}</strong>;
    }

    return <span>{label}</span>;
  };

  const handleScrollTopAndStep = (step) => {
    onChangeStep(step);

    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await acceptOfficialTravelById(user.data.token, formInit._id);

      notification.success({
        message: 'The official travel order has been accepted.',
        placement: 'bottomRight',
      });

      history.goBack();
    } catch (error) {
      console.error('error in accept official travel', error);
      notification.error({
        message: `Problem with accepting travel order. ${error.message}`,
        placement: 'bottomRight',
        duration: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  const transformedPaidFood = (foodList) => {
    const keys = {
      'Breakfast': stepLang === 'en' ? foodList : 'Doručak',
      'Lunch': stepLang === 'en' ? foodList : 'Ručak',
      'Dinner': stepLang === 'en' ? foodList : 'Večera',
      'Breakfast&Lunch&Dinner': stepLang === 'en' ? foodList : 'Doručak&Ručak&Večera',
      'Breakfast&Lunch': stepLang === 'en'? foodList : 'Doručak&Ručak',
      'Lunch&Dinner': stepLang === 'en' ? foodList : 'Ručak&Večera',
      'Dinner&Breakfast': stepLang === 'en' ? foodList : 'Večera&Doručak',
      'None': stepLang === 'en' ? foodList : 'Nijedan'
    }

    const isDefault = stepLang === 'en' ? 'None' : 'Nijedan';

    return keys[foodList] ?? isDefault;
  }

  const abroadCurrency = formInit?.travelOrder?.dailyWageForAbroadCurrency || 'EUR';

  return (
    <>
      <div className='finish-travel-order'>
        <div className='step-header'>
          <h3 className='block-title'>
            {lt('travelFinishOrder.stepTitle')} <small> ({stepLang === 'en' ? typeOfLeave: 'Službeno putovanje'})</small>
          </h3>
        </div>

        <article className='article'>
          <section className='section'>
            <SectionStickyHeader
              step={1}
              handleScrollTopAndStep={handleScrollTopAndStep}
              title={lt('travelGlobal.travelOrder')}
            />

            <div className='details'>
              <div className='left'>
                <div>
                  <p className='label'>{lt('travelOrder.travelOrderNo')}</p>
                  <span className='value'>
                    {travelOrder?.travelOrderNo ? <strong>{travelOrder?.travelOrderNo}</strong> : <span>~</span>}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrder.year')}</p>
                  <span className='value'>
                    {travelOrder?.year ? (
                      <strong>{formatDataTime(travelOrder?.year, 'DD/MM/YYYY')}</strong>
                    ) : (
                      <span>~</span>
                    )}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrder.reason')}</p>
                  <span className='value'>
                    {travelOrder?.reason ? <strong>{travelOrder?.reason}</strong> : <span>~</span>}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrder.country')}</p>
                  <span className='value'>
                    {travelOrder?.country ? <strong>{travelOrder?.country}</strong> : <span className=''>~</span>}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrder.city')}</p>
                  <span className='value'>
                    {travelOrder?.city ? <strong>{travelOrder?.city}</strong> : <span className=''>~</span>}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrder.startDate')}</p>
                  <span className='value'>
                    {travelOrder?.startDate ? (
                      <strong>{formatDataTime(travelOrder?.startDate, 'DD/MM/YYYY')}</strong>
                    ) : (
                      <span>~</span>
                    )}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrder.numberOfDays')}</p>
                  <span className='value'>
                    {travelOrder?.numberOfDays ? <strong>{travelOrder?.numberOfDays}</strong> : <span>~</span>}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrder.transportType')}</p>
                  <span className='value'>
                    {travelOrder?.transportType && travelOrder?.transportType.length ? (
                      <strong style={{ display: 'flex', gap: '5px' }}>
                        {travelOrder?.transportType.map((transport) => <span style={{ border: '1px solid #ccc', display: 'inline-flex', padding: '3px 6px', background: '#fff', borderRadius: '3px' }} key={transport}>{transport}</span>)}</strong>
                    ) : (
                      <span>~</span>
                    )}
                  </span>
                </div>
              </div>

              <div className='right'>
                <div>
                  <p className='label'>{lt('travelOrder.paidAccommodation')}</p>
                  <span className='value`'>
                    {travelOrder?.paidAccommodation ? <strong>{lt('travelGlobal.isTravelInvoicePaidByCardYes')}</strong> : <span>{lt('travelGlobal.isTravelInvoicePaidByCardNo')}</span>}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrder.paidFood')}</p>
                  <span className='value'>
                    {travelOrder?.paidFood ? <strong>{transformedPaidFood(travelOrder?.paidFood)}</strong> : <span>~</span>}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrder.paymentInAdvanceCountry')}</p>
                  <span className='value'>
                    {travelOrder?.paymentInAdvanceCountry ? (
                      <strong>
                        <span>{formatLocalNumber(travelOrder?.paymentInAdvanceCountry)}</span>
                        <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6' }}>RSD</small>
                      </strong>
                    ) : (
                      <span>~</span>
                    )}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrder.paymentInAdvanceAbroad')}</p>
                  <span className='value'>
                    {travelOrder?.paymentInAdvanceAbroad ? (
                      <strong>
                        <span>{formatLocalNumber(travelOrder?.paymentInAdvanceAbroad)}</span>
                        <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6' }}>{abroadCurrency}</small>
                      </strong>                      
                    ) : (
                      <span>~</span>
                    )}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrder.dailyWageForCountry')}</p>
                  <span className='value'>
                    {travelOrder?.dailyWageForCountry ? (
                      <strong>
                        <span>{formatLocalNumber(travelOrder?.dailyWageForCountry)}</span>
                        <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6' }}>RSD</small>
                      </strong>                         
                    ) : (
                      <span>~</span>
                    )}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrder.dailyWageForAbroadCurrency')}</p>
                  <span className='value'>
                    {travelOrder?.dailyWageForAbroadCurrency ? (
                      <strong>{travelOrder?.dailyWageForAbroadCurrency}</strong>                         
                    ) : (
                      <span>~</span>
                    )}       
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrder.dateOfSubmission')}</p>
                  <span className='value'>
                    {travelOrder?.dateOfSubmission ? (
                      <strong>{formatDataTime(travelOrder?.dateOfSubmission, 'DD/MM/YYYY')}</strong>
                    ) : (
                      <span>~</span>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </section>

          <section className='section'>
            <SectionStickyHeader
              step={2}
              handleScrollTopAndStep={handleScrollTopAndStep}
              title={lt('travelGlobal.travelCalculation')}
            />

            <div className='details'>
              <div className='left'>
                <div>
                  <p className='label'>{lt('travelOrderCalculation.totalNumberOfHours')}</p>
                  <span className='value'>
                    {travelCalculation?.totalNumberOfHours ? (
                      <strong>{travelCalculation?.totalNumberOfHours}</strong>
                    ) : (
                      <span>0</span>
                    )}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.numberOfHoursCountry')}</p>
                  <span className='value'>
                    {travelCalculation?.numberOfHoursCountry ? (
                      <strong>{travelCalculation?.numberOfHoursCountry}</strong>
                    ) : (
                      <span>~</span>
                    )}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.numberOfHoursAbroad')}</p>
                  <span className='value'>
                    {travelCalculation?.numberOfHoursAbroad ? (
                      <strong>{travelCalculation?.numberOfHoursAbroad}</strong>
                    ) : (
                      <span>0</span>
                    )}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.numberOfDailyWageForCountry')}</p>
                  <span className='value'>
                    {travelCalculation?.numberOfDailyWageForCountry ? (
                      <strong>{travelCalculation?.numberOfDailyWageForCountry}</strong>
                    ) : (
                      <span>0</span>
                    )}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.numberOfDailyWageForAbroad')}</p>
                  <span className='value'>
                    {travelCalculation?.numberOfDailyWageForAbroad ? (
                      <strong>{travelCalculation?.numberOfDailyWageForAbroad}</strong>
                    ) : (
                      <span>0</span>
                    )}
                  </span>
                </div>

                <div>
                  <p className='label'>
                    <strong>{lt('travelOrderCalculation.totalDailyWageForCountry')}</strong>
                  </p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.totalDailyWageForCountry, true)} 
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>RSD</small>
                  </span>
                </div>

                <div>
                  <p className='label'>
                    <strong>{lt('travelOrderCalculation.totalDailyWageForAbroad')}</strong>
                  </p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.totalDailyWageForAbroad, true)}
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>{abroadCurrency}</small>
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.accommodationCostsByInvoicesCountry')}</p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.accommodationCostsByInvoicesCountry, true)}
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>RSD</small>
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.accommodationCostsByInvoicesAbroad')}</p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.accommodationCostsByInvoicesAbroad, true)}
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>{abroadCurrency}</small>
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.transportationCostsByInvoicesCountry')}</p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.transportationCostsByInvoicesCountry, true)}
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>RSD</small>
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.transportationCostsByInvoicesAbroad')}</p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.transportationCostsByInvoicesAbroad, true)}
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>{abroadCurrency}</small>
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.otherCostsByInvoicesCountry')}</p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.otherCostsByInvoicesCountry, true)}
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>RSD</small>
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.otherCostsByInvoicesAbroad')}</p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.otherCostsByInvoicesAbroad, true)}
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>RSD</small>
                  </span>                  
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.endBorderCrossDateAndTime')}</p>
                  <span className='value'>
                    {travelCalculation?.endBorderCrossDateAndTime ? (
                      <strong>{formatDataTime(travelCalculation?.endBorderCrossDateAndTime, 'DD/MM/YYYY')}</strong>
                    ) : (
                      <span>~</span>
                    )}
                  </span>
                </div>
              </div>

              <div className='right'>
                <div>
                  <p className='label'>{lt('travelOrderCalculation.endDateAndTime')}</p>
                  <span className='value'>
                    {travelCalculation?.endDateAndTime ? (
                      <strong>{formatDataTime(travelCalculation?.endDateAndTime, 'DD/MM/YYYY')}</strong>
                    ) : (
                      <span>~</span>
                    )}
                  </span>
                </div>

                <div>
                  <p className='label'>
                    <strong>{lt('travelOrderCalculation.totalCostsCountry')}</strong>
                  </p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.totalCostsCountry, true)}
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>RSD</small>
                  </span>                  
                </div>

                <div>
                  <p className='label'>
                    <strong>{lt('travelOrderCalculation.totalCostsAbroad')}</strong>
                  </p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.totalCostsAbroad, true)}
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>{abroadCurrency}</small>
                  </span>                   
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.totalCalculationCountry')}</p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.totalCalculationCountry, true)}
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>RSD</small>
                  </span>                        
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.totalCalculationAbroad')}</p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.totalCalculationAbroad, true)}
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>{abroadCurrency}</small>
                  </span>                                     
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.totalPaymentInAdvanceCountry')}</p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.totalPaymentInAdvanceCountry, true)}
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>RSD</small>
                  </span>  
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.totalPaymentInAdvanceAbroad')}</p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.totalPaymentInAdvanceAbroad, true)}
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>{abroadCurrency}</small>
                  </span>                   
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.totalPaidWithCompanyCardCountry')}</p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.totalPaidWithCompanyCardCountry, true)}
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>RSD</small>
                  </span>  
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.totalPaidWithCompanyCardAbroad')}</p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.totalPaidWithCompanyCardAbroad, true)}
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>{abroadCurrency}</small>
                  </span>   
                </div>

                <div>
                  <p className='label'>
                    <strong>{lt('travelOrderCalculation.totalAmountForPaymentCountry')}</strong>
                  </p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.totalAmountForPaymentCountry, true)}
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>RSD</small>
                  </span>  
                </div>

                <div>
                  <p className='label'>
                    <strong>{lt('travelOrderCalculation.totalAmountForPaymentAbroad')}</strong>
                  </p>
                  <span className='value'>
                    {getInputFieldValue(travelCalculation?.totalAmountForPaymentAbroad, true)}
                    <small style={{ display: 'inline-block', paddingLeft: '3px', opacity: '0.6', fontWeight: '600' }}>{abroadCurrency}</small>
                  </span>  
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.attachmentNumber')}</p>
                  <span className='value'>
                    {travelCalculation?.attachmentNumber ? (
                      <strong>{travelCalculation?.attachmentNumber}</strong>
                    ) : (
                      <span>0</span>
                    )}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.startBorderCrossDateAndTime')}</p>
                  <span className='value'>
                    {travelCalculation?.startBorderCrossDateAndTime ? (
                      <strong>{travelCalculation?.startBorderCrossDateAndTime}</strong>
                    ) : (
                      <span>0</span>
                    )}
                  </span>
                </div>

                <div>
                  <p className='label'>{lt('travelOrderCalculation.startDateAndTime')}</p>
                  <span className='value'>
                    {travelCalculation?.startDateAndTime ? (
                      <strong>{formatDataTime(travelCalculation?.startDateAndTime, 'DD/MM/YYYY')}</strong>
                    ) : (
                      <span>~</span>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </section>

          <section className='section'>
            <SectionStickyHeader
              step={3}
              handleScrollTopAndStep={handleScrollTopAndStep}
              title={lt('travelGlobal.travelInvoices')}
            />

            {hasDataNotFound ? (
              <div className='table-details'>
                {isRsdCurrencty && (
                  <div className='table-list'>
                    <TableTopHeader currency='RSD' stepLang={stepLang} />

                    <table className='table'>
                      <thead>
                        <tr>
                          {tableKeys.map((key) => {
                            return <th key={key}>{key}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {dataByCurrency['RSD'].map((currency) => {
                          const idx = currency?.index;
                          const key = currency?.travelInvoiceType.toLowerCase();
                          const invoiceTypeKey = `travelGlobal.${key}`; 

                          return (
                            <tr className={cn(isMarkRow(currency?.index) ? 'mark-row' : '')} key={currency._id}>
                              <td>{hasStrongTableLabel(idx, currency?.index)}</td>
                              <td>{hasStrongTableLabel(idx, currency?.travelInvoiceNumber)}</td>
                              <td>{hasStrongTableLabel(idx, lt(invoiceTypeKey))}</td>
                              <td>{hasStrongTableLabel(idx, currency?.travelInvoiceDescription)}</td>
                              <td>{hasStrongTableLabel(idx, formatLocalNumber(currency?.travelInvoiceAmount))}</td>
                              <td>{hasStrongTableLabel(idx, currency?.travelInvoiceCurrency)}</td>
                              <td>{hasStrongTableLabel(idx, currency?.isTravelInvoicePaidByCard ? lt('travelGlobal.isTravelInvoicePaidByCardYes') : lt('travelGlobal.isTravelInvoicePaidByCardNo'))}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}

                {isOtherCurrency && (
                  <div className='table-list'>
                    {currencies
                      .filter((currency) => currency !== 'RSD')
                      .map((currency, index) => {
                        const tableData = dataByCurrency[currency];

                        return (
                          <div>
                            <TableTopHeader currency={currency} stepLang={stepLang} />

                            <table key={index} className='table'>
                              <thead>
                                <tr>
                                  {tableKeys.map((key) => {
                                    return <th key={key}>{key}</th>;
                                  })}
                                </tr>
                              </thead>

                              {tableData.map((table) => {
                                const idx = table?.index;

                                return (
                                  <tbody>
                                    <tr className={cn(isMarkRow(table?.index) ? 'mark-row' : '')} key={table._id}>
                                      <td>{hasStrongTableLabel(idx, table?.index)}</td>
                                      <td>{hasStrongTableLabel(idx, table?.travelInvoiceNumber)}</td>
                                      <td>{hasStrongTableLabel(idx, table?.travelInvoiceType)}</td>
                                      <td>{hasStrongTableLabel(idx, table?.travelInvoiceDescription)}</td>
                                      <td>{hasStrongTableLabel(idx, formatLocalNumber(table?.travelInvoiceAmount))}</td>
                                      <td>{hasStrongTableLabel(idx, table?.travelInvoiceCurrency)}</td>
                                      <td>
                                        {hasStrongTableLabel(idx, table?.isTravelInvoicePaidByCard ? lt('travelGlobal.isTravelInvoicePaidByCardYes') : lt('travelGlobal.isTravelInvoicePaidByCardNo'))}
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                            </table>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            ) : (
              <p style={{ padding: '15px', textAlign: 'center' }}>No Data</p>
            )}
          </section>
        </article>

        {hasViewDisabled ? (
          <div className="sticky-actions">
            <div className="grid">
              <div className="flex">
                <StepControl currentStep={currentStep} onChangeStep={onChangeStep} />

                <Button type='button' onClick={handleBack}>
                  Return
                </Button>
              </div>
            </div>
          </div>
        ) : null}
        
        {hasEditDisabled ? ( 
          <div className='sticky-actions'>
            <div className='grid'>
              <div className='flex'>
                {formInit?.employee && (
                  <Button type="button" onClick={handleBack} style={{ border: 0, shadow: 0 }} >Cancel</Button>
                )}

                <Button className='ant-btn-primary' onClick={onFinish}>
                  Finish Order
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      
      {loading && (
        <div style={{ position: 'fixed', inset: 0, zIndex: 101, background: 'rgba(255,255,255,.3)', display: 'grid' }}>
          <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
            <Spin spinning={loading} size='large'>
            </Spin>
          </div>
        </div>
      )}
    </>
  );
}

function TableTopHeader({ currency, stepLang }) {
  const { lt } = useLocalTranslations(stepLang);

  return (
    <div className='table-header'>
      <h2>
        {lt('travelGlobal.currency')}: <strong style={{ fontSize: '15px' }}>{currency ? currency : 'RSD'}</strong>
      </h2>
    </div>
  );
}

function SectionStickyHeader({ handleScrollTopAndStep, step, title }) {
  return (
    <header className='header'>
      <h4 className='title'>
        <button onClick={() => handleScrollTopAndStep(step)} className='btn-link' type='button'>
          #{step}{' '}
        </button>
        <span> - {title}</span>
      </h4>
    </header>
  );
}
