import { Button, Card, Form, Input, } from 'antd';
import React from 'react';

const initialValues = {
  name: undefined,
};


const GuideCategoryForm = ({ data, createHandler, updateHandler, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  const isNew = data ? false : true;
  let initValues = data ? { ...data } : initialValues;

  const onFinish = (values) => {
    if (isNew) {
      createHandler(values);
    } else {
      updateHandler(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.warn('Failed:', errorInfo);
  };

  return (
    <div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          <Card title={`${isNew ? 'NEW CATEGORY' : "EDIT CATEGORY"}`} bordered={false}>
            <Form
              name='basic'
              initialValues={initValues}
              onFinish={(values) => onFinish(values)}
              onFinishFailed={onFinishFailed}
              layout='horizontal'
              form={form}
            >
              <Form.Item
                name='name'
                label='Category name'
                rules={[{ required: true, message: 'Please enter category name!' }]}
              >
                <Input />
              </Form.Item>

              <div className='text-right'>
                <Button type='primary' htmlType='submit'>
                  Submit
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default GuideCategoryForm;