import { Drawer, Divider, Checkbox } from 'antd';
import React from 'react';

const AdminEmailsDrawer = ({
  onClose,
  adminEmailsDrawer,
  timesheetSubmission,
  setTimesheetSubmission,
  leavesDecisions,
  setLeavesDecisions,
  terminationExtensionEmails,
  setTerminationExtensionEmails,
  workPermissionExpireEmails,
  setWorkPermissionExpireEmails,
  paymentOrdersEmails,
  setPaymentOrdersEmails,
  newEmployeeEmails,
  setNewEmployeeEmails,
  officialTravelEmails,
  setOfficialTravelEmails,
}) => {
  return (
    <Drawer title='Email notification settings' width={520} closable={false} onClose={onClose} open={adminEmailsDrawer}>
      <div style={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
        <p style={{ marginBottom: '10px' }}>
          Here you can specify which email notifications the admin wants to receive.
        </p>
        <Divider style={{ margin: '30px 0', backgroundColor: '#2db1ab50' }} />
        <div style={{ marginBottom: '10px' }}>
          If you want the admin to <strong>receive following email notifications</strong>, check them:
        </div>
        <Divider style={{ margin: '15px 0', backgroundColor: '#2db1ab50' }} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <span>Timesheet submission emails: </span>
            <Checkbox
              style={{ marginLeft: '10px' }}
              checked={timesheetSubmission}
              onChange={() => {
                setTimesheetSubmission(!timesheetSubmission);
              }}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <span>Leaves decisions emails:</span>
            <Checkbox
              style={{ marginLeft: '10px' }}
              checked={leavesDecisions}
              onChange={() => {
                setLeavesDecisions(!leavesDecisions);
              }}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <span>New employee added notifications:</span>
            <Checkbox
              style={{ marginLeft: '10px' }}
              checked={newEmployeeEmails}
              onChange={() => {
                setNewEmployeeEmails(!newEmployeeEmails);
              }}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <span>Termination/extension emails:</span>
            <Checkbox
              style={{ marginLeft: '10px' }}
              checked={terminationExtensionEmails}
              onChange={() => {
                setTerminationExtensionEmails(!terminationExtensionEmails);
              }}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <span>Work permission expire emails:</span>
            <Checkbox
              style={{ marginLeft: '10px' }}
              checked={workPermissionExpireEmails}
              onChange={() => {
                setWorkPermissionExpireEmails(!workPermissionExpireEmails);
              }}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <span>Payment orders emails:</span>
            <Checkbox
              style={{ marginLeft: '10px' }}
              checked={paymentOrdersEmails}
              onChange={() => {
                setPaymentOrdersEmails(!paymentOrdersEmails);
              }}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <span>Official travel orders emails:</span>
            <Checkbox
              style={{ marginLeft: '10px' }}
              checked={officialTravelEmails}
              onChange={() => {
                setOfficialTravelEmails(!officialTravelEmails);
              }}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default AdminEmailsDrawer;
