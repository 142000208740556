import axios from 'axios';
import { SERVER_URL } from '../config';
import * as FileSaver from 'file-saver';

export const getAllOfficialTravels = async (token) => {
  try {
    const response = await axios.get(`${SERVER_URL}/official-travels`, {
      headers: {
        withCredentials: false,
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching official travels:', error);
    throw error;
  }
};

export const createOfficialTravel = async (token, data) => {
  try {
    const response = await axios.post(`${SERVER_URL}/official-travels`, data, {
      headers: {
        withCredentials: false,
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error create official travel:', error);
    throw error;
  }
};

export const getOfficialTravelById = async (token, id) => {
  try {
    const response = await axios.get(`${SERVER_URL}/official-travels/${id}`, {
      headers: {
        withCredentials: false,
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching official travel by id:', error);
    throw error;
  }
};

export const updateOfficialTravelById = async (token, data, id) => {
  try {
    const response = await axios.put(`${SERVER_URL}/official-travels/${id}`, data, {
      headers: {
        withCredentials: false,
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error update official travel:', error);
    throw error;
  }
};

export const deleteOfficialTravelById = async (token, id) => {
  try {
    const response = await axios.delete(`${SERVER_URL}/official-travels/${id}`, {
      headers: {
        withCredentials: false,
        Authorization: `Bearer ${token}`,
      },
    });

    return response.message;
  } catch (error) {
    console.error('Error delete official travel:', error);
    throw error;
  }
};

export const acceptOfficialTravelById = async (token, id) => {
  const data = { status: 'accepted' };
  try {
    const response = await axios.put(`${SERVER_URL}/official-travels/approve/${id}`, data, {
      headers: {
        withCredentials: false,
        Authorization: `Bearer ${token}`,
      },
    });

    return response.message;
  } catch (error) {
    console.error('Error delete official travel:', error);
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const denyOfficialTravelById = async (token, id) => {
  const data = { status: 'denied' };
  try {
    const response = await axios.put(`${SERVER_URL}/official-travels/deny/${id}`, data, {
      headers: {
        withCredentials: false,
        Authorization: `Bearer ${token}`,
      },
    });

    return response.message;
  } catch (error) {
    console.error('Error deny official travel:', error);
    throw error;
  }
};

export const deleteOfficialTravelInvoiceById = async (token, id) => {
  try {
    const response = await axios.delete(`${SERVER_URL}/official-travels/invoice/${id}`, {
      headers: {
        withCredentials: false,
        Authorization: `Bearer ${token}`,
      },
    });

    return response.message;
  } catch (error) {
    console.error('Error delete official travel:', error);
    throw error;
  }
};

export const deleteOfficialTravelInvoicesFileByFileKey = async (token, originalName, key) => {
  try {
    const response = await axios.delete(`${SERVER_URL}/delete-travel-invoices-s3/${originalName}`, {
      headers: {
        withCredentials: false,
        Authorization: `Bearer ${token}`,
      },
      data: {
        key
      }
    });

    return response.message;
  } catch (error) {
    console.error('Error delete official travel invoices image of s3 Amazon:', error);
    throw error;
  }
}

export const downloadOfficialTravelInvoicesImage = async (token, key, originalName) => {
  const url = `${SERVER_URL}/file-travel-invoice?fileKey=${key}&originalName=${originalName}`;

  try {
    const response = await axios.get(url, {
      headers: {
        withCredentials: false,
        Authorization: `Bearer ${token}`,
      }
    });

    FileSaver.saveAs(response.config.url, originalName);

    return response.statusText;
  } catch (error) {
    console.error('Error download official travel invoices image of s3 Amazon:', error);
    throw error;
  }
}