import { useCallback, useState } from 'react';
import StepNavbar from './StepNavbar';
import StepForm from './form-steps/StepForm';
import StepLanguage from './StepLanguage';
import { useLocation } from 'react-router-dom';

export default function OfficialTravel({ 
  typeOfLeave, 
  officialTravel, 
  companyId, employeeId, 
  token, 
  viewMode, 
  isEmployeeUser, 
  handleChangeTab, 
  handleRefetchData, 
  handleRefetchRequest,
  userFullName
}) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const stepLang = queryParams.get('stepLang') ?? 'en';

  const [currentStep, setCurrentStep] = useState(1);
  const [activeSteps, setActiveSteps] = useState([1]);

  const handleStepChange = useCallback(
    (step) => {
      const currentStepValue = +step;
      setCurrentStep(currentStepValue);

      if (currentStepValue > currentStep) {
        setActiveSteps((prevSteps) => [
          ...prevSteps,
          ...Array.from({ length: currentStepValue - currentStep }, (_, i) => currentStep + i + 1),
        ]);
      } else {
        setActiveSteps((prevSteps) => {
          return prevSteps.slice(0, currentStepValue);
        });
      }
    },
    [currentStep],
  );

  return (
    <div className='official-travel'>
      <StepNavbar
        isEmployeeUser={isEmployeeUser}
        activeSteps={activeSteps}
        formInit={officialTravel}
        onChangeStep={handleStepChange}
      />

      <StepLanguage stepLang={stepLang} currentStep={currentStep} userFullName={userFullName} />

      <StepForm
        stepLang={stepLang}
        typeOfLeave={typeOfLeave}
        formInit={officialTravel}
        currentStep={currentStep}
        onChangeStep={handleStepChange}
        viewMode={viewMode}
        isEmployeeUser={isEmployeeUser}
        employeeId={employeeId}
        companyId={companyId}
        token={token}
        handleChangeTab={handleChangeTab}
        handleRefetchData={handleRefetchData}
        handleRefetchRequest={handleRefetchRequest}
      />
    </div>
  );
}
