import React from 'react';
import { DatePicker, Button, Typography } from 'antd';

const { RangePicker } = DatePicker;

export default function EInvoiceFilters({ onSubmit, onClearFilter, dateFrom, setDateFrom, dateTo, setDateTo }) {
  function setRangePickerValue(value) {
    if (value && value.length > 0) {
      let start = value[0];
      let end = value[1];

      setDateFrom(start.format('YYYY-MM-DD'));
      setDateTo(end.format('YYYY-MM-DD'));
    } else {
      setDateFrom('');
      setDateTo('');
      setTimeout(() => {
        onClearFilter();
      }, 700);
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#fff',
        padding: '5px 10px',
        borderRadius: '6px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        width: '100%',
        maxWidth: '400px',
        marginBottom: '8px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <RangePicker
          onChange={(val) => setRangePickerValue(val)}
          format={'DD-MM-YYYY'}
          style={{
            minWidth: '150px',
            height: '28px',
          }}
          allowClear
        />
      </div>
      <Button
        type='primary'
        onClick={() => onSubmit({ dateFrom, dateTo }, true)}
        style={{
          backgroundColor: '#1890ff',
          borderColor: '#1890ff',
          borderRadius: '4px',
          marginLeft: '5px',
          padding: '0 15px',
          height: '28px',
          fontWeight: 'bold',
          fontSize: '12px',
        }}
      >
        Apply
      </Button>
    </div>
  );
}
