import { Form, Select } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

export default function StepLanguage({ stepLang, userFullName, currentStep }) {
  const LANGUAGES = [
    {
      value: 'en',
      label: 'EN'
    },
    {
      value: 'rs',
      label: 'RS'
    }
  ];

  const history = useHistory();
  const location = useLocation();

  const handleNavigation = (value) => {
    const queryParams = new URLSearchParams({
      stepLang: value
    }).toString();

    history.replace({
      pathname: location.pathname,
      search: `?${queryParams}`
    });
  };

  return (
    <div className='step-language'>
      <p className='user-info'>
        <strong>{userFullName ?? ''}</strong>
      </p>

      <Form.Item label='Language'>
        <Select 
            disabled={![1].includes(currentStep)}
            options={LANGUAGES} 
            defaultValue={stepLang} 
            onChange={(value) => handleNavigation(value)} 
            className='lang-dropdown'
            style={{ width: 'max-content', minWidth: '120px', textTransform: 'uppercase' }} 
          />
      </Form.Item>
    </div>
  )
}
