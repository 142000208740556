import React from 'react';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const CarouselProductsForm = ({ excelData }) => {
  const cellStyle = {
    border: '1px solid #ccc',
    padding: '8px',
    textAlign: 'left',
    width: '300px',
  };

  const headerStyle = {
    ...cellStyle,
    fontWeight: 'bold',
    backgroundColor: '#f2f2f2',
    width: '200px',
  };

  return (
    <div className='carousel-container'>
      <Carousel
        prevArrow={<LeftOutlined />}
        nextArrow={<RightOutlined />}
        dots={true}
        draggable={true}
        dotPosition='bottom'
        arrows={true}
        infinite={false}
        fade={true}
        dotOffset={20}
      >
        {excelData?.map((item, index) => (
          <div key={index}>
            <table style={{ borderCollapse: 'collapse', width: 'auto', margin: 'auto' }}>
              <tbody>
                <tr>
                  <td style={headerStyle}>Code:</td>
                  <td style={cellStyle}>{item.code || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Title:</td>
                  <td style={cellStyle}>{item.title || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Description:</td>
                  <td style={cellStyle}>{item.description || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Unit:</td>
                  <td style={cellStyle}>{item.unit || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Tax %:</td>
                  <td style={cellStyle}>{item.tax || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Price EUR:</td>
                  <td style={cellStyle}>{item.priceEUR || 'undefined'}</td>
                </tr>
                <tr>
                  <td style={headerStyle}>Price RSD:</td>
                  <td style={cellStyle}>{item.priceRSD || 'undefined'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselProductsForm;
