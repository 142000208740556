import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
import { DownloadOutlined, LoadingOutlined, QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Form, notification, Upload, Tooltip, Modal, Spin } from 'antd';
import * as XLSX from 'xlsx';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { useHistory } from 'react-router-dom';
import CarouselProductsForm from '../../components/CarouselProductsForm.js';

const UploadProducts = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [isError, setIsError] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [fileUpload, setFileUpload] = useState(false);

  const selectedEInvoiceCompanyId = (() => {
    const storedCompanyId = localStorage.getItem('selectedEInvoiceCompany');
    const companies = Array.isArray(currentuser?.data?.company) ? currentuser.data.company : [currentuser?.data?.company].filter(Boolean);
    const companyIds = companies.length > 0 ? companies.map(item => item._id) : [];

    return companyIds.includes(storedCompanyId)
        ? storedCompanyId
        : companies.length > 0 ? companies[0]?._id : null;
  })();

  let initialValues = {};

  useEffect(() => {
    if (excelData && excelData.length > 0) {
      setFileList([]);
    }
  }, [excelData, isError]);

  const onFinish = async () => {
    const postData = {
      excelData,
      selectedEInvoiceCompanyId
    };
    try {
      await Axios.post(`${SERVER_URL}/products-upload`, postData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      }).then((res) => {
        if (res.data) {
          let message = res.data.message;
          if (res.data.failedProducts && res.data.failedProducts.length) {
            message += ` Failed products: ${res.data.failedProducts.join(', ')}`;
          }
          notification.success({
            message: message,
            placement: 'bottomRight',
            duration: 0,
            style: {
              maxHeight: '80vh',
              overflowY: 'auto',
            },
          });
          form.resetFields();
          setFileList([]);
          setSubmitClicked(false);
          setSubmitDisabled(true);
          history.push('/admin/products');
        }
      });
    } catch (error) {
      console.log('error in saving products upload categories', error);
      notification.error({
        message: 'Problem with Products excel upload. Please contact administrator or try again.',
        placement: 'bottomRight',
      });
      setSubmitClicked(false);
    }
  };
  const handleRemove = (file) => {
    setFileList((prevFileList) => prevFileList.filter((item) => item.uid !== file.uid));
    setExcelData([]);
  };

  const handleUpload = async (file) => {
    setFileUpload(true);
    setFileList([]);
    setExcelData([]);

    const requiredColumns = ['Code', 'Title', 'Description', 'Unit', 'Tax %', 'Price EUR'];

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      let excelItems = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      excelItems = excelItems.filter((item) => item.length > 0);

      const sheetColumns = excelItems[0] || [];
      const missingColumns = requiredColumns.filter((col) => !sheetColumns.includes(col));

      if (missingColumns.length > 0) {
        notification.error({
          message: `Problem with Products excel upload. Missing columns: ${missingColumns.join(', ')}`,
          placement: 'bottomRight',
          duration: 0,
        });
        setFileUpload(false);
        return;
      }

      const invalidRecords = [];

      const mappedData = excelItems.slice(1).map((item, index) => {
        const record = {
          code: item[sheetColumns.indexOf('Code')],
          title: item[sheetColumns.indexOf('Title')],
          description: item[sheetColumns.indexOf('Description')],
          unit: item[sheetColumns.indexOf('Unit')],
          tax: item[sheetColumns.indexOf('Tax %')],
          priceEUR: item[sheetColumns.indexOf('Price EUR')],
          priceRSD: item[sheetColumns.indexOf('Price RSD')],
        };

        // Error handling related to required columns in Excel
        if (!record.code || !record.title || !record.description || !record.unit || !record.tax || !record.priceEUR) {
          invalidRecords.push(index + 1);
        }
        return record;
      });

      if (invalidRecords.length > 0) {
        notification.error({
          message: `Problem with Products excel upload. Missing required fields in rows: ${invalidRecords.join(', ')}`,
          placement: 'bottomRight',
          duration: 0,
        });
        setFileUpload(false);
        return;
      }

      setFileList([file]);
      setExcelData(mappedData);
      setSubmitDisabled(false);
      setFileUpload(false);
    };

    reader.readAsArrayBuffer(file);
  };

  // download the sample Excel file
  const downloadSampleFile = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/excel?type=products`, {
        responseType: 'blob',
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'products_sample.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('Error downloading sample file:', error);
      notification.error({
        message: 'Error downloading sample file.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper' style={{ marginTop: '15px' }}>
        <Card title='Upload excel' bordered={false}>
          <h4 style={{ paddingBottom: '5px' }}>Here you can upload excel file with products.</h4>
          <h4 style={{ paddingBottom: '15px' }}>
            Please use the sample excel file and fill it according to explanation.
          </h4>
          <Form
            name='basic'
            initialValues={initialValues}
            onFinish={async (values) => {
              setSubmitClicked(true);
              setSubmitDisabled(true);
              await onFinish(values);
            }}
            layout='horizontal'
            form={form}
          >
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex'}}>
                <Form.Item
                  name='fileOption'
                  label='Attach file'
                  className='attach'
                  rules={[
                    {
                      required: true,
                      message: 'Please upload the excel file!',
                    },
                  ]}
                >
                  <Upload
                    accept='.xlsx,.xls'
                    onRemove={handleRemove}
                    maxCount={1}
                    fileList={fileList.length === 0 && !isError ? undefined : isError ? [] : [...fileList]}
                    name='file'
                    multiple={false}
                    beforeUpload={async (file) => {
                      setIsError(false);
                      if (file.size / 1000000 > 5) {
                        notification.error({
                          message: 'File is larger than 5 MB!',
                          placement: 'bottomRight',
                        });
                        return Promise.reject();
                      }
                      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                        notification.error({
                          message: 'Please upload an Excel file!',
                          placement: 'bottomRight',
                        });
                        return Promise.reject();
                      }
                      if (fileList.length > 0) {
                        notification.error({
                          message: 'You can only upload one file at a time!',
                          placement: 'bottomRight',
                        });
                        return Promise.reject();
                      }
                      await handleUpload(file);
                      return false;
                    }}
                  >
                    <Button id='uploadButton' icon={<UploadOutlined />}>
                      Upload excel
                    </Button>
                  </Upload>
                </Form.Item>

                <Tooltip
                  title={
                    <>
                      <strong>Note:</strong> You cannot upload the file larger than 5 MB and it HAS to be .xlsx file.
                    </>
                  }
                  color='#b1b1b1'
                  placement='bottom'
                  autoAdjustOverflow={true}
                >
                  <QuestionCircleOutlined style={{ color: '#b1b1b1', marginLeft: '10px', marginBottom: '20px' }} />
                </Tooltip>
              </div>

              <Form.Item>
                <Button
                  type='default'
                  icon={<DownloadOutlined />}
                  style={{ marginLeft: '8px' }}
                  onClick={downloadSampleFile}
                >
                  Download excel sample
                </Button>
              </Form.Item>
            </div>
   
            <div style={{ marginTop: '30px' }}>
              <CarouselProductsForm excelData={excelData} />
            </div>
   
            <Form.Item style={{ textAlign: 'right', marginTop: '15px' }}>
              <Button
                className='text-right'
                type='primary'
                htmlType='submit'
                style={{ marginTop: '15px' }}
                disabled={submitDisabled || !currentuser?.data?.company?._id}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>

          <Modal visible={fileUpload} maskClosable={false} closable={false} footer={null}>
            <div style={{ textAlign: 'center', fontSize: '1.05rem' }}>
              <p>Please wait...</p>
              <p>
                <Spin style={{ color: '#2db1ab' }} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              </p>
            </div>
          </Modal>
          <Modal visible={submitClicked} maskClosable={false} closable={false} footer={null}>
            <div style={{ textAlign: 'center', fontSize: '1.05rem' }}>
              <p>Please wait...</p>
              <p>
                <Spin style={{ color: '#2db1ab' }} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              </p>
            </div>
          </Modal>
        </Card>
      </div>
    </div>
  );
};
export default UploadProducts;
