import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import ProductsTable from '../../components/tables/ProductsTable';
import { SERVER_URL } from '../../config';
import { formatDateForTables } from '../../helpers/dates';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'company', 'tax', 'code'];

const Products = () => {
  const currentuser = useContext(UserContext);
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState([]);

  const selectedEInvoiceCompanyId = (() => {
    const storedCompanyId = localStorage.getItem('selectedEInvoiceCompany');
    const companies = Array.isArray(currentuser?.data?.company) ? currentuser.data.company : [currentuser?.data?.company].filter(Boolean);
    const companyIds = companies.length > 0 ? companies.map(item => item._id) : [];

    return companyIds.includes(storedCompanyId)
        ? storedCompanyId
        : companies.length > 0 ? companies[0]?._id : null;
  })();

  useEffect(() => {
    if (selectedEInvoiceCompanyId) {
      fetchProducts(selectedEInvoiceCompanyId);
    }
  }, [currentuser]);

  const fetchProducts = async (id) => {
    try {
      const response = await Axios.get(`${SERVER_URL}/products?id=${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setData(response?.data?.items);
    } catch (error) {
      console.error('error in fetching products', error);
      notification.error({
        message: 'Error in fetching products. Please contact administrator.',
        placement: 'bottomRight',
      });
    }
  };

  const deleteProductHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/products/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Product is deleted.',
        placement: 'bottomRight',
      });

      await fetchProducts(selectedEInvoiceCompanyId);
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let newColumnKeys;
  if (data?.length > 0) {
    const keys = Object.keys(data[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    columnKeys = ['numRow', 'title', 'description', 'unit', 'priceEUR', 'priceRSD', 'createdAt', 'updatedAt'];
    let alternativeNames = [];
    for (const columnKey of columnKeys) {
      if (columnKey === 'numRow') {
        alternativeNames.push('No.');
      } else if (columnKey === 'title') {
        alternativeNames.push('Title');
      } else if (columnKey === 'description') {
        alternativeNames.push('Description');
      } else if (columnKey === 'unit') {
        alternativeNames.push('Unit');
      } else if (columnKey === 'priceEUR') {
        alternativeNames.push('Price EUR');
      } else if (columnKey === 'priceRSD') {
        alternativeNames.push('Price RSD');
      } else if (columnKey === 'createdAt') {
        alternativeNames.push('Created');
      } else if (columnKey === 'updatedAt') {
        alternativeNames.push('Updated');
      }
    }
    let keyArray = columnKeys;
    newColumnKeys = columnKeys.map((item, index) => ({
      originalName: keyArray[index],
      alternativeName: alternativeNames[index],
    }));
  }

  useEffect(() => {
    let dataForTable;
    if (data?.length > 0) {
      dataForTable = data.map((item, index) => {
        item.numRow = index + 1;
        const formattedDate = formatDateForTables(item.createdAt);
        const formattedDate2 = formatDateForTables(item.updatedAt);
        return { ...item, createdAt: formattedDate, updatedAt: formattedDate2 };
      });

      const uniqueData = Array.from(new Map(dataForTable.map((item) => [item._id, item])).values());

      setTableData(uniqueData);
    }
  }, [data]);

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Link to='/admin/new-product'>
          <Button type='primary'>New product</Button>
        </Link>
        <Link to='/admin/upload-products'>
          <Button type='primary'>Upload products</Button>
        </Link>
      </div>

      <div style={{ textAlign: 'center' }}>
        {data.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <ProductsTable
              data={tableData}
              deleteHandler={deleteProductHandler}
              columnKeys={newColumnKeys}
              editPath='/admin/edit-product/'
              viewPath='/admin/view-product/'
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          </div>
        )}
        {data.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default Products;
