import React, { useContext, useState } from 'react';
import { UserContext } from '../../App';
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Form, notification, Upload, Tooltip, Spin } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';

const UploadCompanyFilesForm = () => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleRemove = () => {
    setFileList([]);
  };

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append('pdf', file);

    try {
      setLoading(true);
      const response = await Axios.post(`${SERVER_URL}/upload-pdf-translation`, formData, {
        headers: {
          Authorization: `Bearer ${currentuser.data.token}`,
        },
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'translated-document.pdf';
      link.click();

      window.URL.revokeObjectURL(url);

      notification.success({
        message: 'Upload Successful',
        description: 'The PDF file has been successfully uploaded and processed.',
        placement: 'bottomRight',
      });
    } catch (error) {
      console.error('error in handleFileUpload', error);
      notification.error({
        message: 'Error uploading file',
        description: 'There was a problem uploading the PDF file.',
        placement: 'bottomRight',
      });
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async () => {
    if (fileList.length > 0) {
      await handleFileUpload(fileList[0]);
    } else {
      notification.error({
        message: 'No file selected',
        description: 'Please upload a file before submitting.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper' style={{ marginTop: '15px' }}>
        <Card
          title='Upload PDF Document'
          bordered={false}
          style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
        >
          <h4 style={{ paddingBottom: '20px', paddingTop: '10px', fontWeight: 'normal', color: '#555' }}>
            Upload your PDF file for translation. It will be processed and you will receive the translated document.
          </h4>
          <Form name='upload-form' onFinish={onFinish} layout='horizontal' form={form}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Item
                name='fileOption'
                label='Attach file'
                className='attach'
                rules={[
                  {
                    required: true,
                    message: 'Please upload the PDF file!',
                  },
                ]}
              >
                <Upload
                  accept='.pdf'
                  onRemove={handleRemove}
                  maxCount={1}
                  fileList={fileList}
                  name='file'
                  multiple={false}
                  beforeUpload={(file) => {
                    if (file.size / 1000000 > 5) {
                      notification.error({
                        message: 'File is larger than 5 MB!',
                        placement: 'bottomRight',
                      });
                      return false;
                    }

                    if (file.type !== 'application/pdf') {
                      notification.error({
                        message: 'Please upload a PDF file!',
                        placement: 'bottomRight',
                      });
                      return false;
                    }

                    setFileList([file]);
                    return false;
                  }}
                >
                  <Button
                    id='uploadButton'
                    icon={<UploadOutlined />}
                    style={{ marginLeft: '10px', marginBottom: '10px' }}
                    loading={loading}
                  >
                    {loading ? <Spin size='small' /> : 'Upload PDF'}
                  </Button>
                  <Tooltip
                    title={
                      <>
                        <strong>Note:</strong> You cannot upload a file larger than 5 MB, and it must be a .pdf file.
                      </>
                    }
                    color='#b1b1b1'
                    placement='bottom'
                    autoAdjustOverflow={true}
                  >
                    <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#b1b1b1' }} />
                  </Tooltip>
                </Upload>
              </Form.Item>
            </div>

            <div className='text-right' style={{ marginTop: '30px' }}>
              <Button type='primary' id='submit' htmlType='submit' loading={loading}>
                Submit
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default UploadCompanyFilesForm;
