import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { DataForm } from '../../components/forms';
import { useHistory } from 'react-router-dom';

const EditData = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', {}, currentuser.data.token, 'get');
  const [refetchData, setRefetchData] = useState(false);
  const [datesForDisabling, setDatesForDisabling] = useState(undefined);
  const { dataId } = props.match.params;

  useEffect(() => {
    if (dataId) {
      fetchData(`${SERVER_URL}/data/${dataId}`);
      checkPublicHolidayDaysForDisabledCalendar(dataId);
    }
  }, [fetchData, dataId]);

  function handleRefetchData() {
    setRefetchData((p) => !p);
  }

  const checkPublicHolidayDaysForDisabledCalendar = async (employeeId) => {
    try {
      const dataForDisabling = await Axios.get(`${SERVER_URL}/check-public-holidays-timesheet/${employeeId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setDatesForDisabling(dataForDisabling.data.items);
    } catch (error) {
      console.log('error in check-public-holidays-timesheet', error);
    }
  };

  const onSubmit = async (formData, isNew) => {
    if (
      (formData.newPassword !== '' &&
        formData.confirmPassword !== '' &&
        formData.newPassword !== formData.confirmPassword) ||
      (!formData.newPassword && formData.confirmPassword)
    ) {
      notification.error({
        message: 'New and confirmed password are not same',
        placement: 'bottomRight',
      });
    } else {
      if (!formData.newPassword && !formData.confirmPassword) {
        delete formData.newPassword;
        delete formData.confirmPassword;
      }

      if (currentuser.data.role.includes('hrOfficer')) {
        formData.employeeSector = currentuser.data.sectorId;
        formData.company = currentuser.data.company._id;
      }
      const method = isNew ? 'post' : 'put';
      const route = isNew ? `${SERVER_URL}/data` : `${SERVER_URL}/data/${dataId}`;
      try {
        const response = await Axios[method](route, formData, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (
          method === 'post'
        ) {
          if (
            response &&
            response.status === 201
          ) {
            setTimeout(async () => {
              formData.attachments?.length && await Axios.get(
                `${SERVER_URL}/file/zip/${formData.firstName}_${formData.lastName}_${formData.JMBG}.zip`,
                {
                  withCredentials: false,
                },
              );

              const data = {
                employee: response.data.employeeId,
              };
              await Axios.post(`${SERVER_URL}/employee-mail`, data, {
                withCredentials: false,
                headers: { Authorization: `Bearer ${currentuser.data.token}` },
              });
            }, 1000);
          }
        }

        notification.success({
          message: `Employee is ${isNew ? 'created.' : 'edited.'}`,
          placement: 'bottomRight',
        });
        if (!isNew) {
          history.push('/admin/data');
        }
      } catch (error) {
        const msg = error.response ? error.response.data.message : error.message ? error.message : error;
        console.error('create emp error message', msg);
        notification.error({
          message: msg,
          placement: 'bottomRight',
        });
        return false;
      }
      if (!isNew && data?.data?.company?.isHr && formData.newPassword && formData.confirmPassword) {
        try {
          await Axios.put(
            `${SERVER_URL}/user/${data?.data?.email}`,
            { newPassword: formData.newPassword, typeOfHr: data.data.company.typeOfHr },
            { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
          );
          notification.success({
            message: 'Password is changed.',
            placement: 'bottomRight',
          });
        } catch (error) {
          console.log('error in changing password for HR employee', error);
        }
      }
    }
  };

  const isDataFetched = !data.isLoading && data.data && currentuser.language;

  return (
    <div style={{ padding: '8px' }}>
      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}

        {!dataId && isDataFetched && (
          <DataForm
            isNew={true}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            datesForDisabling={datesForDisabling}
            refetchData={refetchData}
            handleRefetchData={handleRefetchData}
          />
        )}

        {dataId && !data.isError && data.data && isDataFetched && (
          <DataForm
            isNew={false}
            data={data.data}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            location={props.location.search}
            datesForDisabling={datesForDisabling}
            refetchData={refetchData}
            handleRefetchData={handleRefetchData}
          />
        )}
      </div>
    </div>
  );
};

export default EditData;
