import React, { memo } from 'react';
import { SERVER_URL } from '../../config';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
const { Dragger } = Upload;

function UploadImages({ token, employee, travelInvoiceId, handleRefetchRequest }) {  
  const props = {
    name: 'imageFiles',
    multiple: true,
    accept: 'image/jpeg,image/png,image/jpg,image/gif,image/bmp,image/webp,application/pdf',
    action: `${SERVER_URL}/upload-travel-invoices-s3?employee=${encodeURIComponent(
      JSON.stringify({
        employeeID: employee?.JMBG,
        employeeName: employee?.firstName.replaceAll(' ', '_') + '_' + employee?.lastName.replaceAll(' ', '_'),
        travelInvoiceId: travelInvoiceId,
      }),
    )}`,
    headers: {
      Authorization: `Bearer ${token}`,
      uri: 'static/pdf/',
      timestamp: Date.now().toString(),
    },
    beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5; // 5mb
      if (!isLt5M) {
        message.error('File must be smaller than 5MB!');
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange(info) {
      const { status } = info.file;

      if (status !== 'uploading') {
      }

      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        
        const allFilesUploaded = info.fileList.every(file => file.status === 'done');
        
        if (allFilesUploaded && handleRefetchRequest) {
          setTimeout(() => {
            handleRefetchRequest();
          }, 0);
        }        
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  if (!employee) {
    return (
      <div className='upload-images'>
        <div className='upload-area'>
          <span className=''>No user data</span>
        </div>
      </div>
    )
  }

  return (
    <div className='upload-images'>
      <div className='upload-area'>
        <Dragger {...props}>
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>Click or drag file to this area to upload</p>
          <p className='ant-upload-hint'>
            Support for a single or bulk upload.
          </p>
          <p className='ant-upload-hint'>
            Allowed file types: JPEG, PNG, JPG, GIF, BMP, WEBP, PDF
          </p>
          <p className='ant-upload-hint'>
            Maximum file size: 5MB
          </p>
        </Dragger>
      </div>
    </div>
  );
}

export default memo(UploadImages);