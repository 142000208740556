import React, { useEffect, useContext } from 'react';
import { Card, Form } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import CustomerInvoiceForm from '../../components/forms/CustomerInvoiceForm';

const EditBuyer = (props) => {
  const currentuser = useContext(UserContext);
  const [customer, setCustomer] = useAxios('', null, currentuser.data.token, 'get');
  const [form] = Form.useForm();
  const { customerId } = props.match.params;

  const selectedEInvoiceCompanyId = (() => {
    const storedCompanyId = localStorage.getItem('selectedEInvoiceCompany');
    const companies = Array.isArray(currentuser?.data?.company) ? currentuser.data.company : [currentuser?.data?.company].filter(Boolean);
    const companyIds = companies.length > 0 ? companies.map(item => item._id) : [];

    return companyIds.includes(storedCompanyId)
        ? storedCompanyId
        : companies.length > 0 ? companies[0]?._id : null;
  })();

  const companies = Array.isArray(currentuser?.data?.company) ? currentuser.data.company : [currentuser?.data?.company].filter(Boolean);
  const selectedEInvoiceCompany = companies.find(item => item._id === selectedEInvoiceCompanyId);

  useEffect(() => {
    setCustomer(`${SERVER_URL}/customer/${customerId}?filter=${JSON.stringify(selectedEInvoiceCompany._id)}`, []);
  }, [customer, setCustomer, selectedEInvoiceCompany]);

  return (
    <div style={{ padding: '8px' }}>
      <div style={{ textAlign: 'center' }}>
        {customerId && customer.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        <div className='dashboard'>
          <div className='card-wrapper'>
            {/* eslint-disable-next-line no-useless-concat */}
            <Card title='EDIT CUSTOMER' bordered={false} style={{ width: '100%' }}>
              {customerId && !customer.isLoading && !customer.isError && customer.data && (
                <CustomerInvoiceForm
                  customerForm={form}
                  SERVER_URL={SERVER_URL}
                  data={customer?.data}
                  setData={setCustomer}
                />
              )}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBuyer;
