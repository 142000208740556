import { useCallback } from 'react';

import TravelOrder from './TravelOrder';
import TravelCalculation from './TravelCalculation';
import TravelInvoices from './TravelInvoices';
import FinishTravelOrder from './FinishTravelOrder';

export default function StepForm({
  formInit,
  stepLang,
  currentStep,
  onChangeStep,
  viewMode,
  isEmployeeUser,
  employeeId,
  companyId,
  token,
  handleChangeTab,
  typeOfLeave,
  handleRefetchData,
  handleRefetchRequest
}) {
  const renderFormStep = useCallback(() => {
    switch (currentStep) {
      case 1:
        return (
          <TravelOrder
            stepLang={stepLang}
            currentStep={currentStep}
            onChangeStep={onChangeStep}
            viewMode={viewMode}
            isEmployeeUser={isEmployeeUser}
            formInit={formInit}
            employeeId={employeeId}
            companyId={companyId}
            token={token}
            handleChangeTab={handleChangeTab}
            typeOfLeave={typeOfLeave}
            handleRefetchData={handleRefetchData}
          />
        );
      case 2:
        return (
          <TravelCalculation
            stepLang={stepLang}
            currentStep={currentStep}
            onChangeStep={onChangeStep}
            viewMode={viewMode}
            formInit={formInit}
            isEmployeeUser={isEmployeeUser}
            employeeId={employeeId}
            companyId={companyId}
            token={token}
            handleChangeTab={handleChangeTab}
            typeOfLeave={typeOfLeave}
            handleRefetchData={handleRefetchData}
            handleRefetchRequest={handleRefetchRequest}
          />
        );
      case 3:
        return (
          <TravelInvoices
            stepLang={stepLang}
            currentStep={currentStep}
            onChangeStep={onChangeStep}
            viewMode={viewMode}
            formInit={formInit}
            isEmployeeUser={isEmployeeUser}
            employeeId={employeeId}
            companyId={companyId}
            token={token}
            handleChangeTab={handleChangeTab}
            typeOfLeave={typeOfLeave}
            handleRefetchData={handleRefetchData}
            handleRefetchRequest={handleRefetchRequest}
          />
        );
      case 4:
        return (
          <FinishTravelOrder 
            stepLang={stepLang}
            currentStep={currentStep}
            onChangeStep={onChangeStep} 
            viewMode={viewMode}
            formInit={formInit}
            isEmployeeUser={isEmployeeUser}
            employeeId={employeeId}
            companyId={companyId}
            token={token}
            handleChangeTab={handleChangeTab}
            typeOfLeave={typeOfLeave}
            handleRefetchData={handleRefetchData}
          />
        )
      default:
        return (
          <TravelOrder 
            stepLang={stepLang}
            currentStep={currentStep}
            onChangeStep={onChangeStep}
            viewMode={viewMode}
            isEmployeeUser={isEmployeeUser}
            formInit={formInit}
            employeeId={employeeId}
            companyId={companyId}
            token={token}
            handleChangeTab={handleChangeTab}
            typeOfLeave={typeOfLeave}
            handleRefetchData={handleRefetchData}
          />
        )
    }
  }, [stepLang, companyId, currentStep, handleChangeTab, typeOfLeave, handleRefetchData, employeeId, formInit, isEmployeeUser, onChangeStep, token, viewMode, handleRefetchRequest]);

  return <div className='step-form'>{renderFormStep()}</div>;
}
