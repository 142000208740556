import { useEffect, useMemo } from 'react';
import { Button, DatePicker, Form, Input, notification, Select, Tooltip } from 'antd';
import moment from 'moment';
import { createOfficialTravel, updateOfficialTravelById } from '../../../../services/official-travel.js';
import { useLocation, useHistory } from 'react-router-dom';
import { hasRequestPermission, useLocalTranslations } from '../../../../utils/index.js';
import StepControl from '../StepControl.js';

export default function TravelOrder({ stepLang, viewMode, isEmployeeUser, employeeId, companyId, token, formInit, handleChangeTab, handleRefetchData, currentStep, onChangeStep }) {
  const { pathname } = useLocation();
  const history = useHistory();
  const hasEditDisabled = hasRequestPermission(pathname, 'edit');
  const hasViewDisabled = hasRequestPermission(pathname, 'view');
  const hasDisabled = hasEditDisabled || hasViewDisabled;

  const [form] = Form.useForm();
  const isNew = !formInit;

  const transportTypeOptions = useMemo(() => {
    if (stepLang === 'rs') {
      return [
        { label: 'Automobil', value: 'Car' },
        { label: 'Bus', value: 'Bus' },
        { label: 'Avion', value: 'Airplane' },
        { label: 'Voz', value: 'Train' },
        { label: 'Ostalo', value: 'Other' },
      ];
    }

    return [
      { label: 'Car', value: 'Car' },
      { label: 'Bus', value: 'Bus' },
      { label: 'Airplane', value: 'Airplane' },
      { label: 'Train', value: 'Train' },
      { label: 'Other', value: 'Other' },
    ];
  }, [stepLang]);

  const paidAccommodationOptions = useMemo(() => {
    if (stepLang === 'rs') {
      return [
        { label: 'DA', value: true },
        { label: 'NE', value: false },
      ]
    }

    return [
      { label: 'YES', value: true },
      { label: 'NO', value: false },
    ]
  }, [stepLang]);

  const paidFoodOptions = useMemo(() => {
    if (stepLang === 'rs') {
      return [
        { label: 'Doručak', value: 'Breakfast' },
        { label: 'Ručak', value: 'Lunch' },
        { label: 'Večera', value: 'Dinner' },
        { label: 'Doručak&Ručak&Večera', value: 'Breakfast&Lunch&Dinner' },
        { label: 'Doručak&Ručak', value: 'Breakfast&Lunch' },
        { label: 'Ručak&Večera', value: 'Lunch&Dinner' },
        { label: 'Večera&Doručak', value: 'Dinner&Breakfast' },
        { label: 'Nijedan', value: 'None' },
      ]
    }

    return [
      { label: 'Breakfast', value: 'Breakfast' },
      { label: 'Lunch', value: 'Lunch' },
      { label: 'Dinner', value: 'Dinner' },
      { label: 'Breakfast&Lunch&Dinner', value: 'Breakfast&Lunch&Dinner' },
      { label: 'Breakfast&Lunch', value: 'Breakfast&Lunch' },
      { label: 'Lunch&Dinner', value: 'Lunch&Dinner' },
      { label: 'Dinner&Breakfast', value: 'Dinner&Breakfast' },
      { label: 'None', value: 'None' },
    ]  
  }, [stepLang]);

  const currencyOptions = ['EUR', 'USD', 'GBP', 'RUB'];
  const currencyDefaultOptions = currencyOptions[0];

  const disabled = !!viewMode;

  const initialValues = {
    travelOrder: {
      travelOrderNo: '',
      year: moment(),
      reason: '',
      country: '',
      city: '',
      startDate: moment(),
      numberOfDays: null,
      transportType: [],
      paidAccommodation: false,
      paidFood: '',
      paymentInAdvanceCountry: null,
      paymentInAdvanceAbroad: null,
      paymentInAdvanceAbroadCurrency: '',
      dailyWageForCountry: null,
      dailyWageForAbroad: null,
      dailyWageForAbroadCurrency: currencyDefaultOptions,
      dateOfSubmission: moment(),
    },
  };

  useEffect(() => {
    let isMounted = true;

    if (formInit) {
      const { travelOrder } = formInit;

      if (isMounted) {
        form.setFieldsValue({
          travelOrder: {
            travelOrderNo: travelOrder?.travelOrderNo || '',
            year: moment(travelOrder?.year) || moment(),
            reason: travelOrder?.reason || '',
            country: travelOrder?.country || '',
            city: travelOrder?.city || '',
            startDate: moment(travelOrder?.startDate) || moment(),
            numberOfDays: travelOrder?.numberOfDays || null,
            transportType: travelOrder?.transportType?.length ? travelOrder.transportType : [],
            paidAccommodation: travelOrder?.paidAccommodation || false,
            paidFood: travelOrder?.paidFood || '',
            paymentInAdvanceCountry: travelOrder?.paymentInAdvanceCountry || null,
            paymentInAdvanceAbroad: travelOrder?.paymentInAdvanceAbroad || null,
            paymentInAdvanceAbroadCurrency: travelOrder?.paymentInAdvanceAbroadCurrency || '',
            dailyWageForCountry: travelOrder?.dailyWageForCountry || null,
            dailyWageForAbroad: travelOrder?.dailyWageForAbroad || null,
            dailyWageForAbroadCurrency: travelOrder?.dailyWageForAbroadCurrency || currencyDefaultOptions,
            dateOfSubmission: travelOrder?.dateOfSubmission ? moment(travelOrder.dateOfSubmission) : moment(),
          },
        });
      }

      return () => {
        isMounted = false;
      };
    }
  }, [formInit, form, currencyDefaultOptions]);

  const onSubmit = (values) => {
    const payload = {
      ...values,
      employee: employeeId,
      company: companyId
    };

    if (isNew) {
      createOfficialTravel(token, payload)
          .then(() => {
            if (handleChangeTab) {
              handleChangeTab('2');
            }

            form.resetFields();
            handleRefetchData();

            notification.success({
              message: 'The data has been saved!',
              placement: 'bottomRight',
            });
          })
          .catch((err) => {
            console.error(err);
            notification.error({
              message: 'Problem with saving data. Contact administrator.',
              placement: 'bottomRight',
            });
          });
    }
    if (!isNew) {
      updateOfficialTravelById(token, payload, formInit._id)
        .then(() => {
          if (handleChangeTab) {
            handleChangeTab('2');
          }

          notification.success({
            message: 'The data has been saved!',
            placement: 'bottomRight',
          });
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: 'Problem with saving data. Contact administrator.',
            placement: 'bottomRight',
          });
        });
    }
  };

  function handleCancel() {
    if (handleChangeTab) {
      handleChangeTab('2');
    }
    form.resetFields();
  }

  function handleBack() {
    history.goBack();
  }

  const { lt } = useLocalTranslations(stepLang);

  const abroadCurrency = Form.useWatch('travelOrder', form);

  return (
      <div className='travel-order'>
        <div className='step-header'>
          <h3 className='block-title'>{lt('travelOrder.stepTitle')}</h3>
        </div>

        <Form
          form={form}
          onFinish={onSubmit}
          layout='vertical'
          initialValues={initialValues}
          style={{ width: '100%' }}
          className='travel-order-form'
        >
          <div className='input-wrapper'>
            <div>
              <Form.Item name={['travelOrder', 'travelOrderNo']} label={lt('travelOrder.travelOrderNo')} className='custom-label'>
                <Input placeholder={lt('travelOrder.travelOrderNo')} disabled={disabled || isEmployeeUser} />
              </Form.Item>

              <Form.Item
                  required
                  name={['travelOrder', 'city']}
                  label={lt('travelOrder.city')}
                  className='custom-label'
                  rules={[{ required: true, message: 'City is a required field!' }]}
              >
                <Input placeholder={lt('travelOrder.city')} disabled={disabled} />
              </Form.Item>

              <Form.Item name={['travelOrder', 'reason']} label={lt('travelOrder.reason')} className='custom-label'>
                <Input placeholder={lt('travelOrder.reason')} disabled={disabled} />
              </Form.Item>

              <Form.Item name={['travelOrder', 'numberOfDays']} label={lt('travelOrder.numberOfDays')} className='custom-label'>
                <Input type='number' placeholder={lt('travelOrder.numberOfDays')} disabled={disabled} />
              </Form.Item>

              <Form.Item name={['travelOrder', 'paidAccommodation']} label={lt('travelOrder.paidAccommodation')} className='custom-label'>
                <Select style={{ width: '100%' }} disabled={disabled}>
                  {paidAccommodationOptions.map((item) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                  name={['travelOrder', 'paymentInAdvanceCountry']}
                  label={lt('travelOrder.paymentInAdvanceCountry')}
                  className='custom-label'
              >
                <Input 
                  type='number' 
                  placeholder={lt('travelOrder.paymentInAdvanceCountry')} 
                  disabled={disabled || isEmployeeUser} 
                  suffix={
                    <Tooltip title="Currency">
                      RSD
                    </Tooltip>
                  }
                />
              </Form.Item>

              <Form.Item
                  name={['travelOrder', 'dailyWageForCountry']}
                  label={lt('travelOrder.dailyWageForCountry')}
                  className='custom-label'
              >
                <Input 
                  type='number' 
                  placeholder={lt('travelOrder.dailyWageForCountry')} 
                  disabled={disabled || isEmployeeUser} 
                  suffix={
                    <Tooltip title="Currency">
                      RSD
                    </Tooltip>
                  }                
                />
              </Form.Item>
              
              <Form.Item
                name={['travelOrder', 'dailyWageForAbroadCurrency']}
                label={lt('travelOrder.dailyWageForAbroadCurrency')}
                className='custom-label'
              >
                <Select 
                    style={{ width: '100%' }} 
                    disabled={disabled || isEmployeeUser}
                  >
                  {currencyOptions.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div>
              <Form.Item name={['travelOrder', 'year']} label={lt('travelOrder.year')} className='custom-label'>
                <DatePicker picker='year' format='YYYY' placeholder='YYYY' style={{ width: '100%' }} disabled={disabled} />
              </Form.Item>

              <Form.Item name={['travelOrder', 'country']} label={lt('travelOrder.country')} className='custom-label'>
                <Input placeholder={lt('travelOrder.country')} disabled={disabled} />
              </Form.Item>

              <Form.Item
                  required
                  name={['travelOrder', 'startDate']}
                  label={lt('travelOrder.startDate')}
                  className='custom-label'
                  rules={[{ required: true, message: 'Start Date is a required field!' }]}
              >
                <DatePicker format='DD-MM-YYYY' placeholder='DD-MM-YYYY' style={{ width: '100%' }} disabled={disabled} />
              </Form.Item>

              <Form.Item name={['travelOrder', 'transportType']} label={lt('travelOrder.transportType')} className='custom-label'>
                <Select mode='multiple' style={{ width: '100%' }} disabled={disabled}>
                  {transportTypeOptions.map((item, idx) => (
                      <Select.Option key={idx} value={item.value}>
                        {item.label}
                      </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                  name={['travelOrder', 'paidFood']}
                  label={lt('travelOrder.paidFood')}
                  className='custom-label'
              >
                <Select style={{ width: '100%' }} disabled={disabled}>
                  {paidFoodOptions.map((item, idx) => (
                    <Select.Option key={idx} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                  name={['travelOrder', 'paymentInAdvanceAbroad']}
                  label={lt('travelOrder.paymentInAdvanceAbroad')}
                  className='custom-label'
              >
                <Input 
                  type='number' 
                  placeholder={lt('travelOrder.paymentInAdvanceAbroad')} 
                  disabled={disabled || isEmployeeUser} 
                  suffix={
                    <Tooltip title="Currency">
                      {abroadCurrency?.dailyWageForAbroadCurrency}
                    </Tooltip>
                  }
                />
              </Form.Item>

              <Form.Item
                  name={['travelOrder', 'dailyWageForAbroad']}
                  label={lt('travelOrder.dailyWageForAbroad')}
                  className='custom-label'
              >
      
                  <Input 
                    type='number' 
                    placeholder={lt('travelOrder.dailyWageForAbroad')} 
                    disabled={disabled || isEmployeeUser} 
                    suffix={
                      <Tooltip title="Currency">
                        {abroadCurrency?.dailyWageForAbroadCurrency}
                      </Tooltip>
                    }
                  />
              </Form.Item>

              <Form.Item
                  name={['travelOrder', 'dateOfSubmission']}
                  label={lt('travelOrder.dateOfSubmission')}
                  className='custom-label'
              >
                <DatePicker format='DD-MM-YYYY' placeholder='DD-MM-YYYY' style={{ width: '100%' }} disabled={disabled} />
              </Form.Item>
            </div>
          </div>
        
          {hasViewDisabled ? (
            <div className="sticky-actions">
              <div className="grid">
                <div className="flex">
                  <StepControl currentStep={currentStep} onChangeStep={onChangeStep} />

                  <Button type='button' onClick={handleBack}>
                    Return
                  </Button>
                </div>
              </div>
            </div>
          ) : null}

          {!viewMode &&  (
            <div className="sticky-actions">
              <div className="grid">
                <div className="flex">
                  {!hasDisabled && (
                    <Button type="button" onClick={handleCancel} style={{ width: '100px' }}>
                      Cancel
                    </Button>
                  )}
                  
                  {formInit?.employee && (
                    <Button type="button" onClick={handleBack} style={{ border: 0, shadow: 0 }} >Cancel</Button>
                  )}

                  <Button style={{ width: '200px' }} type='primary' htmlType='submit' disabled={disabled}>
                    Save order
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Form>

      </div>
  );
}
