/**
 * Resets the scroll position of an element to the top.
 * This function acts as a helper to ensure that when a user opens a new drawer, 
 * the scroll position is reset to the top, enhancing user experience.
 * 
 * @param {string} selector - The class or selector of the element whose scroll position needs to be reset.
 */
export const scrollToTop = (selector) => {
  const element = document.querySelector(selector);

  if (element) {
    element.scrollTo(0, 0);
  }
};
