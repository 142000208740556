import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../App';
import { Input, Button, Form, Tabs, notification, Modal } from 'antd';
import { CheckOutlined, CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import useAxios from '../../hooks/useAxios';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import moment from 'moment';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

const initialValues = {
  commentRejection: undefined,
};

const InvoiceDetail = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [invoice, fetchInvoice] = useAxios('', [], currentuser.data.token, 'get');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [inputContent, setInputContent] = useState('');
  const [embedStyle, setEmbedStyle] = useState({});
  const [embedContainerStyle, setEmbedContainerStyle] = useState({});

  const { _id } = props.match.params;

  const selectedEInvoiceCompanyId = (() => {
    const storedCompanyId = localStorage.getItem('selectedEInvoiceCompany');
    const companies = Array.isArray(currentuser?.data?.company) ? currentuser.data.company : [currentuser?.data?.company].filter(Boolean);
    const companyIds = companies.length > 0 ? companies.map(item => item._id) : [];

    return companyIds.includes(storedCompanyId)
        ? storedCompanyId
        : companies.length > 0 ? companies[0]?._id : null;
  })();

  // this handles 'enter' on keyboard for OK in Accept modal
  useEffect(() => {
    if (isModalOpen) {
      const modalContainer = document.querySelector('.ant-modal');
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          // Trigger the OK button when Enter is pressed
          handleOk();
        }
      };
      modalContainer.addEventListener('keydown', handleKeyDown);
      // Clean up the event listener when the modal is closed
      return () => {
        modalContainer.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isModalOpen]);

  // invoices are with 100% width pdf viewer
  useEffect(() => {
    setEmbedStyle({
      width: '80%',
      height: '100%',
      position: 'fixed',
      top: window.location.pathname.includes('sales') ? 150 : 100,
      left: 300,
      right: 0,
      bottom: 0,
      zIndex: 1000,
    });
    setEmbedContainerStyle({
      display: 'flex',
      width: '100%',
    });
  }, [invoice]);

  useEffect(() => {
    if (window.location.pathname.includes('sales')) {
      fetchInvoice(`${SERVER_URL}/view-sales/${_id}?filter=${JSON.stringify(selectedEInvoiceCompanyId)}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
    } else if (window.location.pathname.includes('purchase')) {
      fetchInvoice(`${SERVER_URL}/view-purchase/${_id}?filter=${JSON.stringify(selectedEInvoiceCompanyId)}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
    }
  }, [fetchInvoice]);

  useEffect(() => {
    if (invoice?.data) {
      form.setFieldsValue({
        customerName: invoice?.data?.customerName,
        supplierName: invoice?.data?.supplierName,
        documentType: invoice?.data?.documentType,
        documentNumber: invoice?.data?.documentNumber,
        documentDate: moment(invoice?.data?.documentDate),
        supplyDate: moment(invoice?.data?.supplyDate),
        dueDate: moment(invoice?.data?.dueDate),
        interestArrears: invoice?.data?.interestArrears,
        poNumber: invoice?.data?.poNumber,
        customerReference: invoice?.data?.customerReference,
        supplierReference: invoice?.data?.supplierReference,
        paymentReference: invoice?.data?.paymentReference,
        comment: invoice?.data?.comment,
        totalDiscounts: invoice?.data?.totalDiscounts,
        totalVatExcl: invoice?.data?.totalVatExcl,
        total: invoice?.data?.total,
        prepaidAmount: invoice?.data?.prepaidAmount,
        openBalance: invoice?.data?.openBalance,
        products: invoice?.data?.products,
        pdf: invoice?.data?.pdf,
        attachment: invoice?.data.attachment,
        qrCode: invoice?.data.qrCode,
      });
    }
  }, [invoice]);

  const sendInvoice = async () => {
    let updatedData = invoice.data;
    if (Array.isArray(invoice.data.vats)) {
      const { vats, ...rest } = invoice.data;
      const updatedVats = { ...vats[0] };
      updatedData = { ...rest, vats: updatedVats };
    }
    updatedData = {
      ...updatedData,
      products: updatedData.products.map((product) => ({
        ...product,
        discount: Number(product.discount),
        grossPrice: Number(product.grossPrice),
        quantity: Number(product.quantity),
        ratePrice: Number(product.ratePrice),
        subtotal: Number(product.subtotal),
        vat: Number(product.vat),
      })),
    };

    try {
      await Axios.post(`${SERVER_URL}/postUbl?filter=${JSON.stringify(selectedEInvoiceCompanyId)}`, updatedData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `The invoice has been sent.`,
        placement: 'bottomRight',
      });
      history.push('/admin/accounts-receivable');
    } catch (error) {
      notification.error({
        message: `Problem with sending the invoice. Please try again later.`,
        placement: 'bottomRight',
      });
    }
  };

  const acceptInvoice = async (id, comment) => {
    try {
      await Axios.post(`${SERVER_URL}/accept-purchase/${id}?filter=${JSON.stringify(selectedEInvoiceCompanyId)}`, comment, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'The invoice has been accepted.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        history.go(-1);
      }, 1000);
    } catch (err) {
      notification.error({
        message: 'Problem with accepting. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const rejectInvoice = async (id, formValues) => {
    const comment = { comment: formValues.commentRejection };
    try {
      await Axios.post(`${SERVER_URL}/reject-purchase/${id}?filter=${JSON.stringify(selectedEInvoiceCompanyId)}`, comment, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      }).then((res) => {
        if (res.status === 200) {
          notification.success({
            message: 'The invoice has been rejected.',
            placement: 'bottomRight',
          });
          setTimeout(() => {
            history.go(-1);
          }, 1000);
        }
      });
    } catch (err) {
      notification.error({
        message: 'Problem with rejecting. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  // TODO uncomment this upon implementation
  // const archiveInvoice = async (id) => {
  //   try {
  //     let companyId = currentuser.data.company._id;
  //     await Axios.post(
  //       `${SERVER_URL}/archive-purchase/${id}?filter=${JSON.stringify(companyId)}`,
  //       {},
  //       {
  //         withCredentials: false,
  //         headers: { Authorization: `Bearer ${currentuser.data.token}` },
  //       },
  //     );
  //     notification.success({
  //       message: 'The invoice has been archived.',
  //       placement: 'bottomRight',
  //     });
  //     // history.push('/admin/accounts-payable');
  //   } catch (err) {
  //     notification.error({
  //       message: 'Problem with archiving. Please try later.',
  //       placement: 'bottomRight',
  //     });
  //   } finally {
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 700);
  //   }
  // };

  const handleOk = () => {
    if (modalType === 'storn') {
    } else if (modalType === 'accept') {
      acceptInvoice(_id, form.getFieldsValue());
    } else if (modalType === 'reject') {
      rejectInvoice(_id, form.getFieldsValue());
    }
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    setInputContent(e.target.value);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleBack = () => {
    setTimeout(() => {
      history.go(-1);
    }, 500);
  };

  const downloadInvoice = () => {
    fetch(`${SERVER_URL}/view-sales/${_id}?filter=${JSON.stringify(selectedEInvoiceCompanyId)}`).then((response) => {
      response.blob().then(() => {
        let alink = document.createElement('a');
        alink.href = `data:application/pdf;base64,${invoice?.data?.pdf}`;
        alink.download = `${invoice?.data?.documentDate}-${invoice?.data?.supplierName}.pdf`;
        alink.click();
      });
    });
  };

  return (
    <div style={{ padding: '8px', height: '100%', width: '100%' }}>
      {invoice?.data?.status === 'Draft' && (
        <div className='actions-block'>
          <Link to={`/admin/edit-invoice/${_id}`}>
            <Button type='primary'>Edit invoice</Button>
          </Link>
          <Button type='primary' style={{ marginLeft: '10px' }} onClick={sendInvoice}>
            Send invoice
          </Button>
        </div>
      )}

      <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'space-between' }}>
        {invoice.data.type === 'sales' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginRight: '5px',
              marginLeft: '8px',
              marginTop: '83px',
              maxWidth: '100px',
              minWidth: '52px',
              maxHeight: '60px',
              justifyContent: 'center',
              backgroundColor: '#2db1ab',
              borderTopColor: 'black',
              borderLeftColor: 'black',
              borderRightColor: 'black',
              borderBottomColor: 'black',
              borderStyle: 'solid',
              width: '6%',
            }}
          >
            <div style={{ cursor: 'pointer', marginLeft: '5px', marginTop: '5px' }}>
              <DownloadOutlined
                title='Download invoice'
                style={{
                  backgroundColor: 'white',
                  borderStyle: 'solid',
                  borderLeftColor: 'black',
                  borderBottomColor: 'black',
                  borderRightColor: 'black',
                  borderTopColor: 'black',
                  marginTop: '10px',
                  paddingLeft: '8px',
                }}
                onClick={downloadInvoice}
              />
            </div>
            <div
              style={{
                cursor: 'pointer',
                marginLeft: '5px',
                marginTop: '10px',
                marginBottom: '10px',
                color: 'black',
                fontWeight: '700',
              }}
              onClick={handleBack}
            >
              BACK
            </div>
          </div>
        )}
        {invoice.data.type !== 'sales' && (invoice.data.status === 'Seen' || invoice.data.status === 'Rejected') && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginRight: '5px',
              marginLeft: '8px',
              marginTop: '83px',
              maxWidth: '100px',
              minWidth: '52px',
              maxHeight: '140px',
              justifyContent: 'center',
              backgroundColor: '#2db1ab',
              borderTopColor: 'black',
              borderLeftColor: 'black',
              borderRightColor: 'black',
              borderBottomColor: 'black',
              borderStyle: 'solid',
              width: '6%',
              height: invoice.data.status !== 'Rejected' ? '140px' : '100px',
            }}
          >
            <div style={{ cursor: 'pointer', marginLeft: '5px', marginTop: '6px' }}>
              <CheckOutlined
                title='Accept invoice'
                style={{
                  backgroundColor: 'white',
                  borderStyle: 'solid',
                  borderLeftColor: 'black',
                  borderBottomColor: 'black',
                  borderRightColor: 'black',
                  borderTopColor: 'black',
                  paddingLeft: '8px',
                }}
                onClick={() => {
                  setIsModalOpen(true);
                  setModalType('accept');
                }}
              />
            </div>
            {invoice.data.status !== 'Rejected' && (
              <div style={{ cursor: 'pointer', marginLeft: '5px', marginTop: '5px' }}>
                <CloseOutlined
                  title='Reject invoice'
                  style={{
                    backgroundColor: 'white',
                    borderStyle: 'solid',
                    borderLeftColor: 'black',
                    borderBottomColor: 'black',
                    borderRightColor: 'black',
                    borderTopColor: 'black',
                    marginTop: '5px',
                    paddingLeft: '8px',
                  }}
                  onClick={() => {
                    setIsModalOpen(true);
                    setModalType('reject');
                  }}
                />
              </div>
            )}
            <div style={{ cursor: 'pointer', marginLeft: '5px', marginTop: '5px' }}>
              <DownloadOutlined
                title='Download invoice'
                style={{
                  backgroundColor: 'white',
                  borderStyle: 'solid',
                  borderLeftColor: 'black',
                  borderBottomColor: 'black',
                  borderRightColor: 'black',
                  borderTopColor: 'black',
                  marginTop: '5px',
                  paddingLeft: '8px',
                }}
                onClick={downloadInvoice}
              />
            </div>
            <div
              style={{
                cursor: 'pointer',
                marginLeft: '5px',
                marginTop: '10px',
                color: 'black',
                fontWeight: '700',
              }}
              onClick={handleBack}
            >
              BACK
            </div>
          </div>
        )}
        {invoice.data.type !== 'sales' && invoice.data.status !== 'Seen' && invoice.data.status !== 'Rejected' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginRight: '5px',
              marginLeft: '8px',
              marginTop: '83px',
              maxWidth: '100px',
              minWidth: '52px',
              maxHeight: '60px',
              justifyContent: 'center',
              backgroundColor: '#2db1ab',
              borderTopColor: 'black',
              borderLeftColor: 'black',
              borderRightColor: 'black',
              borderBottomColor: 'black',
              borderStyle: 'solid',
              width: '6%',
            }}
          >
            {/* TODO uncomment this after archive implementation */}
            {/*<div style={{ cursor: 'pointer', marginLeft: '5px', marginTop: '6px' }}>*/}
            {/*  <Popconfirm*/}
            {/*      placement='left'*/}
            {/*      title={`This will archive the invoice`}*/}
            {/*      onConfirm={() => archiveInvoice(_id)}*/}
            {/*      okText='Ok'*/}
            {/*      cancelText='Cancel'*/}

            {/*  >*/}
            {/*    <FolderAddOutlined*/}
            {/*        title='Archive invoice'*/}
            {/*        style={{*/}
            {/*          backgroundColor: 'white',*/}
            {/*          borderStyle: 'solid',*/}
            {/*          borderLeftColor: 'black',*/}
            {/*          borderBottomColor: 'black',*/}
            {/*          borderRightColor: 'black',*/}
            {/*          borderTopColor: 'black',*/}
            {/*          paddingLeft: '8px',*/}
            {/*          paddingRight: '4px'*/}
            {/*        }}*/}
            {/*    />*/}
            {/*  </Popconfirm>*/}
            {/*</div>*/}

            <div
              style={{
                cursor: 'pointer',
                marginLeft: '5px',
                marginTop: '4px',
                color: 'black',
                fontWeight: '700',
              }}
              onClick={handleBack}
            >
              BACK
            </div>
          </div>
        )}
        <Modal
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          style={{ top: 20, zIndex: 10000 }}
          okText='OK'
          cancelText='Cancel'
          okButtonProps={modalType === 'reject' ? { disabled: !inputContent.length } : {}}
        >
          <h2>
            {modalType === 'accept' ? (
              'Please confirm Invoice Status update'
            ) : (
              <>
                Please enter the reason why you want to <br /> reject the invoice.
              </>
            )}
          </h2>
          <Form form={form} initialValues={initialValues}>
            {modalType === 'reject' && (
              <Form.Item name='commentRejection'>
                <Input onChange={handleChange} />
              </Form.Item>
            )}
          </Form>
        </Modal>
        <div style={embedContainerStyle}>
          <embed
            // src={`data:application/pdf;base64,${invoice?.data?.unifiedPdf ? invoice?.data?.unifiedPdf : invoice?.data?.pdf}`}
              src={`data:application/pdf;base64,${invoice?.data?.sefPdf ? invoice?.data?.sefPdf : invoice?.data?.unifiedPdf ? invoice?.data?.unifiedPdf : invoice?.data?.pdf}`}
            style={embedStyle}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetail;
