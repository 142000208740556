import { Fragment } from "react";
import { cn } from "../../../libs";
import { STEPS } from "../../../shared/steps-data";
import { useLocation } from "react-router-dom";
import { hasRequestPermission } from "../../../utils";

export default function StepNavbar({ activeSteps, onChangeStep }) {
  const { pathname } = useLocation();
  const hasEditDisabled = hasRequestPermission(pathname, 'edit');
  const hasViedDisabled = hasRequestPermission(pathname, 'view');
  const hasDisabled = hasEditDisabled || hasViedDisabled;

  const activeClasses = (stepValue) => {
    const isActive = activeSteps.includes(stepValue);
    return isActive ? 'active' : '';
  }

  const isLastObject = (id) => {
    const findLastObject = STEPS.at(-1);

    if (findLastObject.id === id) {
      return true;
    }

    return null;
  }


  return (
    <nav className="step-navbar">
      <ul className="navbar-items">
        {STEPS.map((step) => {
          const disabled = step.value !== 1 && !hasDisabled;

          return (
            <Fragment key={step.id}>
              <li onClick={() => !disabled && onChangeStep(step.value)} className={cn("navbar-item", activeClasses(step.value), disabled ? 'disabled' : '')}>
                <span className="item-text">{step.text}</span>
                <button disabled={disabled} className="item-label" type="button">{step.label}</button>
              </li>

              {!isLastObject(step.id) && <li className={cn("navbar-line", activeClasses(step.value))} />}
            </Fragment>
          )
        })}
      </ul>
    </nav>
  )
}
