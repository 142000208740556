import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, DatePicker, Form, Input, notification, Tooltip } from 'antd';
import { UserContext } from '../App';
import { SERVER_URL } from '../config';
import Axios from 'axios';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { disabledDate } from '../helpers/dates.js';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { OfficialTravel } from './forms/official-travel/index.js';
import { getUserFullName, hasUserData } from '../utils/index.js';

const { RangePicker } = DatePicker;

const HrEdit = () => {
  const [form] = Form.useForm();

  const currentuser = useContext(UserContext);
  const [request, setRequest] = useState(undefined);
  const [refetchRequest, setRefetchRequest] = useState(false);

  const [currentRequestId] = useState(window.location.pathname.replace('/admin/edit-request/', ''));
  const [initialValues, setInitialValues] = useState(undefined);
  const [dateFrom, setDateFrom] = useState(initialValues?.dateFrom);
  const [dateTo, setDateTo] = useState(initialValues?.dateTo);
  const [dateOvertime, setDateOvertime] = useState(initialValues?.dateOvertime);
  const [firstDayBack, setFirstDayBack] = useState(initialValues?.firstDayBack);
  const [numOfDays, setNumOfDays] = useState(initialValues?.numOfDays);
  const [disabled, setDisabled] = useState(false);
  const [dateOfCreation, setDateOfCreation] = useState(undefined);
  const history = useHistory();

  const fetchRequestById = useCallback(async () => {
    try {
      return await Axios.get(`${SERVER_URL}/request/${currentRequestId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
    } catch (error) {
      console.error('error in fetch request by id', error);
    }
  }, [currentRequestId, currentuser.data.token]);

  const handleRefetchRequest = () => {
    setRefetchRequest(p => !p);
  }

  useEffect(() => {
    if (currentRequestId) {
      fetchRequestById().then((r) => setRequest(r));
    }
  }, [currentRequestId, fetchRequestById, refetchRequest]);

  useEffect(() => {
    if (request) {
      setInitialValues({ ...request?.data?.request });
      //this value is set as state as dateOvertime date picker is not part of the form
      const overtimeDateMoment = moment(`${request?.data?.request?.dateOvertime}`, 'DD-MM-YYYY') || '';
      setDateOvertime(overtimeDateMoment);

      const picker = {
        picker: [moment(`${initialValues?.dateFrom}`, 'DD-MM-YYYY'), moment(`${initialValues?.dateTo}`, 'DD-MM-YYYY')],
      };
      const createdDate = moment(initialValues?.createdAt).format('DD-MM-YYYY');
      form.setFieldsValue(request?.data?.request);
      form.setFieldsValue(picker);
      form.setFieldsValue({ firstDayBack: moment(`${initialValues?.firstDayBack}`, 'DD-MM-YYYY') });
      form.setFieldsValue({ createdAt: moment(`${createdDate}`, 'DD-MM-YYYY') });
      if (request?.data?.request?.status === 'accepted' || request?.data?.request?.status === 'denied') {
        setDisabled(true);
      }
    }
  }, [
    request?.data,
    form,
    initialValues?.dateFrom,
    initialValues?.dateOvertime,
    initialValues?.dateTo,
    initialValues?.firstDayBack,
    request,
  ]);

  // those three useEffects are needed to set datePickers and rangePickers as well as numOfDays to default initial values
  useEffect(() => {
    form.setFieldsValue({ firstDayBack: moment(`${initialValues?.firstDayBack}`, 'DD-MM-YYYY') });
  }, [form, initialValues?.firstDayBack]);

  useEffect(() => {
    const picker = {
      picker: [moment(`${initialValues?.dateFrom}`, 'DD-MM-YYYY'), moment(`${initialValues?.dateTo}`, 'DD-MM-YYYY')],
    };
    form.setFieldsValue(picker);
  }, [form, initialValues?.dateFrom, initialValues?.dateTo]);

  useEffect(() => {
    form.setFieldsValue({ numOfDays: initialValues?.numOfDays });
  }, [form, initialValues?.numOfDays]);

  useEffect(() => {
    setDateFrom(initialValues?.dateFrom);
    setDateTo(initialValues?.dateTo);
    setFirstDayBack(initialValues?.firstDayBack);
    setNumOfDays(initialValues?.numOfDays);
  }, [initialValues]);

  const onFinish = async (values) => {
    // set dateOvertime value as it is not part of the form
    const dateOvertimeForDb = dateOvertime ? dateOvertime.format('L') : '';
    const {
      numOfDays,
      basisOvertime,
      typeBonus,
      numOfHoursOvertime,
      percentageBonusOvertime,
      valueBonus,
      currencyBonus,
      typeOfLeave,
      reasonOfLeave,
      reasonOfLeaveEng,
    } = values;
    try {
      const employeeId = request?.data?.request?.employee;
      await Axios.put(
        `${SERVER_URL}/request/${currentRequestId}`,
        {
          currentRequestId,
          numOfDays,
          basisOvertime,
          typeBonus,
          dateFrom,
          dateTo,
          firstDayBack,
          employeeId,
          dateOvertime: dateOvertimeForDb,
          numOfHoursOvertime,
          percentageBonusOvertime,
          valueBonus,
          currencyBonus,
          typeOfLeave,
          reasonOfLeave,
          reasonOfLeaveEng,
          dateOfCreation,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: 'The request has been edited successfully.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        history.push('/admin/hr-platform-requests');
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem with editing the request. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);

  useEffect(() => {
    if (dateFrom && dateTo && numOfDays) {
      let array = [];
      array.push(dateFrom);
      array.push(dateTo);
      array.push(numOfDays);
      setRangePickerValue(array);
    }
  }, [dateFrom, dateTo, numOfDays, setDateFrom, setDateTo, setRangePickerValue]);

  function setRangePickerValue(value, flag) {
    let countDays;
    let firstDayBack;
    if (value && value.length > 1) {
      let start = moment(value[0]);
      let startWhile = moment(value[0]);
      let end = moment(value[1]);
      countDays = 0;
      while (startWhile <= end) {
        if (startWhile.format('E') !== '6' && startWhile.format('E') !== '7') {
          countDays++;
        }
        startWhile = moment(startWhile, 'YYYY-MM-DD').add(1, 'days');
        setNumOfDays(countDays);
        end.format('E') === '5'
          ? (firstDayBack = moment(end, 'YYYY-MM-DD').add(3, 'days'))
          : (firstDayBack = moment(end, 'YYYY-MM-DD').add(1, 'days'));

        setDateFrom(start.format('L'));
        setDateTo(end.format('L'));
        setFirstDayBack(firstDayBack.format('L'));
        form.setFieldsValue({ numOfDays: flag ? countDays : value[2] });
        form.setFieldsValue({
          firstDayBack: flag
            ? moment(`${firstDayBack.format('L')}`, 'DD-MM-YYYY')
            : moment(`${initialValues?.firstDayBack}`, 'DD-MM-YYYY'),
        });
      }
    }
  }

  const overtimeDateChange = (date) => {
    setDateOvertime(date);
  };

  /**
   * TODO: USER TESTING request array (INDEX point[0])
   */
  const userData = hasUserData(request?.data?.request);
  const userFullName = userData ? getUserFullName(userData) : 'Loading...';

  const displayForm = () => {
    if (request?.data?.request?.typeOfLeave === 'Annual leave') {
      return (
        <Form
          className='form-horizontal dataForm'
          layout='horizontal'
          initialValues={initialValues}
          form={form}
          labelCol={20}
          onFinish={onFinish}
        >
          <Form.Item
            label='Name'
            name='firstName'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Surname'
            name='lastName'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Email'
            name='email'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Status of leave'
            name='status'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Type of leave'
            name='typeOfLeave'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Date of leave'
            className='bold-label request-label-width'
            name='picker'
            labelCol={{ span: 6 }}
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please select date!',
              },
            ]}
          >
            <RangePicker
              disabled={disabled}
              disabledDate={disabledDate}
              onCalendarChange={(val) => setRangePickerValue(val, true)}
              format='DD-MM-YYYY'
            />
          </Form.Item>
          <Form.Item
            label='Number of days'
            className='bold-label request-label-width'
            name='numOfDays'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='First day back'
            className='bold-label request-label-width'
            name='firstDayBack'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <DatePicker disabled disabledDate={disabledDate} allowClear={false} format='DD-MM-YYYY' />
          </Form.Item>
          <Form.Item
            label='Date of request creation'
            className='bold-label request-label-width'
            name='createdAt'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <DatePicker disabled disabledDate={disabledDate} allowClear={false} format='DD-MM-YYYY' />
          </Form.Item>
          {!currentuser.data.role.includes('employee') && (
            <Form.Item
              label='Date of request acceptance'
              name='dateOfCreation'
              className='bold-label request-label-width'
              labelCol={{ span: 6 }}
              labelAlign='left'
              style={{ marginTop: '-1px' }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DatePicker
                  disabledDate={disabledDate}
                  onChange={(val) => setDateOfCreation(val)}
                  format={'DD-MM-YYYY'}
                />
                <Tooltip
                  title='Select a date to manually set the request acceptance date. Otherwise, todays date will be the request acceptance date.'
                  color='#b1b1b1'
                  placement='bottom'
                  autoAdjustOverflow={true}
                >
                  <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#b1b1b1', marginBottom: '5px' }} />
                </Tooltip>
              </div>
            </Form.Item>
          )}
          <Button type='primary' htmlType='submit' style={{ float: 'right', marginTop: '10px' }} disabled={disabled}>
            Update
          </Button>
        </Form>
      );
    } else if (
      request?.data?.request?.typeOfLeave === 'Paid days off' ||
      request?.data?.request?.typeOfLeave === 'Unpaid days off' ||
      request?.data?.request?.typeOfLeave === 'Slava - Saint day'
    ) {
      return (
        <Form
          className='form-horizontal dataForm'
          layout='horizontal'
          initialValues={initialValues}
          form={form}
          labelCol={20}
          onFinish={onFinish}
        >
          <Form.Item
            label='Name'
            name='firstName'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Surname'
            name='lastName'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Email'
            name='email'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Status of leave'
            name='status'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Type of leave'
            name='typeOfLeave'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          {form.getFieldValue('reasonOfLeave') && (
            <Form.Item
              label='Reason of leave - Serbian'
              name='reasonOfLeave'
              className='bold-label request-label-width'
              labelCol={{ span: 6 }}
              labelAlign='left'
            >
              <Input />
            </Form.Item>
          )}
          {form.getFieldValue('reasonOfLeaveEng') && (
            <Form.Item
              label='Reason of leave - English'
              name='reasonOfLeaveEng'
              className='bold-label request-label-width'
              labelCol={{ span: 6 }}
              labelAlign='left'
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            label='Date of leave'
            className='bold-label request-label-width'
            name='picker'
            labelCol={{ span: 6 }}
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please select date!',
              },
            ]}
          >
            <RangePicker
              disabledDate={disabledDate}
              onChange={(val) => setRangePickerValue(val, true)}
              format='DD-MM-YYYY'
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item
            label='Number of days'
            className='bold-label request-label-width'
            name='numOfDays'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='First day back'
            className='bold-label request-label-width'
            name='firstDayBack'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <DatePicker disabled disabledDate={disabledDate} allowClear={false} format='DD-MM-YYYY' />
          </Form.Item>
          <Button type='primary' htmlType='submit' style={{ float: 'right', marginTop: '10px' }} disabled={disabled}>
            Update
          </Button>
        </Form>
      );
    } else if (
      request?.data?.request?.typeOfLeave === 'Maternity leave' ||
      request?.data?.request?.typeOfLeave === 'Pregnancy leave'
    ) {
      return (
        <Form
          className='form-horizontal dataForm'
          layout='horizontal'
          initialValues={initialValues}
          form={form}
          labelCol={20}
          onFinish={onFinish}
        >
          <Form.Item
            label='Name'
            name='firstName'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Surname'
            name='lastName'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Email'
            name='email'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Status of leave'
            name='status'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Type of leave'
            name='typeOfLeave'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Date of leave'
            className='bold-label request-label-width'
            name='picker'
            labelCol={{ span: 6 }}
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please select date!',
              },
            ]}
          >
            <RangePicker
              disabledDate={disabledDate}
              onChange={(val) => setRangePickerValue(val, true)}
              format='DD-MM-YYYY'
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item
            label='Number of days'
            className='bold-label request-label-width'
            name='numOfDays'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name='firstDayBack'
            label='First day back'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <DatePicker disabled disabledDate={disabledDate} allowClear={false} format='DD-MM-YYYY' />
          </Form.Item>
          <Button type='primary' htmlType='submit' style={{ float: 'right', marginTop: '10px' }} disabled={disabled}>
            Update
          </Button>
        </Form>
      );
    } else if (request?.data?.request?.typeOfLeave === 'Bonus') {
      return (
        <Form
          className='form-horizontal dataForm'
          layout='horizontal'
          initialValues={initialValues}
          form={form}
          labelCol={20}
          onFinish={onFinish}
        >
          <Form.Item
            label='Name'
            name='firstName'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Surname'
            name='lastName'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Email'
            name='email'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Status of leave'
            name='status'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Type of leave'
            name='typeOfLeave'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Date of leave'
            className='bold-label request-label-width'
            name='picker'
            labelCol={{ span: 6 }}
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please select date!',
              },
            ]}
          >
            <RangePicker
              disabledDate={disabledDate}
              onCalendarChange={(val) => setRangePickerValue(val, true)}
              format='DD-MM-YYYY'
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item
            label='Type of bonus'
            name='typeBonus'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Value'
            name='valueBonus'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Currency'
            name='currencyBonus'
            className='bold-label request-label-width'
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <Input />
          </Form.Item>
          <Button type='primary' htmlType='submit' style={{ float: 'right', marginTop: '10px' }} disabled={disabled}>
            Update
          </Button>
        </Form>
      );
    } else if (request?.data?.request?.typeOfLeave === 'Overtime') {
      return (
        <Form
          className='form-horizontal dataForm'
          layout='horizontal'
          initialValues={initialValues}
          form={form}
          labelCol={20}
          onFinish={onFinish}
        >
          <Form.Item
            label='Name'
            name='firstName'
            className='bold-label request-label-width'
            labelCol={{ span: 9 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Surname'
            name='lastName'
            className='bold-label request-label-width'
            labelCol={{ span: 9 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Email'
            name='email'
            className='bold-label request-label-width'
            labelCol={{ span: 9 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Status of leave'
            name='status'
            className='bold-label request-label-width'
            labelCol={{ span: 9 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Type of leave'
            name='typeOfLeave'
            className='bold-label request-label-width'
            labelCol={{ span: 9 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='Basis for overtime'
            name='basisOvertime'
            className='bold-label request-label-width'
            labelCol={{ span: 9 }}
            labelAlign='left'
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Number of hours worked overtime'
            name='numOfHoursOvertime'
            className='bold-label request-label-width'
            labelCol={{ span: 9 }}
            labelAlign='left'
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Percentage of bonus for overtime'
            name='percentageBonusOvertime'
            className='bold-label request-label-width'
            labelCol={{ span: 9 }}
            labelAlign='left'
          >
            <Input disabled />
          </Form.Item>
          <Button type='primary' htmlType='submit' style={{ float: 'right', marginTop: '10px' }} disabled={disabled}>
            Update
          </Button>
        </Form>
      );
    } else if (request?.data?.request?.typeOfLeave === 'Official travel') {
      return <OfficialTravel
              userFullName={userFullName}
              handleRefetchRequest={handleRefetchRequest}
              typeOfLeave={request?.data?.request?.typeOfLeave}
              officialTravel={request.data.request}
              companyId={request.data.request.company}
              employeeId={request.data.request.employee}
              token={currentuser.data.token}
              isEmployeeUser={currentuser.data.role.length === 1 && currentuser.data.role[0] === 'employee'}
            />
    }
  };

  return (
    <div className='dashboard'>
      <div style={{ width: '100%', maxWidth: '1280px', marginTop: '20px' }}>
        <Card title="EDIT EMPLOYEE'S REQUEST">
          {request?.data?.request?.typeOfLeave === 'overtime' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '25px',
                marginTop: '25px',
                justifyContent: 'space-between',
              }}
            >
              <>
                <div
                  style={{
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  Date of overtime:
                </div>
                <div />
                <div style={{ marginLeft: '16px' }}>
                  <DatePicker
                    disabledDate={disabledDate}
                    allowClear={false}
                    format='DD-MM-YYYY'
                    value={dateOvertime}
                    onChange={overtimeDateChange}
                  />
                </div>
                <div />
                <div />
                <div />
                <div />
              </>
            </div>
          )}
          {displayForm()}
        </Card>
      </div>
    </div>
  );
};

export default HrEdit;
