import React from 'react';
import { useThemeContext } from '../../context/ThemeContext.jsx';

export default function TimeSheetUploadIcon(props) {
  const { theme } = useThemeContext();
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      fillRule='evenodd'
      clipRule='evenodd'
      viewBox='0 0 512 433.601'
      width='25'
      height='25'
      {...props}
    >
      <path
        fill={theme === 'dark' ? 'white' : '#1A1A1A'}
        fillRule='nonzero'
        d='M359.785 124.708a172.229 172.229 0 00-9.414 5.043c-9.373 5.498-18.625 12.21-28.082 19.915l-20.168-23.081a166.9 166.9 0 0122.5-17.54 154.805 154.805 0 0119.919-11.042 148.056 148.056 0 018.329-3.876c-13.289-23.542-32.415-40.415-54.082-50.832-43.064-20.618-96.686-16.131-134.618 13.332-21.792 16.872-38.336 42.127-44.71 75.582l-2.002 10.456-10.415 1.836c-10.209 1.79-19.331 4.252-27.332 7.375-7.75 3-14.666 6.708-20.707 11.083-4.835 3.5-9.002 7.418-12.543 11.669-10.958 13.123-16.043 29.578-15.918 46.248.124 16.914 5.628 33.995 15.792 48.037 3.792 5.21 8.169 10.003 13.166 14.171a66.312 66.312 0 0017.211 10.333c6.374 2.622 13.415 4.496 21.207 5.581h11.336a148.43 148.43 0 00.762 30.667H97.417l-1.918-.167c-11.121-1.418-21.207-4.041-30.372-7.833-9.462-3.918-17.832-8.961-25.208-15.127-7-5.831-13.128-12.46-18.333-19.667C7.671 267.708.171 244.289.003 220.959c-.166-23.585 7.167-47.041 23.001-66.041 5.123-6.165 11.163-11.835 18.078-16.832 8.043-5.836 17.254-10.75 27.67-14.792 7.166-2.794 14.834-5.127 22.913-6.999 9.169-36.416 28.712-64.461 53.837-83.917C192.571-4.098 258.606-9.919 312.04 15.751c29.203 14.045 54.703 37.418 71.247 70.293 6.666-1.044 13.332-1.586 19.956-1.503 69.258.514 109.454 59.525 108.748 124.041-.292 26.293-7.374 52.46-21.874 71.706-9.457 12.544-21.584 22.793-36.125 30.919-13.999 7.833-30.33 13.79-48.663 18.042l-3.341.407a148.653 148.653 0 001.219-19.008c0-4.132-.18-8.222-.513-12.267 13.689-3.477 25.846-8.068 36.257-13.883 10.957-6.125 19.919-13.583 26.625-22.543 10.416-13.874 15.543-33.496 15.752-53.624.561-47.211-26.496-92.875-78.293-93.204-14.291-.126-29.166 3.332-43.25 9.581z'
      />
      <path
        fill='#FF654F'
        d='M256 187.697c67.902 0 122.952 55.05 122.952 122.952S323.902 433.601 256 433.601s-122.952-55.05-122.952-122.952S188.098 187.697 256 187.697z'
      />
      <path
        fill='#fff'
        d='M271.406 369.395h-30.809c-6.095 0-11.088-4.99-11.088-11.087v-39.633h-23.076c-3.802-.163-6.503-1.425-8.059-3.793-4.228-6.339 1.542-12.603 5.549-17.017 11.363-12.472 39.215-42.448 44.824-49.045 4.249-4.7 10.301-4.7 14.552 0 5.792 6.768 35.046 38.109 45.848 50.241 3.749 4.22 8.384 9.976 4.48 15.821-1.594 2.368-4.265 3.63-8.066 3.793h-23.067v39.633c0 6.09-4.997 11.087-11.088 11.087z'
      />
    </svg>
  );
}
