import React, { useContext, useState, useEffect } from 'react';
import { Table, Popconfirm, Input, Button, Modal, Form, notification } from 'antd';
import {
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
  FolderAddOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';

const initialValues = {
  comment: undefined,
};

const InvoicesTable = ({
  type,
  dataSource,
  dataForSearch,
  deleteHandler,
  columnKeys,
  archiveHandler,
  acceptHandler,
  rejectHandler,
  purchaseHandler,
  salesHandler,
  customWidth,
  customJustifyContent,
  handlePaginationChange,
  pagination,
  setPagination,
  companyId,
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(dataSource);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordCopy, setRecordCopy] = useState();
  const [modalType, setModalType] = useState();
  const [inputContent, setInputContent] = useState('');
  const [filteredColumnsStatus, setFilteredColumnsStatus] = useState({});
  const [searchInputs, setSearchInputs] = useState({});

  const user = useContext(UserContext);
  let searchInput;

  useEffect(() => {
    if (dataSource && (!searchInputs || !searchInput)) {
      setData(dataSource);
    }
  }, [dataSource, searchInputs]);

  useEffect(() => {
    if (searchInputs.length || searchInput) {
      if (data && data.length && data.length && data.length !== pagination.pageSize) {
        setPagination({
          pageSize: pagination.pageSize,
          current: pagination.current,
          total: data.length,
        });
      }
    }
  }, [data.length, pagination.total, searchInputs, searchInput]);

  const handleChange = (e) => {
    setInputContent(e.target.value);
  };

  const checkStatus = async (id, pagination, type) => {
    let status;
    if (type === 'purchase') {
      status = await purchaseHandler(id, pagination);
    }

    if (type === 'sales') {
      status = await salesHandler(id, pagination);
    }

    if (status) {
      const updatedData = data.map((item) => (item._id === id ? { ...item, status: status } : item));
      setData(updatedData);

      notification.success({
        message: 'Status checked!',
        placement: 'bottomRight',
      });
    }
  };

  const handleOk = () => {
    if (modalType === 'storn') {
      deleteHandler(recordCopy._id, recordCopy.status, form.getFieldsValue());
    } else if (modalType === 'accept') {
      acceptHandler(recordCopy._id, form.getFieldsValue());
    } else if (modalType === 'reject') {
      rejectHandler(recordCopy._id, form.getFieldsValue());
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      const modalContainer = document.querySelector('.ant-modal');
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          // Trigger the OK button when Enter is pressed
          handleOk();
        }
      };
      modalContainer.addEventListener('keydown', handleKeyDown);

      // Clean up the event listener when the modal is closed
      return () => {
        modalContainer.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isModalOpen]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchInputs({ ...searchInputs, [dataIndex]: selectedKeys[0] || '' });
    // search across all pages not only current page
    if (selectedKeys && selectedKeys.length) {
      // Filter the entire dataForSearch based on selectedKeys and dataIndex
      const newData = dataForSearch.filter((record) => {
        const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
        return filterRecord && filterRecord.toString().toLowerCase().includes(selectedKeys[0].toLowerCase());
      });
      setData(newData);
      // Update filteredColumnsStatus for the specific column
      setFilteredColumnsStatus({
        ...filteredColumnsStatus,
        [dataIndex]: true,
      });
      // Update pagination's total count based on the filtered data
      handlePageChange({
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: newData.length,
      });
    } else {
      // reset on initial data set for table with normal pagination
      // when empty string is in search bar of the column
      setData(dataSource);
      setFilteredColumnsStatus({
        ...filteredColumnsStatus,
        [dataIndex]: false,
      });
      setFilteredColumnsStatus({});
      setSearchInputs({});
      handlePageChange({
        current: 1,
        pageSize: pagination.pageSize,
      });
    }
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setData(dataSource);
    setSearchInputs({});
  };

  const getRowClassName = (record, index) => {
    if (record.status === 'Seen' || record.status === '' || !record.status) {
      return 'purchase_invoice_row_seen';
    }
  };

  // define order of columns
  const desiredColumnOrder = [
    'documentNumber',
    'purchaseInvoiceId',
    'deliveryDate',
    'paymentDate',
    'supplierName',
    'status',
  ];

  const desiredIncomeColumnOrder = [
    'documentNumber',
    'salesInvoiceId',
    'supplyDate',
    'total',
    'customerName',
    'status',
  ];

  let columns = [];
  columns = (type === 'outgoing' ? desiredIncomeColumnOrder : desiredColumnOrder).map((columnKey) => {
    const item = columnKeys?.find((item) => item.originalName === columnKey);
    if (item) {
      return {
        key: item.originalName,
        title: item?.alternativeName?.toUpperCase(),
        dataIndex: ['title', 'category'].includes(item.originalName)
          ? [item.originalName, user.language ? user.language.selected.code : '']
          : item.originalName,
        ...getColumnSearchProps(
          ['title', 'category'].includes(item.originalName)
            ? [item.originalName, user.language ? user.language.selected.code : '']
            : item.originalName,
        ),
      };
    }
    return null;
  });

  columns?.push({
    title: 'Options',
    width: customWidth,
    render: (text, record) => {
      return (
        <>
          {record.type === 'purchase' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: customJustifyContent,
                padding: '0 10px',
                alignItems: 'center',
                fontSize: '15px',
                width: '100%',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {(record.status !== '' || record.status !== 'Not seen') && (
                  <div style={{ padding: '4px', cursor: 'pointer', paddingTop: '16px', paddingRight: '15px' }}>
                    <Link to={`/admin/${record.type}-invoice-detail/${record._id}`}>
                      <EyeOutlined title={`View invoice`} style={{ textDecoration: 'none', color: 'black' }} />
                    </Link>
                  </div>
                )}
                {(record.type === 'purchase' && record.status === 'Seen') ||
                (record.type === 'purchase' && record.status !== 'Seen') ? (
                  <div style={{ padding: '4px', cursor: 'pointer' }}>
                    <Button
                      type='primary'
                      onClick={async () => {
                        await checkStatus(record._id, pagination, record.type);
                      }}
                      size='small'
                      style={{ fontSize: '10px' }}
                    >
                      Update <br />
                      status
                    </Button>
                  </div>
                ) : (
                  <div style={{ padding: '4px', cursor: 'pointer', visibility: 'hidden' }}>
                    <Button
                      type='primary'
                      onClick={async () => {
                        await checkStatus(record._id, pagination, record.type);
                      }}
                      size='small'
                      style={{ fontSize: '10px' }}
                    >
                      Update <br />
                      status
                    </Button>
                  </div>
                )}
                {record.type === 'sales' && record.status !== 'Seen' && !record.isForeignInvoice ? (
                  <>
                    <div style={{ padding: '4px', cursor: 'pointer', marginLeft: '-80px' }}>
                      <Button
                        type='primary'
                        onClick={async () => {
                          await checkStatus(record._id, pagination, record.type);
                        }}
                        size='small'
                        style={{ fontSize: '10px' }}
                      >
                        Update <br /> status
                      </Button>
                    </div>
                    {/* this is only for visual appearance */}
                    <div style={{ padding: '4px', cursor: 'pointer', visibility: 'hidden' }}>
                      <Button
                        type='primary'
                        onClick={async () => {
                          await checkStatus(record._id, pagination, record.type);
                        }}
                        size='small'
                        style={{ fontSize: '10px' }}
                      >
                        Update <br /> status
                      </Button>
                    </div>
                  </>
                ) : (
                  <div style={{ padding: '4px', cursor: 'pointer', visibility: 'hidden' }}>
                    <Button
                      type='primary'
                      onClick={async () => {
                        await checkStatus(record._id, pagination, record.type);
                      }}
                      size='small'
                      style={{ fontSize: '10px' }}
                    >
                      Update <br /> status
                    </Button>
                  </div>
                )}
                {record.type === 'sales' && record.isForeignInvoice && (
                  // this is only because icons could have uniform style
                  <div style={{ visibility: 'hidden', margin: '0 0px', padding: '4px', cursor: 'pointer' }}>
                    <Button type='primary' size='small' style={{ fontSize: '10px' }}>
                      Update <br /> status
                    </Button>
                  </div>
                )}
                {record.status === 'Draft' ? (
                  <div style={{ margin: '2px', padding: '4px' }}>
                    <Popconfirm
                      disabled={record._id === user.data.id}
                      placement='left'
                      title={`This will delete the invoice`}
                      onConfirm={() => deleteHandler(record._id, record.status, '')}
                      okText='Ok'
                      cancelText='Cancel'
                    >
                      <DeleteOutlined title={`Delete invoice`} />
                    </Popconfirm>
                  </div>
                ) : record.status === 'Sent' ? (
                  <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
                    <DeleteOutlined
                      title={`Storn invoice`}
                      onClick={() => {
                        setIsModalOpen(true);
                        setRecordCopy(record);
                        setModalType('storn');
                      }}
                    />
                  </div>
                ) : !record.status && record.type === 'sales' ? (
                  // this is only because so icons can have uniform style
                  <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
                    <DeleteOutlined style={{ visibility: 'hidden' }} />
                  </div>
                ) : (
                  ''
                )}
                {(record.type === 'purchase' && (record.status === 'Seen' || record.status === 'Rejected')) ||
                record.status === 'ReNotified' ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: '-60px',
                      marginRight: '-20px',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ marginRight: '5px', padding: '4px', cursor: 'pointer', paddingTop: '16px' }}>
                      <CheckOutlined
                        title='Accept invoice'
                        onClick={() => {
                          setIsModalOpen(true);
                          setRecordCopy(record);
                          setModalType('accept');
                        }}
                      />
                    </div>
                    {record.status !== 'Rejected' ? (
                      <div style={{ padding: '4px', cursor: 'pointer', paddingTop: '16px' }}>
                        <CloseOutlined
                          title='Reject invoice'
                          onClick={() => {
                            setIsModalOpen(true);
                            setRecordCopy(record);
                            setModalType('reject');
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{ padding: '4px', cursor: 'pointer', paddingTop: '16px', opacity: '0' }}>
                        <CloseOutlined
                          title='Reject invoice'
                          onClick={() => {
                            setIsModalOpen(true);
                            setRecordCopy(record);
                            setModalType('reject');
                          }}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: '-60px',
                      visibility: 'hidden',
                      marginRight: '-20px',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ marginRight: '5px', padding: '4px', cursor: 'pointer', paddingTop: '16px' }}>
                      <CheckOutlined
                        title='Accept invoice'
                        onClick={() => {
                          setIsModalOpen(true);
                          setRecordCopy(record);
                          setModalType('accept');
                        }}
                      />
                    </div>
                    <div style={{ padding: '4px', cursor: 'pointer', visibility: 'hidden', paddingTop: '16px' }}>
                      <CloseOutlined
                        title='Reject invoice'
                        onClick={() => {
                          setIsModalOpen(true);
                          setRecordCopy(record);
                          setModalType('reject');
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div style={{ marginLeft: '40px', padding: '4px', cursor: 'pointer', paddingTop: '16px' }}>
                <Popconfirm
                  disabled={record._id === user.data.id}
                  placement='left'
                  title={`This will archive the invoice`}
                  onConfirm={() => archiveHandler(record._id)}
                  okText='Ok'
                  cancelText='Cancel'
                >
                  <FolderAddOutlined title='Archive invoice' />
                </Popconfirm>
              </div>
            </div>
          )}
          {record.type === 'sales' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: customJustifyContent,
                padding: '0 10px',
                alignItems: 'center',
                fontSize: '15px',
                width: '100%',
                minWidth: customWidth + 'px',
                maxWidth: customWidth + 'px',
              }}
            >
              <div style={{ padding: '4px', cursor: 'pointer', paddingTop: '16px', paddingRight: '27px' }}>
                <Link to={`/admin/${record.type}-invoice-detail/${record._id}`}>
                  <EyeOutlined title={`View invoice`} style={{ textDecoration: 'none', color: 'black' }} />
                </Link>
              </div>
              {record.type === 'sales' && !record.isForeignInvoice ? (
                <>
                  <div style={{ padding: '4px', cursor: 'pointer', marginLeft: '-20px', marginRight: '5px' }}>
                    <Button
                      type='primary'
                      onClick={async () => {
                        await checkStatus(record._id, pagination, record.type);
                      }}
                      size='small'
                      style={{ fontSize: '10px' }}
                    >
                      Update <br /> status
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      padding: '4px',
                      cursor: 'pointer',
                      marginLeft: '-20px',
                      marginRight: '5px',
                      visibility: 'hidden',
                    }}
                  >
                    <Button
                      type='primary'
                      onClick={async () => {
                        await checkStatus(record._id, pagination, record.type);
                      }}
                      size='small'
                      style={{ fontSize: '10px' }}
                    >
                      Update <br /> status
                    </Button>
                  </div>
                </>
              )}
              {record.status === 'Draft' ? (
                <div style={{ margin: '2px', padding: '4px', paddingTop: '16px' }}>
                  <Popconfirm
                    disabled={record._id === user.data.id}
                    placement='left'
                    title={`This will delete the invoice`}
                    onConfirm={() => deleteHandler(record._id, record.status, '')}
                    okText='Ok'
                    cancelText='Cancel'
                  >
                    <DeleteOutlined title={`Delete invoice`} />
                  </Popconfirm>
                </div>
              ) : record.status === 'Sent' ? (
                <div style={{ margin: '2px', padding: '4px', cursor: 'pointer', paddingTop: '16px' }}>
                  <DeleteOutlined
                    title={`Storn invoice`}
                    onClick={() => {
                      setIsModalOpen(true);
                      setRecordCopy(record);
                      setModalType('storn');
                    }}
                  />
                </div>
              ) : !record.status && record.type === 'sales' ? (
                // this is only because so icons can have uniform style
                <div style={{ margin: '2px', padding: '4px', cursor: 'pointer', paddingTop: '16px' }}>
                  <DeleteOutlined style={{ visibility: 'hidden' }} />
                </div>
              ) : (
                <div style={{ margin: '2px', padding: '4px', cursor: 'pointer', paddingTop: '16px' }}>
                  <DeleteOutlined style={{ visibility: 'hidden' }} />
                </div>
              )}
              <div
                style={{
                  marginLeft: '3px',
                  padding: '4px',
                  cursor: 'pointer',
                  paddingTop: '16px',
                  marginRight: '-120px',
                }}
              >
                <Popconfirm
                  disabled={record._id === user.data.id}
                  placement='left'
                  title={`This will archive the invoice`}
                  onConfirm={() => archiveHandler(record._id)}
                  okText='Ok'
                  cancelText='Cancel'
                >
                  <FolderAddOutlined title='Archive invoice' />
                </Popconfirm>
              </div>
            </div>
          )}
        </>
      );
    },
  });

  const handlePageChange = (current) => {
    setPagination(current);
    if (type === 'outgoing') {
      handlePaginationChange(companyId, current);
    } else {
      handlePaginationChange(current);
    }
  };

  const filteredColumns = columns.filter((column) => column !== null);

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={filteredColumns}
        rowClassName={(record, index) => getRowClassName(record, index)}
        rowKey='_id'
        pagination={{
          ...pagination,
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
          current: pagination?.current,
          pageSize: pagination?.pageSize,
          total: pagination?.total,
          onChange: (page, pageSize) => handlePageChange({ current: page, pageSize, total: pagination?.total }),
        }}
      />
      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: 20 }}
        okText='OK'
        cancelText='Cancel'
        okButtonProps={modalType === 'reject' || modalType === 'storn' ? { disabled: !inputContent.length } : {}}
      >
        <h2>
          {modalType === 'accept' ? (
            'Please confirm Invoice Status update'
          ) : (
            <>
              Please enter the reason why you want to <br /> {modalType} the invoice.
            </>
          )}
        </h2>
        <Form form={form} initialValues={initialValues}>
          {(modalType === 'reject' || modalType === 'storn') && (
            <Form.Item name='comment'>
              <Input onChange={handleChange} />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default InvoicesTable;
