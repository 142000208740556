import React from 'react'
import { TOTAL_STEPS } from '../../../shared/steps-data';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export default function StepControl({ currentStep, onChangeStep }) {
  const totalSteps = TOTAL_STEPS;

  const handlePrev = () => {
    if (currentStep === 1) {
      return onChangeStep(totalSteps);
    }
    onChangeStep(currentStep - 1);
  }

  const handleNext = () => {
    if (totalSteps === currentStep) {
      return onChangeStep(1);
    }
    onChangeStep(currentStep + 1);
  }

  return (
    <div className='step-control'>
      <button onClick={handlePrev} type="button">
        <LeftOutlined />
        <span>Prev</span>  
      </button>
      <button onClick={handleNext} type="button">
        <span>Next</span>
        <RightOutlined />
      </button>
    </div>
  )
}
