import React from 'react';

export default function AllProductsIcon(props) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 97 81'
            fill='#fff'
            fillRule='evenodd'
            stroke='#000'
            strokeLinecap='round'
            strokeLinejoin='round'
            width='22'
            height='22'
            {...props}
        >
            <use href='#A' x='.5' y='.5' />
            <symbol id='A' overflow='visible'>
                <g fill='#ba141a' stroke='none'>
                    <use href='#C' />
                    <path d='M92.391 48.864c3.475 1.424 3.475 3.731 0 5.152L53.791 69.819c-3.475 1.424-9.11 1.424-12.587 0L2.607 54.016c-3.475-1.424-3.475-3.731 0-5.152l38.599-15.803c3.475-1.424 9.11-1.424 12.587 0l38.597 15.803z' />
                    <use href='#D' />
                    <path d='M92.391 32.868c3.475 1.424 3.475 3.73 0 5.152L53.791 53.823c-3.475 1.424-9.11 1.424-12.587 0L2.607 38.02c-3.475-1.424-3.475-3.731 0-5.152l38.599-15.803c3.475-1.424 9.11-1.424 12.587 0l38.597 15.803z' />
                    <use href='#E' />
                </g>
                <g fill='#1e1e1e'>
                    <use href='#C' opacity='.25' stroke='none' />
                    <use href='#D' opacity='.25' stroke='none' />
                    <use href='#E' opacity='.25' stroke='none' />
                </g>
                <path
                    d='M92.391 16.871c3.475 1.424 3.475 3.73 0 5.153L53.791 37.826c-3.475 1.424-9.11 1.424-12.587 0L2.607 22.023c-3.475-1.424-3.475-3.73 0-5.152L41.206 1.068c3.475-1.424 9.11-1.424 12.587 0l38.597 15.803z'
                    fill='#ba141a'
                    stroke='none'
                />
                <path
                    d='M35.605 22.426c-4.406 1.957-11.548 1.957-15.952 0s-4.406-5.132 0-7.091 11.548-1.957 15.952 0 4.406 5.134 0 7.091zm-2.988 5.042l22.605-2.692-6.057 10.047z'
                    stroke='none'
                />
                <g fill='#1e1e1e'>
                    <path d='M68.037 24.943l-13.518-6.009 13.518-6.009 13.52 6.009z' opacity='.25' stroke='none' />
                    <path d='M68.037 24.943l-13.518-6.009 13.518-6.009z' opacity='.25' stroke='none' />
                </g>
                <path
                    d='M43.161 11.591l-8.825-.797 7.839-1.971-1.02-3.979 6.64 2.705 8.196-1.659-3.735 3.641 6.085 2.952-8.949-.454-4.436 3.486z'
                    stroke='none'
                />
            </symbol>
            <defs>
                <path
                    id='C'
                    d='M.019 60.314c-.161 1.01.701 2.043 2.588 2.816l38.599 15.803c3.475 1.424 9.11 1.424 12.587 0l38.599-15.803c1.886-.773 2.749-1.806 2.588-2.815l.01-9.052c.118.991-.749 1.997-2.597 2.755L53.793 69.819c-3.475 1.424-9.11 1.424-12.587 0L2.607 54.016C.578 53.185-.268 52.053.074 50.971l-.055 9.343z'
                />
                <path
                    id='D'
                    d='M.019 44.317c-.161 1.01.701 2.043 2.588 2.816l38.599 15.803c3.475 1.424 9.11 1.424 12.587 0l38.599-15.803c1.886-.773 2.749-1.806 2.588-2.816l.01-9.052c.118.991-.749 1.997-2.597 2.755L53.793 53.823c-3.475 1.424-9.11 1.424-12.587 0L2.607 38.02C.578 37.189-.268 36.057.074 34.975l-.055 9.342z'
                />
                <path
                    id='E'
                    d='M.019 28.321c-.161 1.01.701 2.043 2.588 2.815l38.599 15.803c3.475 1.424 9.11 1.424 12.587 0l38.599-15.803c1.886-.773 2.749-1.805 2.588-2.815l.01-9.052c.118.991-.749 1.997-2.597 2.755L53.793 37.826c-3.475 1.424-9.11 1.424-12.587 0L2.607 22.023C.578 21.192-.268 20.06.074 18.978l-.055 9.343z'
                />
            </defs>
        </svg>
    );
}
