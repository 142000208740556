import React, { useCallback, useContext } from 'react';
import { Table, Popconfirm, Input, Button, notification, Spin, Tooltip } from 'antd';
import {
  CloseOutlined,
  CheckOutlined,
  EyeOutlined,
  SearchOutlined,
  VerticalAlignBottomOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../../config';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { useEffect, useState } from 'react';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { formatDateForTables } from '../../helpers/dates';
import CancelTerminationModal from '../forms/modals/CancelTerminationModal';
import {
  acceptOfficialTravelById,
  deleteOfficialTravelById,
  denyOfficialTravelById
} from '../../services/official-travel.js';

const HREmployeeTable = ({ employee, employeeId, data, typeOfHr, refetchData }) => {
  const user = useContext(UserContext);
  const history = useHistory();
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employeeCompany, setEmployeeCompany] = useState();
  let searchInput;

  const columnKeys = ['typeOfLeave', 'status', 'createdAt'];

  const getEmployeeCompany = useCallback(async () => {
    try {
      if (requests.length) {
        const company = await Axios.get(`${SERVER_URL}/companies/${requests[0].companyID}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.data.token}` },
        });

        if (company.data._id && company.status === 200) {
          setEmployeeCompany(company.data);
        } else {
          throw new Error('Error fetching company');
        }
      }
    } catch (err) {
      console.error(err.message);
    }
  }, [requests, user.data.token]);

  const getEmployeesRequests =  useCallback(async() => {
    try {
      const response = await Axios.get(`${SERVER_URL}/employee-requests/${employeeId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.data.token}` },
      });
      setRequests(response.data);
      return response.data;
    } catch (error) {
      console.error(error.message);
    }
  }, [employeeId, user.data.token]);

  useEffect(() => {
    if (requests.length) {
      getEmployeeCompany();
    }
  }, [requests, refetchData, getEmployeeCompany]);

  useEffect(() => {
    getEmployeesRequests();
  }, [refetchData, getEmployeesRequests]);

  const deleteRequestHandler = async (id, status) => {
    try {
      await Axios.delete(`${SERVER_URL}/request/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.data.token}` },
      });
      notification.success({
        message: status !== 'accepted' ? 'The request has been deleted.' : 'The request has been withdrawn.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const deleteOfficialTravelRequestHandler = async (id, status) => {
    try {
      await deleteOfficialTravelById(user.data.token, id);
      notification.success({
        message: 'The request has been deleted.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      console.error('error in delete official travel', err);
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const acceptOfficialTravelRequest = async (record) => {
    try {
      setLoading(true);
      await acceptOfficialTravelById(user.data.token, record._id);

      notification.success({
        message: 'The official travel order has been accepted.',
        placement: 'bottomRight',
      });
      
      setTimeout(() => {
        history.go(0);
      }, 1800);
    } catch (error) {
      console.error('error in accept official travel', error);
      notification.error({
        message: `Problem with accepting travel order. ${error.message}`,
        placement: 'bottomRight',
        duration: 0,
      });
    } finally {
      setLoading(false);
    }
  }

  const acceptRequest = async (record) => {
    setLoading(true);
    try {
      if (user?.data?.company?.typeOfHr === 'serbian' || !user?.data?.company?.typeOfHr) {
        if (record.typeOfLeave === 'Annual leave') {
          notification.info({
            message: 'Calculating number of days left of annual leave...',
            placement: 'bottomRight',
          });
          user?.data?.company?.holidayDaysTracker &&
            (await Axios.put(
              `${SERVER_URL}/accepted`,
              {
                record,
              },
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            ));

          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });
          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Overtime') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Paid days off') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Unpaid days off') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Slava - Saint day') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Pregnancy leave') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Maternity leave') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Bonus') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Suspension') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        }

        setLoading(false);
        notification.success({
          message: 'The leave has been accepted.',
          placement: 'bottomRight',
        });
        setTimeout(() => {
          history.go(0);
        }, 1800);
      } else if (user?.data?.company?.typeOfHr === 'bilingual') {
        if (record.typeOfLeave === 'Annual leave') {
          notification.info({
            message: 'Calculating number of days left of annual leave...',
            placement: 'bottomRight',
          });
          user?.data?.company?.holidayDaysTracker &&
            (await Axios.put(
              `${SERVER_URL}/accepted`,
              {
                record,
              },
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            ));
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Overtime') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Paid days off') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Unpaid days off') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Slava - Saint day') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Pregnancy leave') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Maternity leave') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Bonus') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        } else if (record.typeOfLeave === 'Suspension') {
          notification.info({
            message: 'Creation of decision is in progress...',
            placement: 'bottomRight',
          });

          await Axios.post(
            `${SERVER_URL}/accepted`,
            {
              record,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
        }
        setLoading(false);
        notification.success({
          message: 'The leave has been accepted.',
          placement: 'bottomRight',
        });
        setTimeout(() => {
          history.go(0);
        }, 1800);
      }
    } catch (err) {
      setLoading(false);
      notification.error({
        message: 'Problem with accepting. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let tableData = [];
  
  if (requests && requests.length > 0) {
    tableData = requests.map((item, index) => {
      const formattedDate = formatDateForTables(item.createdAt);
      return { ...item, createdAt: formattedDate };
    });
  }

  const downloadRequest = async (record) => {
    try {
      if (typeOfHr === 'serbian' || !typeOfHr) {
        if (record.typeOfLeave === 'Annual leave') {
          if (
            employeeCompany.documentReturnType === 'word-pdf' ||
            employeeCompany.documentReturnType === 'word' ||
            !record.documentNamePdf
          ) {
            const requestGodisnji = await Axios.get(
              `${SERVER_URL}/download-go/${record.firstName}_${record.lastName}_${record.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            FileSaver.saveAs(
              requestGodisnji.config.url,
              `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Godišnji - ${record.firstName} ${
                record.lastName
              }.doc`,
            );
          } else if (employeeCompany.documentReturnType === 'pdf') {
            const requestAnnualLeavePdf = await Axios.get(
              `${SERVER_URL}/download-go/${record.firstName}_${record.lastName}_${record.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            if (
              employeeCompany.documentSeparateRequestResponse === 'separated' &&
              requestAnnualLeavePdf.headers['content-type'] === 'application/zip'
            ) {
              FileSaver.saveAs(
                requestAnnualLeavePdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Godišnji - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            } else if (
              employeeCompany.documentSeparateRequestResponse !== 'separated' &&
              requestAnnualLeavePdf.headers['content-type'] === 'application/pdf'
            ) {
              FileSaver.saveAs(
                requestAnnualLeavePdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Godišnji - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Overtime') {
          const requestOvertime = await Axios.get(
            `${SERVER_URL}/download-overtime/${record.firstName}_${record.lastName}_${record.employee}/${record.documentName}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
          FileSaver.saveAs(
            requestOvertime.config.url,
            `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Prekovremeni - ${record.firstName} ${
              record.lastName
            }.doc`,
          );
        } else if (record.typeOfLeave === 'Paid days off' || record.typeOfLeave === 'Paid leave') {
          if (
            employeeCompany.documentReturnType === 'word-pdf' ||
            employeeCompany.documentReturnType === 'word' ||
            !record.documentNamePdf
          ) {
            const requestPaid = await Axios.get(
              `${SERVER_URL}/download-paid/${record.firstName}_${record.lastName}_${record.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            FileSaver.saveAs(
              requestPaid.config.url,
              `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Plaćeno - ${record.firstName} ${
                record.lastName
              }.doc`,
            );
          } else if (employeeCompany.documentReturnType === 'pdf') {
            const requestPaid = await Axios.get(
              `${SERVER_URL}/download-paid/${record.firstName}_${record.lastName}_${record.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            if (
              employeeCompany.documentSeparateRequestResponse === 'separated' &&
              requestPaid.headers['content-type'] === 'application/zip'
            ) {
              FileSaver.saveAs(
                requestPaid.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Plaćeno - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            } else if (
              employeeCompany.documentSeparateRequestResponse !== 'separated' &&
              requestPaid.headers['content-type'] === 'application/pdf'
            ) {
              FileSaver.saveAs(
                requestPaid.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Plaćeno - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Unpaid days off') {
          if (
            employeeCompany.documentReturnType === 'word-pdf' ||
            employeeCompany.documentReturnType === 'word' ||
            !record.documentNamePdf
          ) {
            const requestUnpaid = await Axios.get(
              `${SERVER_URL}/download-unpaid/${record.firstName}_${record.lastName}_${record.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            FileSaver.saveAs(
              requestUnpaid.config.url,
              `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Neplaćeno - ${record.firstName} ${
                record.lastName
              }.doc`,
            );
          } else if (employeeCompany.documentReturnType === 'pdf') {
            const requestUnpaid = await Axios.get(
              `${SERVER_URL}/download-unpaid/${record.firstName}_${record.lastName}_${record.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            if (
              employeeCompany.documentSeparateRequestResponse === 'separated' &&
              requestUnpaid.headers['content-type'] === 'application/zip'
            ) {
              FileSaver.saveAs(
                requestUnpaid.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Neplaćeno - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            } else if (
              employeeCompany.documentSeparateRequestResponse !== 'separated' &&
              requestUnpaid.headers['content-type'] === 'application/pdf'
            ) {
              FileSaver.saveAs(
                requestUnpaid.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Neplaćeno - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Slava - Saint day') {
          if (
            employeeCompany.documentReturnType === 'word-pdf' ||
            employeeCompany.documentReturnType === 'word' ||
            !record.documentNamePdf
          ) {
            const requestHoliday = await Axios.get(
              `${SERVER_URL}/download-holiday/${record.firstName}_${record.lastName}_${record.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestHoliday.config.url,
              `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Slava - ${record.firstName} ${record.lastName}.doc`,
            );
          } else if (employeeCompany.documentReturnType === 'pdf') {
            const requestHoliday = await Axios.get(
              `${SERVER_URL}/download-holiday/${record.firstName}_${record.lastName}_${record.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            if (
              employeeCompany.documentSeparateRequestResponse === 'separated' &&
              requestHoliday.headers['content-type'] === 'application/zip'
            ) {
              FileSaver.saveAs(
                requestHoliday.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Slava - ${record.firstName} ${record.lastName}.zip`,
              );
            } else if (
              employeeCompany.documentSeparateRequestResponse !== 'separated' &&
              requestHoliday.headers['content-type'] === 'application/pdf'
            ) {
              FileSaver.saveAs(
                requestHoliday.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Slava - ${record.firstName} ${record.lastName}.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Pregnancy leave') {
          if (
            employeeCompany.documentReturnType === 'word-pdf' ||
            employeeCompany.documentReturnType === 'word' ||
            !record.documentNamePdf
          ) {
            const requestPregnancy = await Axios.get(
              `${SERVER_URL}/download-pregnancy/${record.firstName}_${record.lastName}_${record.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestPregnancy.config.url,
              `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Trudničko - ${record.firstName} ${
                record.lastName
              }.doc`,
            );
          } else if (employeeCompany.documentReturnType === 'pdf') {
            const requestPregnancy = await Axios.get(
              `${SERVER_URL}/download-pregnancy/${record.firstName}_${record.lastName}_${record.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            if (
              employeeCompany.documentSeparateRequestResponse === 'separated' &&
              requestPregnancy.headers['content-type'] === 'application/zip'
            ) {
              FileSaver.saveAs(
                requestPregnancy.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Trudničko - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            } else if (
              employeeCompany.documentSeparateRequestResponse !== 'separated' &&
              requestPregnancy.headers['content-type'] === 'application/pdf'
            ) {
              FileSaver.saveAs(
                requestPregnancy.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Trudničko - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Maternity leave') {
          if (
            employeeCompany.documentReturnType === 'word-pdf' ||
            employeeCompany.documentReturnType === 'word' ||
            !record.documentNamePdf
          ) {
            const requestPregnancy = await Axios.get(
              `${SERVER_URL}/download-maternity/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestPregnancy.config.url,
              `${record.documentName} - Porodiljsko - ${record.firstName} ${record.lastName}.doc`,
            );
          } else if (employeeCompany.documentReturnType === 'pdf') {
            const requestPregnancyPdf = await Axios.get(
              `${SERVER_URL}/download-maternity/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            if (
              employeeCompany.documentSeparateRequestResponse === 'separated' &&
              requestPregnancyPdf.headers['content-type'] === 'application/zip'
            ) {
              FileSaver.saveAs(
                requestPregnancyPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Porodiljsko - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            } else if (
              employeeCompany.documentSeparateRequestResponse !== 'separated' &&
              requestPregnancyPdf.headers['content-type'] === 'application/pdf'
            ) {
              FileSaver.saveAs(
                requestPregnancyPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Porodiljsko - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Bonus') {
          if (
            employeeCompany.documentReturnType === 'word-pdf' ||
            employeeCompany.documentReturnType === 'word' ||
            !record.documentNamePdf
          ) {
            const requestBonus = await Axios.get(
              `${SERVER_URL}/download-bonus/${record.firstName}_${record.lastName}_${record.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            FileSaver.saveAs(
              requestBonus.config.url,
              `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Bonus - ${record.firstName} ${record.lastName}.doc`,
            );
          } else if (employeeCompany.documentReturnType === 'pdf') {
            const requestBonusPDF = await Axios.get(
              `${SERVER_URL}/download-bonus/${record.firstName}_${record.lastName}_${record.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestBonusPDF.config.url,
              `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Bonus - ${record.firstName} ${record.lastName}.pdf`,
            );
          }
        } else if (record.typeOfLeave === 'Suspension') {
          if (
            employeeCompany.documentReturnType === 'word-pdf' ||
            employeeCompany.documentReturnType === 'word' ||
            !record.documentNamePdf
          ) {
            const requestBonus = await Axios.get(
              `${SERVER_URL}/download-payment-suspension/${record.firstName}_${record.lastName}_${record.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            FileSaver.saveAs(
              requestBonus.config.url,
              `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')}-Obustava-isplate-${record.firstName}-${record.lastName}.doc`,
            );
          } else if (employeeCompany.documentReturnType === 'pdf') {
            const requestBonusPDF = await Axios.get(
              `${SERVER_URL}/download-payment-suspension/${record.firstName}_${record.lastName}_${record.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestBonusPDF.config.url,
              `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')}-Obustava-isplate-${record.firstName} ${record.lastName}.pdf`,
            );
          }
        }
      } else if (typeOfHr === 'bilingual') {
        if (record.typeOfLeave === 'Annual leave') {
          if (
            employeeCompany.documentReturnType === 'word-pdf' ||
            employeeCompany.documentReturnType === 'word' ||
            !record.documentNamePdf
          ) {
            const requestAnnualLeave = await Axios.get(
              `${SERVER_URL}/download-annual/${record.firstName}_${record.lastName}_${record.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            FileSaver.saveAs(
              requestAnnualLeave.config.url,
              `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Annual-leave - ${record.firstName} ${
                record.lastName
              }.doc`,
            );
          } else if (employeeCompany.documentReturnType === 'pdf') {
            const requestAnnualLeave = await Axios.get(
              `${SERVER_URL}/download-annual/${record.firstName}_${record.lastName}_${record.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            if (
              employeeCompany.documentSeparateRequestResponse === 'separated' &&
              requestAnnualLeave.headers['content-type'] === 'application/zip'
            ) {
              FileSaver.saveAs(
                requestAnnualLeave.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Annual-leave - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            } else if (
              employeeCompany.documentSeparateRequestResponse !== 'separated' &&
              requestAnnualLeave.headers['content-type'] === 'application/pdf'
            ) {
              FileSaver.saveAs(
                requestAnnualLeave.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Annual-leave - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Overtime') {
          const requestOvertime = await Axios.get(
            `${SERVER_URL}/download-overtime-bil/${record.firstName}_${record.lastName}_${record.employee}/${record.documentName}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
          FileSaver.saveAs(
            requestOvertime.config.url,
            `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Overtime - ${record.firstName} ${
              record.lastName
            }.doc`,
          );
        } else if (record.typeOfLeave === 'Paid days off' || record.typeOfLeave === 'Paid leave') {
          if (
            employeeCompany.documentReturnType === 'word-pdf' ||
            employeeCompany.documentReturnType === 'word' ||
            !record.documentNamePdf
          ) {
            const requestPaid = await Axios.get(
              `${SERVER_URL}/download-paid-bil/${record.firstName}_${record.lastName}_${record.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestPaid.config.url,
              `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Paid-leave - ${record.firstName} ${
                record.lastName
              }.doc`,
            );
          } else if (employeeCompany.documentReturnType === 'pdf') {
            const requestPaid = await Axios.get(
              `${SERVER_URL}/download-paid-bil/${record.firstName}_${record.lastName}_${record.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            if (
              employeeCompany.documentSeparateRequestResponse === 'separated' &&
              requestPaid.headers['content-type'] === 'application/zip'
            ) {
              FileSaver.saveAs(
                requestPaid.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Paid-leave - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            } else if (
              employeeCompany.documentSeparateRequestResponse !== 'separated' &&
              requestPaid.headers['content-type'] === 'application/pdf'
            ) {
              FileSaver.saveAs(
                requestPaid.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Paid-leave - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Unpaid days off') {
          if (
            employeeCompany.documentReturnType === 'word-pdf' ||
            employeeCompany.documentReturnType === 'word' ||
            !record.documentNamePdf
          ) {
            const requestUnpaid = await Axios.get(
              `${SERVER_URL}/download-unpaid-bil/${record.firstName}_${record.lastName}_${record.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestUnpaid.config.url,
              `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Unpaid-leave - ${record.firstName} ${
                record.lastName
              }.doc`,
            );
          } else if (employeeCompany.documentReturnType === 'pdf') {
            const requestUnpaid = await Axios.get(
              `${SERVER_URL}/download-unpaid-bil/${record.firstName}_${record.lastName}_${record.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            if (
              employeeCompany.documentSeparateRequestResponse === 'separated' &&
              requestUnpaid.headers['content-type'] === 'application/zip'
            ) {
              FileSaver.saveAs(
                requestUnpaid.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Unpaid-leave - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            }

            if (
              employeeCompany.documentSeparateRequestResponse !== 'separated' &&
              requestUnpaid.headers['content-type'] === 'application/pdf'
            ) {
              FileSaver.saveAs(
                requestUnpaid.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Unpaid-leave - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Slava - Saint day') {
          if (
            employeeCompany.documentReturnType === 'word-pdf' ||
            employeeCompany.documentReturnType === 'word' ||
            !record.documentNamePdf
          ) {
            const requestHoliday = await Axios.get(
              `${SERVER_URL}/download-holiday-bil/${record.firstName}_${record.lastName}_${record.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestHoliday.config.url,
              `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Saint day - slava - ${record.firstName} ${
                record.lastName
              }.doc`,
            );
          } else if (employeeCompany.documentReturnType === 'pdf') {
            const requestHoliday = await Axios.get(
              `${SERVER_URL}/download-holiday-bil/${record.firstName}_${record.lastName}_${record.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            if (
              employeeCompany.documentSeparateRequestResponse === 'separated' &&
              requestHoliday.headers['content-type'] === 'application/zip'
            ) {
              FileSaver.saveAs(
                requestHoliday.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Saint day - slava - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            } else if (
              employeeCompany.documentSeparateRequestResponse !== 'separated' &&
              requestHoliday.headers['content-type'] === 'application/pdf'
            ) {
              FileSaver.saveAs(
                requestHoliday.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Saint day - slava - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Pregnancy leave') {
          if (
            employeeCompany.documentReturnType === 'word-pdf' ||
            employeeCompany.documentReturnType === 'word' ||
            !record.documentNamePdf
          ) {
            const requestPregnancy = await Axios.get(
              `${SERVER_URL}/download-pregnancy-bil/${record.firstName}_${record.lastName}_${record.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestPregnancy.config.url,
              `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Pregnancy - ${record.firstName} ${
                record.lastName
              }.doc`,
            );
          } else if (employeeCompany.documentReturnType === 'pdf') {
            const requestPregnancy = await Axios.get(
              `${SERVER_URL}/download-pregnancy-bil/${record.firstName}_${record.lastName}_${record.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            if (
              employeeCompany.documentSeparateRequestResponse === 'separated' &&
              requestPregnancy.headers['content-type'] === 'application/zip'
            ) {
              FileSaver.saveAs(
                requestPregnancy.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Pregnancy - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            } else if (
              employeeCompany.documentSeparateRequestResponse !== 'separated' &&
              requestPregnancy.headers['content-type'] === 'application/pdf'
            ) {
              FileSaver.saveAs(
                requestPregnancy.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Pregnancy - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Maternity leave') {
          if (
            employeeCompany.documentReturnType === 'word-pdf' ||
            employeeCompany.documentReturnType === 'word' ||
            !record.documentNamePdf
          ) {
            const requestPregnancy = await Axios.get(
              `${SERVER_URL}/download-maternity-bil/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestPregnancy.config.url,
              `${record.documentName} - Maternity - leave - ${record.firstName} ${record.lastName}.doc`,
            );
          } else if (employeeCompany.documentReturnType === 'pdf') {
            const requestPregnancyPdf = await Axios.get(
              `${SERVER_URL}/download-maternity-bil/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentNamePdf}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );

            if (
              employeeCompany.documentSeparateRequestResponse === 'separated' &&
              requestPregnancyPdf.headers['content-type'] === 'application/zip'
            ) {
              FileSaver.saveAs(
                requestPregnancyPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Maternity - ${record.firstName} ${
                  record.lastName
                }.zip`,
              );
            } else if (
              employeeCompany.documentSeparateRequestResponse !== 'separated' &&
              requestPregnancyPdf.headers['content-type'] === 'application/pdf'
            ) {
              FileSaver.saveAs(
                requestPregnancyPdf.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Maternity - ${record.firstName} ${
                  record.lastName
                }.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Bonus') {
          if (
            employeeCompany.documentReturnType === 'word-pdf' ||
            employeeCompany.documentReturnType === 'word' ||
            !record.documentNamePdf
          ) {
            const requestBonus = await Axios.get(
              `${SERVER_URL}/download-bonus-bil/${record.firstName}_${record.lastName}_${record.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestBonus.config.url,
              `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Bonus - ${record.firstName} ${record.lastName}.doc`,
            );
          }
          if (employeeCompany.documentReturnType === 'pdf') {
            if (record.documentNamePdf) {
              const requestBonus = await Axios.get(
                `${SERVER_URL}/download-bonus-bil/${record.firstName}_${record.lastName}_${record.employee}/${record.documentNamePdf}`,
                {
                  withCredentials: false,
                  headers: { Authorization: `Bearer ${user.data.token}` },
                },
              );
              FileSaver.saveAs(
                requestBonus.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')} - Bonus - ${record.firstName} ${record.lastName}.pdf`,
              );
            }
          }
        } else if (record.typeOfLeave === 'Suspension') {
          if (
            employeeCompany.documentReturnType === 'word-pdf' ||
            employeeCompany.documentReturnType === 'word' ||
            !record.documentNamePdf
          ) {
            const requestBonus = await Axios.get(
              `${SERVER_URL}/download-payment-suspension-bil/${record.firstName}_${record.lastName}_${record.employee}/${record.documentName}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${user.data.token}` },
              },
            );
            FileSaver.saveAs(
              requestBonus.config.url,
              `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')}-Payment-suspension-${record.firstName}-${record.lastName}.doc`,
            );
          }
          if (employeeCompany.documentReturnType === 'pdf') {
            if (record.documentNamePdf) {
              const requestBonus = await Axios.get(
                `${SERVER_URL}/download-payment-suspension-bil/${record.firstName}_${record.lastName}_${record.employee}/${record.documentNamePdf}`,
                {
                  withCredentials: false,
                  headers: { Authorization: `Bearer ${user.data.token}` },
                },
              );
              FileSaver.saveAs(
                requestBonus.config.url,
                `${moment(record.dateAccepted, 'DD/MM/YYYY').format('DD/MM/YYYY')}-Payment-suspension-${record.firstName}-${record.lastName}.pdf`,
              );
            }
          }
        }
      }
    } catch (err) {
      notification.error({
        message: 'Problem with download. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const downloadTermination = async (record) => {
    try {
      if (typeOfHr === 'serbian' || !typeOfHr) {
        if (
          employeeCompany.documentReturnType === 'word-pdf' ||
          employeeCompany.documentReturnType === 'word' ||
          !record.pdfDocumentName
        ) {
          const termination = await Axios.get(
            `${SERVER_URL}/download-termination/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
          FileSaver.saveAs(termination.config.url, record.documentName);
        } else if (employeeCompany.documentReturnType === 'pdf') {
          const terminationPdf = await Axios.get(
            `${SERVER_URL}/download-termination/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.pdfDocumentName}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );
          FileSaver.saveAs(terminationPdf.config.url, record.pdfDocumentName);
        }
      } else if (typeOfHr === 'bilingual') {
        if (
          employeeCompany.documentReturnType === 'word-pdf' ||
          employeeCompany.documentReturnType === 'word' ||
          !record.pdfDocumentName
        ) {
          const termination = await Axios.get(
            `${SERVER_URL}/download-termination/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentName}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );

          FileSaver.saveAs(termination.config.url, record.documentName);
        } else if (employeeCompany.documentReturnType === 'pdf') {
          const terminationPdf = await Axios.get(
            `${SERVER_URL}/download-termination/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.pdfDocumentName}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
          );

          FileSaver.saveAs(terminationPdf.config.url, record.pdfDocumentName);
        }
      }
    } catch (err) {
      notification.error({
        message: 'Problem with download. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const downloadOfficialTravelRequest = async (record) => {
    try {
      const downloadedDocument = await Axios.get(
          `${SERVER_URL}/download-official-travel/${record.firstName}_${record.lastName}_${record.employee ? record.employee : user.data.employee}/${record.documentNamePdf}`,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${user.data.token}` },
          },
      );
      FileSaver.saveAs(
          downloadedDocument.config.url,
          `${moment(record.travelOrder.dateOfSubmission, 'YYYY/MM/DD').format('DD/MM/YYYY')}-Službeni_put-${record.firstName} ${
              record.lastName
          }.pdf`,
      );
    } catch (error) {
      console.error('error download official travel document', error);
      notification.error({
        message: 'Problem with download. Please try later.',
        placement: 'bottomRight',
      });
    }
  }

  const downloadDocument = async(record) => {
    if (record.typeOfLeave === 'Official travel') await downloadOfficialTravelRequest(record);
    else if (record.typeOfLeave === 'Termination of employment') await downloadTermination(record);
    else if (record.typeOfLeave !== 'Official travel' && record.typeOfLeave !== 'Termination of employment') await downloadRequest(record);
  }

  const deleteTerminationHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/termination-salary-documents/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.data.token}` },
      });
      const response = await Axios.delete(`${SERVER_URL}/termination/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.data.token}` },
      });
      notification.success({
        message: 'The termination request has been deleted.',
        placement: 'bottomRight',
      });

      return response;
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const denyRequest = async (record) => {
    try {
      const request = await Axios.post(
        `${SERVER_URL}/denied`,
        {
          record,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.data.token}` },
        },
      );
      notification.success({
        message: 'The leave has been denied.',
        placement: 'bottomRight',
      });

      history.go(0);
    } catch (err) {
      notification.error({
        message: 'Problem with denying. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const denyOfficialTravelRequest = async (record) => {
    try {
      await denyOfficialTravelById(user.data.token, record._id);
      notification.success({
        message: 'The request has been denied.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      console.error('error in deny official travel', err);
      notification.error({
        message: 'Problem with denying. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const [isExtendEmploymentModalOpen, setIsExtendEmploymentModalOpen] = useState(false);
  const [terminationRequestId, setTerminationRequestId] = useState('');

  useEffect(() => {
    if (!isExtendEmploymentModalOpen) {
      setTerminationRequestId('');
    }
  }, [isExtendEmploymentModalOpen]);

  const handleCloseTerminationCancelationModal = () => {
    setTerminationRequestId('');
    setIsExtendEmploymentModalOpen(false);
  };

  const handleOpenTerminationCancelationModal = (requestId) => {
    setTerminationRequestId(requestId);
    setIsExtendEmploymentModalOpen(true);
  };

  const columns = columnKeys.map((item) => ({
    key: item,
    title:
      item === 'firstName'
        ? 'first name'
        : item === 'lastName'
          ? 'surname'
          : item === '_id'
            ? 'id'
            : item === 'typeOfLeave'
              ? 'type of document'
              : item === 'createdAt'
                ? 'created at'
                : item === 'status'
                  ? 'status of document'
                  : item,
    dataIndex:
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
          ? [item, user.language ? user.language.selected.code : '']
          : item,
    ...getColumnSearchProps(
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
          ? [item, user.language ? user.language.selected.code : '']
          : item,
    ),
    render: (text, record) => {
      let displayText = text || '';
      if (displayText && displayText.length > 30) {
        displayText = `${displayText.slice(0, 30)}...`;
      }

      return displayText.length > 30 ? (
        <Tooltip title={text}>
          <span>{displayText}</span>
        </Tooltip>
      ) : (
        <span>{displayText}</span>
      );
    },
  }));

  columns.push({
    title: 'Options',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <Link to={'/admin/view-request/' + record._id}>
            <EyeOutlined style={{ textDecoration: 'none', color: 'black' }} />
          </Link>
        </div>

        {record.status === 'in progress' &&
        (user.data.role.includes('SuperAdmin') ||
          user.data.role.includes('admin') ||
          user.data.role.includes('user') ||
          user.data.role.includes('userOfficer') ||
          user.data.role.includes('hrOfficer')) ? (
          <>
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <CheckOutlined onClick={() => record.typeOfLeave === 'Official travel' ? acceptOfficialTravelRequest(record) : acceptRequest(record)} title='Approve request' />
            </div>

            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <Popconfirm
                placement='left'
                title='This will deny request'
                onConfirm={() => {
                  record.typeOfLeave === 'Official travel' ? denyOfficialTravelRequest(record) : denyRequest(record);
                }}
                okText='Ok'
                cancelText='Cancel'
              >
                <CloseOutlined title='Deny request' />
              </Popconfirm>
            </div>
          </>
        ) : (
          ''
        )}

        {record.status === 'in progress' || record.typeOfLeave === 'Official travel' ? (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link to={`/admin/edit-request/${record._id}`}>
              <EditOutlined title='Edit request' style={{ textDecoration: 'none', color: 'black' }} />
            </Link>
          </div>
        ) : (
          ''
        )}

        {record.status === 'accepted' || moment(record.status, 'DD-MM-YYYY', true).isValid() ? (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <VerticalAlignBottomOutlined
              title='Download document'
              onClick={() =>
                downloadDocument(record)}
            />
          </div>
        ) : (
          ''
        )}
        {user.data.role.includes('SuperAdmin') ||
        user.data.role.includes('admin') ||
        user.data.role.includes('hrOfficer') ||
        user.data.role.includes('userOfficer') ||
        user.data.role.includes('user') ? (
          <div style={{ margin: '2px', padding: '4px' }}>
            {moment(record.status, 'DD-MM-YYYY', true).isValid() ? (
              <div style={{ cursor: 'pointer' }}>
                <DeleteOutlined
                  onClick={() => handleOpenTerminationCancelationModal(record._id)}
                  title='Delete termination request'
                />
              </div>
            ) : (
              <Popconfirm
                disabled={record._id === user.data.id}
                placement='left'
                title={record.status !== 'accepted' ? 'This will delete request' : 'This will withdraw approved leave'}
                onConfirm={() =>
                  record.typeOfLeave === 'Official travel'
                    ? deleteOfficialTravelRequestHandler(record._id, record.status)
                    : deleteRequestHandler(record._id, record.status)
                }
                okText='Ok'
                cancelText='Cancel'
              >
                <DeleteOutlined title={record.status !== 'accepted' ? 'Delete request' : 'Withdraw approved leave'} />
              </Popconfirm>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    ),
  });

  return (
    <Spin spinning={loading} size='large'>
      <div>
        <p>
          Employee: {data.firstName} {data.lastName}
        </p>
        <Table
          size='middle'
          bordered
          dataSource={tableData}
          columns={columns}
          rowKey='_id'
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
        />
      </div>
      {isExtendEmploymentModalOpen ? (
        <CancelTerminationModal
          terminationRequestId={terminationRequestId}
          open={isExtendEmploymentModalOpen}
          employee={employee}
          company={employeeCompany}
          deleteTerminationHandler={deleteTerminationHandler}
          handleClose={handleCloseTerminationCancelationModal}
        />
      ) : null}
    </Spin>
  );
};

export default HREmployeeTable;
