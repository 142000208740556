import { QuestionCircleOutlined } from '@ant-design/icons';
import { DatePicker, Drawer, Form, Input, Tooltip } from 'antd';
import { disabledDate } from '../../../helpers/dates';

export default function TimesheetPaySuspensionDrawer({
  selectedCompany,
  onEditSuspensionDrawerClose,
  isEditSuspensionDrawerOpen,
  dateOfCreation,
  editedReasonOfSuspension,
  editedReasonOfSuspensionEng,
  suspensionAmount,
  suspensionCurrency,
  setDateOfCreation,
  setEditedReasonOfSuspension,
  setEditedReasonOfSuspensionEng,
  setSuspensionAmount,
}) {
  return (
    <Drawer
      title='Edit Suspension'
      width={480}
      closable={false}
      onClose={onEditSuspensionDrawerClose}
      open={isEditSuspensionDrawerOpen}
    >
      <Form.Item
        label='Reason of payment suspension - Serbian'
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        labelAlign='left'
      >
        <Input
          placeholder='Molimo, unesite razlog obustava isplate'
          value={editedReasonOfSuspension}
          onChange={(e) => setEditedReasonOfSuspension(e.target.value)}
        />
      </Form.Item>

      {selectedCompany.typeOfHr === 'bilingual' && (
        <Form.Item
          label='Reason of payment suspension - English'
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          labelAlign='left'
        >
          <Input
            placeholder='Please, specify the reason of payment suspension'
            value={editedReasonOfSuspensionEng}
            onChange={(e) => setEditedReasonOfSuspensionEng(e.target.value)}
          />
        </Form.Item>
      )}

      <Form.Item label='Suspension Amount' wrapperCol={{ span: 6 }} labelCol={{ span: 10 }} labelAlign='left'>
        <Input onChange={(e) => setSuspensionAmount(e.target.value)} value={suspensionAmount} />
      </Form.Item>

      <Form.Item label='Currency' wrapperCol={{ span: 6 }} labelCol={{ span: 10 }} labelAlign='left'>
        <Input style={{ width: 80 }} disabled value={suspensionCurrency?.toUpperCase()} />
      </Form.Item>

      <Form.Item label='Date of request creation' labelAlign='left'>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <DatePicker
            disabledDate={disabledDate}
            onChange={(val) => setDateOfCreation(val)}
            format={'DD-MM-YYYY'}
            value={dateOfCreation}
          />
          <Tooltip
            title="Select a date to manually set the request acceptance date. Otherwise, today's date will be the request acceptance date."
            color='#b1b1b1'
            placement='bottom'
            autoAdjustOverflow
          >
            <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#b1b1b1', marginBottom: '5px' }} />
          </Tooltip>
        </div>
      </Form.Item>
    </Drawer>
  );
}
