import { formatLocalNumber } from '../helpers/dates';
import translate from '../messages/translate.json'
/**
 * Retrieves the value associated with the specified key from the given data object.
 * If the key does not exist in the object, returns null.
 *
 * @param {string} key - The key whose value is to be retrieved.
 * @param {Object} data - The object from which to retrieve the value.
 * @returns {*} The value associated with the key if it exists, otherwise null.
 */
export function getValueByKey(key, data) {
  // Check if the object has the specified key and return its value, otherwise return null
  return Object.prototype.hasOwnProperty.call(data, key) ? data[key] : null;
}

/**
 * This function checks if the given path matches a specific pattern for either "edit" or "view" requests.
 * The function is flexible, allowing you to pass the type of request (either 'edit' or 'view'), 
 * and it will construct the appropriate regular expression for validation.
 * 
 * @param path - The URL path to be tested
 * @param type - The type of request ('edit' or 'view')
 * @returns A boolean indicating whether the path matches the expected pattern
 */
export function hasRequestPermission(path, type) {
  const isAllowRegex = new RegExp(`^\\/admin\\/${type}-request\\/[a-f0-9]{24}$`);
  return isAllowRegex.test(path);
}

/**
 * 
 * @param {*} inputValue 
 * @param {*} isBold 
 * @returns 
 */
export function getInputFieldValue(inputValue, isBold = false, isInput = false) {
  if (inputValue === null || typeof inputValue === 'undefined') {
    return '~';
  }

  if (isBold && typeof inputValue === 'number' && !isNaN(inputValue) && inputValue > 0) {
    return <strong>{formatLocalNumber(+inputValue)}</strong>
  }

  return !isInput ? formatLocalNumber(+inputValue) : inputValue;
}

/**
 * Retrieves a translation from a nested object based on the provided namespace and language.
 *
 * This function traverses the object by splitting the namespace string into keys, then
 * accesses the nested properties of the object accordingly. If the namespace is invalid 
 * or a key does not exist, it throws an error. Once it reaches the correct nested object,
 * it returns the value for the specified language (e.g., 'en', 'rs'). If the language 
 * does not exist, it returns `null`.
 * 
 * @param {string} namespace - A dot-separated string representing the path to the target object (e.g., 'travelOrder.year').
 * @param {string} lang - The language key to retrieve the appropriate translation (e.g., 'en', 'rs').
 * @returns {*} - The translated value for the specified language or `null` if not found.
 * @throws {Error} - Throws an error if the namespace path is invalid.
 */
export const useLocalTranslations = (lang = 'en') => {
  const lt = (namespace) => {
    const keys = namespace.split('.');
    let result = translate;

    for (const key of keys) {
      if (result[key] === undefined) {
        console.error(`Namespace "${namespace}" is invalid.`);
        return namespace;
      }

      result = result[key];
    }

    return result[lang] || null;
  };

  return { lt };
};

/**
 * Checks if the currentUser object contains `firstName` and `lastName` properties.
 * If both are present, returns the `currentUser`.
 * Otherwise, it assumes the data is nested under the `employee` key and returns `currentUser.employee`.
 *
 * @param {object} currentUser - The user data object that needs to be checked.
 * @returns {object | null} - Returns the currentUser or currentUser.employee if applicable, otherwise null if no data is present.
 */
export const hasUserData = (currentUser) => {
  if (!currentUser) return null;

  if (currentUser.hasOwnProperty('firstName') && currentUser.hasOwnProperty('lastName')) {
    return currentUser;
  }

  return currentUser.employee;
};

/**
 * This function retrieves the full name of a user by combining the first and last name.
 * It checks if the user object is valid and contains the necessary fields before returning the full name.
 * 
 * @param user - An object containing the user's data
 * @returns A string that combines the firstName and lastName of the user
 * @throws Will throw an error if the user object is missing or invalid
 */
export const getUserFullName = (user) => {  
  if (!user || !user.firstName || !user.lastName) {
    throw new Error(`User data is invalid or incomplete: ${JSON.stringify(user)}`);
  }

  const { firstName, lastName } = user;
  return `${firstName} ${lastName}`.trim();
};

/**
 * Formats a number into a localized string based on provided options.
 *
 * @param number - The number to format.
 * @param locale - The locale to use for formatting (default: 'sr-Latn-RS').
 * @param options - Options for formatting the number, such as style, currency, etc.
 * @returns - A formatted number string.
 */
export function formatNumber(number, locale = 'de-DE', options) {
  const defaultOptions = {
    style: 'decimal', // Default style is decimal
    minimumFractionDigits: 2, // Minimum 2 decimal places
    maximumFractionDigits: 2, // Maximum 2 decimal places
  };

  const finalOptions = options || defaultOptions;

  return new Intl.NumberFormat(locale, finalOptions).format(number);
}