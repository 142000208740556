import React, { useContext } from 'react';
import { Button, Input, Popconfirm, Table, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';

const ProductsTable = ({
  data,
  deleteHandler,
  columnKeys,
  editPath,
  viewPath,
  page,
}) => {
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes('category')
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys.map((item) => {
    return {
      key: item.originalName,
      width:
        item.originalName === 'numRow'
          ? '5%'
          : item.originalName === 'code'
            ? '10%'
            : item.originalName === 'title'
              ? '20%'
              : item.originalName === 'description'
                ? '40%'
                : item.originalName === 'unit'
                  ? '5%'
                  : item.originalName === 'priceEUR'
                    ? '10%'
                    : item.originalName === 'priceRSD'
                      ? '20%'
                      : item.originalName === 'createdAt'
                        ? '20%'
                        : item.originalName === 'updatedAt'
                          ? '20%'
                          : '30%',
      title: item.alternativeName.toUpperCase(),
      dataIndex: ['category'].includes(item.originalName)
        ? [item.originalName, user.language ? user.language.selected.code : '']
        : item.originalName,
      ...getColumnSearchProps(
        ['category'].includes(item.originalName)
          ? [item.originalName, user.language ? user.language.selected.code : '']
          : item.originalName,
      ),
      render: (text) => {
        const tooltip = (
          <Tooltip title={text}>
            <span
              style={{
                display: 'inline-block',
                maxWidth: '150px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {text}
            </span>
          </Tooltip>
        );

        const textElement = (
          <span
            style={{
              display: 'inline-block',
              maxWidth: '150px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {text}
          </span>
        );

        return text && text.length > 35 ? tooltip : textElement;
      },
    };
  });

  if (!page) {
    columns.push({
      title: 'Options',
      render: (text, record) => {
        return (
          <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
              <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
                <Link to={editPath + record._id}>
                  <EditOutlined title={'Edit product'} style={{ textDecoration: 'none', color: 'black' }} />
                </Link>
              </div>
            {viewPath && (
              <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
                <Link to={viewPath + record._id}>
                  <EyeOutlined title={'View product'} style={{ textDecoration: 'none', color: 'black' }} />
                </Link>
              </div>
            )}

              <div style={{ margin: '2px', padding: '4px' }}>
                <Popconfirm
                  disabled={record._id === user.data.id}
                  placement='left'
                  title={'This will delete the product. Do you want to delete the product?'}
                  onConfirm={() => {
                    deleteHandler(record._id);
                  }}
                  okText='Ok'
                  cancelText='Cancel'
                >
                  <DeleteOutlined title={'Delete product'} />
                </Popconfirm>
              </div>
          </div>
        );
      },
    });
  }

  return (
    <div>
        <Table
          size='middle'
          bordered
          dataSource={data}
          columns={columns}
          rowKey='_id'
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
        />
    </div>
  );
};

export default ProductsTable;
