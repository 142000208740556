import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { ProductForm } from '../../components/forms';

const EditProduct = (props) => {
  const currentuser = useContext(UserContext);
  const [product, fetchProduct] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();
  const { productId } = props.match.params;

  const selectedEInvoiceCompanyId = (() => {
    const storedCompanyId = localStorage.getItem('selectedEInvoiceCompany');
    const companies = Array.isArray(currentuser?.data?.company) ? currentuser.data.company : [currentuser?.data?.company].filter(Boolean);
    const companyIds = companies.length > 0 ? companies.map(item => item._id) : [];

    return companyIds.includes(storedCompanyId)
        ? storedCompanyId
        : companies.length > 0 ? companies[0]?._id : null;
  })();

  useEffect(() => {
    if (selectedEInvoiceCompanyId) {
      fetchProduct(`${SERVER_URL}/products/${productId}`);
    }
  }, [currentuser]);

  const updateProduct = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/products/${productId}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: 'Product is updated.',
        placement: 'bottomRight',
      });

      history.push('/admin/products');
    } catch (err) {
      console.error('error in update product handler', err)
      notification.error({
        message: 'Problem with edit. Please try later',
        placement: 'bottomRight',
      });
    }
  };

  const createProduct = async (data) => {
    data.selectedEInvoiceCompanyId = selectedEInvoiceCompanyId;
    try {
      await Axios.post(
        `${SERVER_URL}/products`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: 'Product is created.',
        placement: 'bottomRight',
      });

      return true;
    } catch (err) {
      console.error('error in create product', err);
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem with create. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <>
      <div style={{ padding: '8px', textAlign: 'center' }}>
        {productId && product.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        <ProductForm
          data={product.data}
          updateHandler={updateProduct}
          createHandler={createProduct}
          SERVER_URL={SERVER_URL}
          token={currentuser.data.token}
        />
      </div>
    </>
  );
};

export default EditProduct;
