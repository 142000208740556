import React, { useEffect, useState } from 'react';
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import { Button, notification } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../config';

const ExportToExcel = ({ csvData, fileName, propsToRemove, format, user, selectedCompany, allFetched, month, year }) => {
  const fileExtension = '.xlsx';
  const [loading, setLoading] = useState(false);
  const [usdRate, setUsdRate] = useState();
  const [eurRate, setEurRate] = useState();
  const [gbpRate, setGbpRate] = useState();
  const [rubRate, setRubRate] = useState();
  const [rsdRate, setRsdRate] = useState(1);

  let filesToDownload = [];
  for (const data of csvData) {
    if (data.attachments) {
      for (const attachment of data.attachments) {
        filesToDownload.push({ ...attachment, employee: data.employee.firstName + ' ' + data.employee.lastName });
      }
    }
  }

  const employeeData = [];
  let commentExist = false;
  csvData.forEach((salary) => {
    if (salary.comment) {
      commentExist = true;
    }
    employeeData.push({
      firstName: salary.employee.firstName,
      lastName: salary.employee.lastName,
      id: salary.employee._id,
      comment: salary.comment,
    });
  });

  const removeProperties = (object, ...keys) =>
      Object.entries(object).reduce(
          (prev, [key, value]) => ({
            ...prev,
            ...(!keys.includes(key) && { [key]: value }),
          }),
          {},
      );

  useEffect(() => {
    async function convertUSDToRSD() {
      await Axios.post(`${SERVER_URL}/currency`, { currency: 'usd' }, { withCredentials: false })
          .then((res) => {
            setUsdRate(res.data.currentCurrency.exchange_middle);
          });
    }
    async function convertEURToRSD() {
      await Axios.post(`${SERVER_URL}/currency`, { currency: 'eur' }, { withCredentials: false })
          .then((res) => {
            setEurRate(res.data.currentCurrency.exchange_middle);
          });
    }
    async function convertGBPToRSD() {
      await Axios.post(`${SERVER_URL}/currency`, { currency: 'gbp' }, { withCredentials: false })
          .then((res) => {
            setGbpRate(res.data.currentCurrency.exchange_middle);
          });
    }
    async function convertRUBToRSD() {
      await Axios.post(`${SERVER_URL}/currency`, { currency: 'rub' }, { withCredentials: false })
          .then((res) => {
            setRubRate(res.data.currentCurrency.exchange_middle);
          });
    }
    convertUSDToRSD();
    convertEURToRSD();
    convertGBPToRSD();
    convertRUBToRSD();
  }, [setEurRate, setUsdRate, setGbpRate, setRubRate]);

  const dataForExcel = async (data) => {
    const types = [
      'Regular work',
      'Holiday leave',
      'Sick leave up to 30 days 65%',
      'Sick leave up to 30 days 100%',
      'Sick leave more than 30 days 65%',
      'Sick leave more than 30 days 100%',
      'Pregnancy leave',
      'Maternity leave',
      'Work on public holiday',
      'Public holiday',
      'Paid leave',
      'Non-paid leave',
      'Weekend work',
      'Night work',
      'Unemployed',
      'Overtime work on public holiday',
      'Night work on public holiday',
      'Holiday leave - fixed',
      'Sick leave up to 30 days 65% - fixed',
      'Sick leave up to 30 days 100% - fixed',
      'Public holiday - fixed',
      'Paid leave - fixed',
    ];

    let retData = [];
    if (format === 'salaries') {
      for (let i = 0; i < data.length; i++) {
        data[i].code = i + 1;
        if (data[i].employee.JMBG) {
          data[i].jmbg = data[i].employee.JMBG;
        }
        if (
            data[i].employee.company &&
            data[i].employee.company.mealCurrency &&
            data[i].employee.company.mealCurrency !== 'rsd'
        ) {
          if (data[i].employee.company.mealCurrency === 'usd') {
            data[i].hotMeal = data[i].hotMeal * usdRate;
          } else if (data[i].employee.company.mealCurrency === 'eur') {
            data[i].hotMeal = data[i].hotMeal * eurRate;
          } else if (data[i].employee.company.mealCurrency === 'gbp') {
            data[i].hotMeal = data[i].hotMeal * gbpRate;
          } else {
            data[i].hotMeal = data[i].hotMeal * rubRate;
          }
        }
        if (
            data[i].employee.company &&
            data[i].employee.company.holidayCurrency &&
            data[i].employee.company.holidayCurrency !== 'rsd'
        ) {
          if (data[i].employee.company.holidayCurrency === 'usd') {
            data[i].regres = data[i].regres * usdRate;
          } else if (data[i].employee.company.holidayCurrency === 'eur') {
            data[i].regres = data[i].regres * eurRate;
          } else if (data[i].employee.company.holidayCurrency === 'gbp') {
            data[i].regres = data[i].regres * gbpRate;
          } else {
            data[i].regres = data[i].regres * rubRate;
          }
        }
        if (
            data[i].employee.company &&
            data[i].employee.company.otherIncomeCurrency &&
            data[i].employee.company.otherIncomeCurrency !== 'rsd'
        ) {
          if (data[i].employee.company.otherIncomeCurrency === 'usd') {
            data[i].otherIncome = data[i].otherIncome * usdRate;
          } else if (data[i].employee.company.otherIncomeCurrency === 'eur') {
            data[i].otherIncome = data[i].otherIncome * eurRate;
          } else if (data[i].employee.company.otherIncomeCurrency === 'gbp') {
            data[i].otherIncome = data[i].otherIncome * gbpRate;
          } else if (data[i].employee.company.otherIncomeCurrency === 'rsd') {
            data[i].otherIncome = data[i].otherIncome * rsdRate;
          } else {
            data[i].otherIncome = data[i].otherIncome * rubRate;
          }
        }

        if (
            data[i].employee.company &&
            data[i].employee.company.otherExpensesCurrency &&
            data[i].employee.company.otherExpensesCurrency !== 'rsd'
        ) {
          if (data[i].employee.company.otherExpensesCurrency === 'usd') {
            data[i].otherExpenses = data[i].otherExpenses * usdRate;
          } else if (data[i].employee.company.otherExpensesCurrency === 'eur') {
            data[i].otherExpenses = data[i].otherExpenses * eurRate;
          } else if (data[i].employee.company.otherExpensesCurrency === 'gbp') {
            data[i].otherExpenses = data[i].otherExpenses * gbpRate;
          } else if (data[i].employee.company.otherExpensesCurrency === 'rsd') {
            data[i].otherExpenses = data[i].otherExpenses * rsdRate;
          } else {
            data[i].otherExpenses = data[i].otherExpenses * rubRate;
          }
        }

        if (
            data[i].employee.company &&
            data[i].employee.company.suspensionCurrency &&
            data[i].employee.company.suspensionCurrency !== 'rsd'
        ) {
          if (data[i].employee.company.suspensionCurrency === 'usd') {
            data[i].suspension = data[i].suspension * usdRate;
          } else if (data[i].employee.company.suspensionCurrency === 'eur') {
            data[i].suspension = data[i].suspension * eurRate;
          } else if (data[i].employee.company.suspensionCurrency === 'gbp') {
            data[i].suspension = data[i].suspension * gbpRate;
          } else if (data[i].employee.company.suspensionCurrency === 'rsd') {
            data[i].suspension = data[i].suspension * rsdRate;
          } else {
            data[i].suspension = data[i].suspension * rubRate;
          }
        }

        if (
            data[i].employee.company &&
            data[i].employee.company.travelExpCurrency &&
            data[i].employee.company.travelExpCurrency !== 'rsd'
        ) {
          if (data[i].employee.company.travelExpCurrency === 'usd') {
            data[i].travelExpenses = data[i].travelExpenses * usdRate;
          } else if (data[i].employee.company.travelExpCurrency === 'eur') {
            data[i].travelExpenses = data[i].travelExpenses * eurRate;
          } else if (data[i].employee.company.travelExpCurrency === 'gbp') {
            data[i].travelExpenses = data[i].travelExpenses * gbpRate;
          } else {
            data[i].travelExpenses = data[i].travelExpenses * rubRate;
          }
        }

        if (
            data[i].employee.company &&
            data[i].employee.company.workFromHomeType &&
            data[i].employee.company.workFromHomeCurrency
        ) {
          if (data[i].employee.company.workFromHomeType === '1') {
            data[i].workFromHomeType = data[i].employee.company.workFromHomeType;
            data[i].workFromHome1 = data[i].workFromHome;
            if (data[i].employee.company.workFromHomeCurrency !== 'rsd') {
              if (data[i].employee.company.workFromHomeCurrency === 'usd') {
                data[i].workFromHome1 = data[i].workFromHome * usdRate;
              } else if (data[i].employee.company.workFromHomeCurrency === 'eur') {
                data[i].workFromHome1 = data[i].workFromHome * eurRate;
              } else if (data[i].employee.company.workFromHomeCurrency === 'gbp') {
                data[i].workFromHome1 = data[i].workFromHome * gbpRate;
              } else {
                data[i].workFromHome1 = data[i].workFromHome * rubRate;
              }
            }
          } else if (data[i].employee.company.workFromHomeType === '2') {
            data[i].workFromHomeType = data[i].employee.company.workFromHomeType;
            data[i].workFromHome2 = data[i].workFromHome;
            if (data[i].employee.company.workFromHomeCurrency !== 'rsd') {
              if (data[i].employee.company.workFromHomeCurrency === 'usd') {
                data[i].workFromHome2 = data[i].workFromHome * usdRate;
              } else if (data[i].employee.company.workFromHomeCurrency === 'eur') {
                data[i].workFromHome2 = data[i].workFromHome * eurRate;
              } else if (data[i].employee.company.workFromHomeCurrency === 'gbp') {
                data[i].workFromHome2 = data[i].workFromHome * gbpRate;
              } else {
                data[i].workFromHome2 = data[i].workFromHome * rubRate;
              }
            }
          }
        }

        if (
            data[i].employee.company &&
            data[i].employee.company.bonusCurrency &&
            data[i].employee.company.bonusCurrency !== 'rsd'
        ) {
          if (data[i].employee.company.bonusCurrency === 'usd') {
            data[i].bonus = data[i].bonus * usdRate;
          } else if (data[i].employee.company.bonusCurrency === 'eur') {
            data[i].bonus = data[i].bonus * eurRate;
          } else if (data[i].employee.company.bonusCurrency === 'gbp') {
            data[i].bonus = data[i].bonus * gbpRate;
          } else {
            data[i].bonus = data[i].bonus * rubRate;
          }
        }

        if (data[i].employee.endDate) {
          data[i].endDate = data[i].employee.endDate;
        }
        if (data[i].employee.firstName && data[i].employee.lastName)
          data[i].employee = data[i].employee.firstName + ' ' + data[i].employee.lastName;

        for (let type of types) {
          let isExist = false;
          let hours = 0;
          for (let workType of data[i].hoursByType) {
            if (workType.workType === type || (workType.workType === 'Pregnancy leave' && type === 'Pregnancy leave')) {
              isExist = true;
              hours = parseFloat(workType.hours) / 60;
            }
            if (workType.workType === type || (workType.workType === 'Maternity leave' && type === 'Maternity leave')) {
              isExist = true;
              hours = parseFloat(workType.hours) / 60;
            }
            if (type === 'Paid leave' && workType.workType.includes('Paid leave')) {
              isExist = true;
              hours = parseFloat(workType.hours) / 60;
            }
            if (selectedCompany[0].salaryCalculationType === 'fixed' && selectedCompany[0].holidayLeaveConfirmation === 'fixed' && type === 'Holiday leave - fixed' && workType.workType.includes('Holiday leave')) {
              isExist = true;
              hours = parseFloat(workType.hours) / 60;
            }
            if (selectedCompany[0].salaryCalculationType === 'fixed' && selectedCompany[0].sickLeaveUpTo30Days65Confirmation === 'fixed' && type === 'Sick leave up to 30 days 65% - fixed' && workType.workType.includes('Sick leave up to 30 days 65%')) {
              isExist = true;
              hours = parseFloat(workType.hours) / 60;
            }
            if (selectedCompany[0].salaryCalculationType === 'fixed' && selectedCompany[0].sickLeaveUpTo30Days100Confirmation === 'fixed' && type === 'Sick leave up to 30 days 100% - fixed' && workType.workType.includes('Sick leave up to 30 days 100%')) {
              isExist = true;
              hours = parseFloat(workType.hours) / 60;
            }
            if (selectedCompany[0].salaryCalculationType === 'fixed' && selectedCompany[0].publicHolidayConfirmation === 'fixed' && type === 'Public holiday - fixed' && workType.workType.includes('Public holiday')) {
              isExist = true;
              hours = parseFloat(workType.hours) / 60;
            }
            if (selectedCompany[0].salaryCalculationType === 'fixed' && selectedCompany[0].paidLeaveConfirmation === 'fixed' && type === 'Paid leave - fixed' && workType.workType.includes('Paid leave')) {
              isExist = true;
              hours = parseFloat(workType.hours) / 60;
            }
          }

          if (isExist) {
            if (hours.toString().split('.').length > 1) {
              hours = hours.toFixed(2);
            }

            if (selectedCompany[0].salaryCalculationType === 'fixed' && selectedCompany[0].holidayLeaveConfirmation === 'fixed' && type === 'Holiday leave') {
              data[i][type] = 0;
            } else if (selectedCompany[0].salaryCalculationType === 'fixed' && selectedCompany[0].sickLeaveUpTo30Days65Confirmation === 'fixed' && type === 'Sick leave up to 30 days 65%') {
              data[i][type] = 0;
            } else if (selectedCompany[0].salaryCalculationType === 'fixed' && selectedCompany[0].sickLeaveUpTo30Days100Confirmation === 'fixed' && type === 'Sick leave up to 30 days 100%') {
              data[i][type] = 0;
            } else if (selectedCompany[0].salaryCalculationType === 'fixed' && selectedCompany[0].publicHolidayConfirmation === 'fixed' && type === 'Public holiday') {
              data[i][type] = 0;
            } else if (selectedCompany[0].salaryCalculationType === 'fixed' && selectedCompany[0].paidLeaveConfirmation === 'fixed' && type === 'Paid leave') {
              data[i][type] = 0;
            } else {
              data[i][type] = hours;
            }
          } else {
            data[i][type] = 0;
          }
        }
        if (data[i].overTime2 === 0) {
          data[i].overTime2 = +parseFloat(data[i].overTime2).toFixed(2);
        }
        if (parseFloat(data[i].overTime) % 60 !== 0) {
          data[i].overTime = Math.floor(parseFloat(data[i].overTime) / 60) + (parseFloat(data[i].overTime) % 60) / 60;
        } else {
          data[i].overTime = +(parseFloat(data[i].overTime) / 60).toFixed(2);
        }
        if (data[i].overTime2) {
          if (parseFloat(data[i].overTime2) % 60 !== 0) {
            data[i].overTime2 =
                Math.floor(parseFloat(data[i].overTime2) / 60) + (parseFloat(data[i].overTime2) % 60) / 60;
          } else {
            data[i].overTime2 = +(parseFloat(data[i].overTime2) / 60).toFixed(2);
          }
        }
        if (data[i].overTime > 32) {
          if (data[i].overTime2) {
            data[i].overTime2 = parseFloat(data[i].overTime2) + parseFloat(data[i].overTime) - 32;
          } else {
            data[i].overTime2 = +(data[i].overTime - 32).toFixed(2);
          }
          data[i].overTime = +parseFloat(32).toFixed(2);
        }
        if (data[i].stoppedRegularWork) {
          data[i].stoppedRegularWork = parseFloat(data[i].stoppedRegularWork) / 60;
        } else {
          data[i].stoppedRegularWork = 0;
          propsToRemove.push('stoppedRegularWork');
        }
        data[i].comment = data[i].comment ? 'Yes' : 'No';
        retData.push(data[i]);
      }

      retData = retData.map((item) => removeProperties(item, ...propsToRemove));
      let newRetData = [];
      retData.map((item) => {
        delete Object.assign(item, { ['Code']: item['code'] })['code'];
        delete Object.assign(item, { ['Personal number']: item['jmbg'] })['jmbg'];
        delete Object.assign(item, { ['Full name']: item['employee'] })['employee'];
        for (let workType of types) {
          delete Object.assign(item, { [workType + ' hours']: item[workType] })[workType];
        }
        delete Object.assign(item, { ['Meal allowance']: item['hotMeal'] })['hotMeal'];
        delete Object.assign(item, { ['Holiday allowance']: item['regres'] })['regres'];
        delete Object.assign(item, { ['Bonus']: item['bonus'] })['bonus'];
        delete Object.assign(item, {
          ['Other income on salary']: item['otherIncomeValue'],
        })['otherIncomeValue'];
        delete Object.assign(item, {
          ['Other income on salary']: item['otherIncome'],
        })['otherIncome'];
        delete Object.assign(item, { ['Suspension of earnings']: item['suspensionValue'] })['suspensionValue'];
        delete Object.assign(item, { ['Suspension of earnings']: item['suspension'] })['suspension'];
        delete Object.assign(item, { ['Other paid expenses']: item['otherExpensesValue'] })['otherExpensesValue'];
        delete Object.assign(item, { ['Other paid expenses']: item['otherExpenses'] })['otherExpenses'];
        delete Object.assign(item, { ['Travel expenses']: item['travelExpenses'] })['travelExpenses'];
        delete Object.assign(item, { ['Overtime 1']: item['overTime'] })['overTime'];
        delete Object.assign(item, { ['Overtime 2']: item['overTime2'] })['overTime2'];
        delete Object.assign(item, { ['Remark']: item['comment'] })['comment'];
        newRetData.push(item);
      });

      return newRetData;
    } else {
      retData = await data.map((item) => removeProperties(item, ...propsToRemove));
    }
    return retData;
  };

  const exportToCSV = async (csvData, fileName) => {
    const workTypes = [
      'Regular work',
      'Holiday leave',
      'Sick leave up to 30 days 65%',
      'Sick leave up to 30 days 100%',
      'Sick leave more than 30 days 65%',
      'Sick leave more than 30 days 100%',
      'Public holiday',
      'Paid leave',
      'Holiday leave - fixed',
      'Sick leave up to 30 days 65% - fixed',
      'Sick leave up to 30 days 100% - fixed',
      'Public holiday - fixed',
      'Paid leave - fixed',
      'Pregnancy leave',
      'Maternity leave',
      'Work on public holiday',
      'Non-paid leave',
      'Weekend work',
      'Night work',
      'Unemployed',
      'Overtime work on public holiday',
      'Night work on public holiday',
    ];

    if (csvData.length !== 0) {
      const filtered = await dataForExcel(csvData);
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('data');
      const columns = [
        {
          header: 'Code',
          key: 'Code',
        },
        {
          header: 'Personal number',
          key: 'Personal number',
        },
        {
          header: 'Full name',
          key: 'Full name',
        },
      ];
      for (const type of workTypes) {
        columns.push({
          header: type,
          key: type + ' hours',
        });
      }
        columns.push(
            {
              header: 'Meal allowance',
              key: 'Meal allowance',
            },
            {
              header: 'Overtime 1',
              key: 'Overtime 1',
            },
            {
              header: 'Overtime 2',
              key: 'Overtime 2',
            },
            {
              header: 'Holiday allowance',
              key: 'Holiday allowance',
            },
            {
              header: 'Bonus',
              key: 'Bonus',
            },
            {
              header: 'Other income on salary',
              key: 'Other income on salary',
            },
            {
              header: 'Suspension of earnings',
              key: 'Suspension of earnings',
            },
            {
              header: 'Other paid expenses',
              key: 'Other paid expenses',
            },
            {
              header: 'Travel expenses',
              key: 'Travel expenses',
            },
            {
              header: 'WFH 1',
              key: 'workFromHome1',
            },
            {
              header: 'WFH 2',
              key: 'workFromHome2',
            },
            {
              header: 'Remark',
              key: 'Remark',
            },
        );

      worksheet.columns = columns;
      for (const row of filtered) {
        worksheet.addRow(row);
      }

      const yellowStyle = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF00' }, // Yellow background color
        },
      };

      // columns relevant for salaryCalculationType fixed/average
      const confirmations = {
        'Holiday leave': 'holidayLeaveConfirmation',
        'Sick leave up to 30 days 65%': 'sickLeaveUpTo30Days65Confirmation',
        'Sick leave up to 30 days 100%': 'sickLeaveUpTo30Days100Confirmation',
        'Public holiday': 'publicHolidayConfirmation',
        'Paid leave': 'paidLeaveConfirmation',
        'Holiday leave - fixed': 'holidayLeaveConfirmation',
        'Sick leave up to 30 days 65% - fixed': 'sickLeaveUpTo30Days65Confirmation',
        'Sick leave up to 30 days 100% - fixed': 'sickLeaveUpTo30Days100Confirmation',
        'Public holiday - fixed': 'publicHolidayConfirmation',
        'Paid leave - fixed': 'paidLeaveConfirmation',
      };

      // Iterate through each column and set the style conditionally
      // if salaryCalculationType = fixed and is confirmed as fixed, then average columns should be colored in yellow and vice versa
      worksheet.columns.forEach((column) => {
        const columnIndex = column._number;
        const columnName = column.header;

        const confirmationKey = confirmations[columnName];

        if (
            confirmationKey &&
            selectedCompany[0].hasOwnProperty(confirmationKey)
        ) {
          const confirmationStatus = selectedCompany[0][confirmationKey];

          const isFixedType = columnName.includes(' - fixed');
          const shouldColorize = isFixedType
              ? confirmationStatus !== 'fixed'
              : confirmationStatus === 'fixed';

          if (shouldColorize) {
            worksheet.getColumn(columnIndex).eachCell((cell) => {
              cell.style = yellowStyle;
            });
          }
        }
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([Buffer.from(buffer)], { type: 'applicationi/xlsx' });
      FileSaver.saveAs(blob, fileName + new Date().getTime() + fileExtension);
      let keys = [];
      for (let i = 0; i < filesToDownload.length; i++) {
        keys.push(filesToDownload[i].location);
      }
      if (keys.length !== 0 || commentExist) {
        const res = await Axios.post(
            `${SERVER_URL}/zip-file`,
            { keys: keys, user: user.data.id, employees: employeeData },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${user.data.token}` },
            },
        );
        if (res.data.salariesWithError.length !== 0) {
          const employeeNames = [];
          res.data.salariesWithError.forEach((item) => {
            employeeNames.push(item.employee.firstName + ' ' + item.employee.lastName);
          });
          notification.info({
            message: 'Problem with downloading files for employees ' + employeeNames.join(' ,'),
            placement: 'bottomRight',
          });
        }
        setTimeout(async () => {
          const response = await Axios.get(`${SERVER_URL}/file/zip/${user.data.id}.zip`, {
            withCredentials: false,
          });
          FileSaver.saveAs(response.config.url, 'Attach - ' + selectedCompany[0].name + `- ${month} ${year} -` + new Date().getTime() + '.zip');
          setLoading(false);
        }, 2000);
      } else {
        setLoading(false);
      }
    } else {
      notification.info({
        message: 'Record for chosen data does not exist.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    }
  };

  return (
      <div>
        {!loading ? (
            <Button
                type='primary'
                size='small'
                title='Export to Excel'
                onClick={(e) => {
                  setLoading(true);
                  exportToCSV(csvData, fileName);
                }}
                disabled={!usdRate || !eurRate || !gbpRate || !rubRate || csvData?.length < 1 || !allFetched}
            >
              <ExportOutlined /> Export to excel
            </Button>
        ) : (
            <Button type='primary' loading>
              Loading
            </Button>
        )}
      </div>
  );
};

export default ExportToExcel;