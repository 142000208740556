import React, { useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, Spin, notification, Space, Table, Tooltip } from 'antd';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { createOfficialTravel, updateOfficialTravelById } from '../../../../services/official-travel.js';
import { getInputFieldValue, hasRequestPermission, useLocalTranslations } from '../../../../utils/index.js';
import { useHistory, useLocation } from 'react-router-dom';
import StepControl from '../StepControl.js';
import { cn } from '../../../../libs/index.js';
import { formatLocalNumber } from '../../../../helpers/dates.js';

export default function TravelCalculation({
  viewMode,
  formInit,
  token,
  employeeId,
  companyId,
  handleRefetchRequest,
  stepLang,
  currentStep,
  onChangeStep
}) {
  const [form] = Form.useForm();
  const isNew = !formInit;
  const disabled = !!viewMode;
  const { lt } = useLocalTranslations(stepLang);

  const history = useHistory();
  const { pathname } = useLocation();
  const hasViewDisabled = hasRequestPermission(pathname, 'view');
  const hasDisabled = hasViewDisabled;

  function handleBack() {
    history.goBack();
  }

  const [startDate, setStartDate] = useState(
    formInit?.travelOrderCalculation?.startDateAndTime
      ? moment(formInit?.travelOrderCalculation?.startDateAndTime)
      : null,
  );
  const [startBorderDate, setStartBorderDate] = useState(
    formInit?.travelOrderCalculation?.startBorderCrossDateAndTime
      ? moment(formInit?.travelOrderCalculation?.startBorderCrossDateAndTime)
      : null,
  );
  const [endBorderDate, setEndBorderDate] = useState(
    formInit?.travelOrderCalculation?.endBorderCrossDateAndTime
      ? moment(formInit?.travelOrderCalculation?.endBorderCrossDateAndTime)
      : null,
  );
  const [endDate, setEndDate] = useState(
    formInit?.travelOrderCalculation?.endDateAndTime ? moment(formInit?.travelOrderCalculation?.endDateAndTime) : null,
  );

  const abroadCurrency = formInit?.travelOrder?.dailyWageForAbroadCurrency || 'EUR';

  const columns = [
    {
      title: 'No.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (text, record) => {
        const isHighlighted = ['6', '7', '14', '15', '22', '23'].includes(record.key);
        const isValueFormatted = Array.from({ length: 18 }, (_, index) => (index + 6).toString()).includes(record.key);
        const roundedNumberValues = ['1', '2', '3', '4', '5'];
        const isCountry = stepLang === 'rs' ? record.label.toLowerCase().trim().includes('zemlji:') : record.label.toLowerCase().trim().includes('country:');

        if (isValueFormatted) {
          return (
            <div className={cn('input-wrapper', isHighlighted ? 'highlighted' : '')}>
              <Input
                type='text'
                value={formatLocalNumber(text)}
                disabled
                style={{ color: 'red' }}
                suffix={
                  <Tooltip title="Currency">
                    {isCountry ? 'RSD' : abroadCurrency}
                  </Tooltip>
                }
                />
            </div>
          )
        }

        return (
          <Input
            value={!roundedNumberValues.includes(record.key) ? text : text}
            disabled
            style={{
              color: 'green',
              backgroundColor: isHighlighted ? '#f0f0f0' : '',
              fontWeight: isHighlighted ? 'bold' : 'normal',
            }}
          />
        );
      },
    },
  ];

  const dataSource = [
    {
      key: '1',
      label: `${lt("travelOrderCalculation.totalNumberOfHours")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.totalNumberOfHours, false, true),
    },
    {
      key: '2',
      label: `${lt("travelOrderCalculation.numberOfHoursCountry")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.numberOfHoursCountry, false, true),
    },
    {
      key: '3',
      label: `${lt("travelOrderCalculation.numberOfHoursAbroad")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.numberOfHoursAbroad, false, true),
    },
    {
      key: '4',
      label: `${lt("travelOrderCalculation.numberOfDailyWageForCountry")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.numberOfDailyWageForCountry, false, true),
    },
    {
      key: '5',
      label: `${lt("travelOrderCalculation.numberOfDailyWageForAbroad")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.numberOfDailyWageForAbroad, false, true),
    },
    {
      key: '6',
      label: `${lt("travelOrderCalculation.totalDailyWageForCountry")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.totalDailyWageForCountry, false, true),
    },
    {
      key: '7',
      label: `${lt("travelOrderCalculation.totalDailyWageForAbroad")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.totalDailyWageForAbroad, false, true),
    },
    {
      key: '8',
      label: `${lt("travelOrderCalculation.accommodationCostsByInvoicesCountry")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.accommodationCostsByInvoicesCountry, false, true),
    },
    {
      key: '9',
      label: `${lt("travelOrderCalculation.accommodationCostsByInvoicesAbroad")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.accommodationCostsByInvoicesAbroad, false, true),
    },
    {
      key: '10',
      label: `${lt("travelOrderCalculation.transportationCostsByInvoicesCountry")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.transportationCostsByInvoicesCountry, false, true),
    },
    {
      key: '11',
      label: `${lt("travelOrderCalculation.transportationCostsByInvoicesAbroad")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.transportationCostsByInvoicesAbroad, false, true),
    },
    {
      key: '12',
      label: `${lt("travelOrderCalculation.otherCostsByInvoicesCountry")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.otherCostsByInvoicesCountry, false, true),
    },
    {
      key: '13',
      label: `${lt("travelOrderCalculation.otherCostsByInvoicesAbroad")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.otherCostsByInvoicesAbroad, false, true),
    },
    {
      key: '14',
      label: `${lt("travelOrderCalculation.totalCostsCountry")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.totalCostsCountry, false, true),
    },
    {
      key: '15',
      label: `${lt("travelOrderCalculation.totalCostsAbroad")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.totalCostsAbroad, false, true),
    },
    {
      key: '16',
      label: `${lt("travelOrderCalculation.totalCalculationCountry")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.totalCalculationCountry, false, true),
    },
    {
      key: '17',
      label: `${lt("travelOrderCalculation.totalCalculationAbroad")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.totalCalculationAbroad, false, true),
    },
    {
      key: '18',
      label: `${lt("travelOrderCalculation.totalPaymentInAdvanceCountry")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.totalPaymentInAdvanceCountry, false, true),
    },
    {
      key: '19',
      label: `${lt("travelOrderCalculation.totalPaymentInAdvanceAbroad")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.totalPaymentInAdvanceAbroad, false, true),
    },
    {
      key: '20',
      label: `${lt("travelOrderCalculation.totalPaidWithCompanyCardCountry")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.totalPaidWithCompanyCardCountry, false, true),
    },
    {
      key: '21',
      label: `${lt("travelOrderCalculation.totalPaidWithCompanyCardAbroad")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.totalPaidWithCompanyCardAbroad, false, true),
    },
    {
      key: '22',
      label: `${lt("travelOrderCalculation.totalAmountForPaymentCountry")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.totalAmountForPaymentCountry, false, true),
    },
    {
      key: '23',
      label: `${lt("travelOrderCalculation.totalAmountForPaymentAbroad")}`,
      value: getInputFieldValue(formInit?.travelOrderCalculation?.totalAmountForPaymentAbroad, false, true),
    },
    {
      key: '24',
      label: `${lt("travelOrderCalculation.attachmentNumber")}`,
      value: formInit?.travelOrderCalculation?.attachmentNumber ?? 0,
    },
  ];

  const rowClassName = (record) => {
    const isHighlighted = ['6', '7', '14', '15', '22', '23'];
    return isHighlighted.includes(record.key) ? 'highlight-row' : '';
  };

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = () => {
    const payload = {
      travelOrderCalculation: {
        startDateAndTime: startDate?.valueOf() || null,
        startBorderCrossDateAndTime: startBorderDate?.valueOf() || null,
        endBorderCrossDateAndTime: endBorderDate?.valueOf() || null,
        endDateAndTime: endDate?.valueOf() || null,
      },
      employee: employeeId,
      company: companyId,
    };

    if (isNew) {
      createOfficialTravel(token, payload)
        .then((data) => {
          notification.success({
            message: 'The data has been saved!',
            placement: 'bottomRight',
          });
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: 'Problem with saving data. Contact administrator.',
            placement: 'bottomRight',
          });
        });
    }

    if (!isNew) {
      updateOfficialTravelById(token, payload, formInit._id)
        .then((data) => {
          setIsLoading(true);
          handleRefetchRequest && handleRefetchRequest();
          notification.success({
            message: 'The data has been saved!',
            placement: 'bottomRight',
          });
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: 'Problem with saving data. Contact administrator.',
            placement: 'bottomRight',
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 700);
        })
    }
  };

  return (
    <div className='travel-calculation'>
      <div className='step-header'>
        <h3 className='block-title'>{lt("travelOrderCalculation.stepTitle")}</h3>
      </div>

      <div className='form-wrapper'>
        <Form className='form-travel-calculation' form={form} onFinish={(values) => onSubmit(values)} layout='vertical' style={{ width: '100%' }}>
          <div className='group'>
            <Form.Item
              label={lt("travelOrderCalculation.startDateAndTime")}
              className='custom-label'
            >
              <Space direction='vertical'>
                <DatePicker
                  format='DD/MM/YYYY HH:mm'
                  showTime={{ format: 'HH:mm' }}
                  onChange={(value) => setStartDate(value)}
                  value={startDate ? moment(startDate) : null}
                  disabled={hasDisabled}
                />
              </Space>
            </Form.Item>
          </div>

          <div className='group'>
            <Form.Item
              label={lt("travelOrderCalculation.startBorderCrossDateAndTime")}
              className='custom-label'
            >
              <Space direction='vertical'>
                <DatePicker
                  format='DD/MM/YYYY HH:mm'
                  showTime={{ format: 'HH:mm' }}
                  onChange={(value) => setEndDate(value)}
                  value={endDate ? moment(endDate) : null}
                  disabled={hasDisabled}
                />
              </Space>
            </Form.Item>
          </div>          

          <div className='group'>
            <Form.Item
              label={lt("travelOrderCalculation.endBorderCrossDateAndTime")}
              className='custom-label'
            >
              <Space direction='vertical'>
                <DatePicker
                  format='DD/MM/YYYY HH:mm'
                  showTime={{ format: 'HH:mm' }}
                  onChange={(value) => setStartBorderDate(value)}
                  value={startBorderDate ? moment(startBorderDate) : null}
                  disabled={hasDisabled}
                />
              </Space>
            </Form.Item>
          </div>

          <div className='group'>
            <Form.Item
              label={lt("travelOrderCalculation.endDateAndTime")}
              className='custom-label'
            >
              <Space direction='vertical'>
                <DatePicker
                  format='DD/MM/YYYY HH:mm'
                  showTime={{ format: 'HH:mm' }}
                  onChange={(value) => setEndBorderDate(value)}
                  value={endBorderDate ? moment(endBorderDate) : null}
                  disabled={hasDisabled}
                />
              </Space>
            </Form.Item>
          </div>

          {!hasViewDisabled && (
            <div className='actions'>
              {!viewMode && (
                <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right' }}>
                  <Button loading={isLoading} type='primary' htmlType='submit' disabled={disabled}>
                    Save
                  </Button>
                </Form.Item>
              )}
            </div>
          )}
        </Form>
      </div>

      <div className='table-wrapper'>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          bordered
          rowKey='key'
          rowClassName={rowClassName}
        />
      </div>


      {hasViewDisabled ? (
        <div className="sticky-actions">
          <div className="grid">
            <div className="flex">
              <StepControl currentStep={currentStep} onChangeStep={onChangeStep} />
              
              <Button type='button' onClick={handleBack}>
                Return
              </Button>                   
            </div>
          </div>
        </div>     
      ) : null}

      <Modal open={isLoading} maskClosable={false} closable={false} footer={null}>
        <div style={{ textAlign: 'center', fontSize: '1.05rem' }}>
          <p>Processing file create!</p>
          <p>Please wait. This may take up to a minute depending on number of employees.</p>
          <p>
            <Spin style={{ color: '#2db1ab' }} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </p>
        </div>
      </Modal>
    </div>
  );
}
