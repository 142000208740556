import React, { createContext, useEffect, useState } from 'react';
import { Switch, BrowserRouter as Router, useHistory } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Axios from 'axios';
import { message } from 'antd';
import { SERVER_URL } from './config';
import Layout from './components/base/Layout';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import './App.scss';
import { ThemeProvider } from './context/ThemeContext';
import { TimesheetProvider } from './context/TimesheetContext';

export const UserContext = createContext(null);

const handleLogin = async (user) => {
  try {
    const userResponse = await Axios.post(
      `${SERVER_URL}/login`,
      { email: user.email, password: user.password },
      { withCredentials: false },
    );

    if (userResponse.data.message === 'Auth successful') {
      if (user.rememberMe) {
        localStorage.setItem('user', JSON.stringify(userResponse.data));
      } else {
        sessionStorage.setItem('user', JSON.stringify(userResponse.data));
      }
      if (userResponse.data.role.includes('employee')) {
        window.location.href = '/admin/hr-platform-requests';
      } else if (userResponse.data.role.includes('eInvoiceOfficer')) {
        if (userResponse.data.company?.length > 1) {
          window.location.href = '/admin/companies';
        } else window.location.href = '/admin/accounts-payable';
      } else {
        window.location.reload();
      }
    }
  } catch (err) {
    console.log('\n Login error = ', err.response.data.message);
    const errMsg = err.response.data.message;
    if (errMsg === 'Auth failed, user not found!') {
      return message.warning('User not found.');
    }
    if (errMsg === 'Auth failed, incorrect password!') {
      return message.warning('Wrong password');
    }
    return message.warning(errMsg);
  }
};

const App = () => {
  const [isLoggedIn, setLoggedIn] = useState(true);
  const [language, setLanguage] = useState();
  let user = sessionStorage.getItem('user') ? sessionStorage.getItem('user') : localStorage.getItem('user');
  const history = useHistory();
  if (user) user = JSON.parse(user);

  useEffect(() => {
    // Not good way to check session
    if (user && new Date() > new Date(user.expires)) {
      sessionStorage.removeItem('user');
      localStorage.removeItem('user');
      console.log('Session expired!');
      setLoggedIn(false);
      history.push('/login');
    } else if (user === null) {
      setLoggedIn(false);
      history.push('/login');
    } else {
      setLoggedIn(true);
    }
  }, [setLoggedIn, user, history]);

  useEffect(() => {
    Axios.get(`${SERVER_URL}/languages-selected`)
      .then((resp) => {
        const defaultLanguage = resp.data.find((l) => l.default);
        setLanguage({ selected: defaultLanguage, list: resp.data, default: defaultLanguage });
      })
      .catch((err) => console.log(err.response ? err.response.data.message : err.message));
  }, [setLanguage]);

  return (
    <ThemeProvider>
      <UserContext.Provider value={{ isLoggedIn, handleLogin, data: user, language, setLanguage }}>
        <Switch>
          {isLoggedIn ? (
            <TimesheetProvider>
              <Layout />
            </TimesheetProvider>
          ) : (
            <>
              <Route exact path='/admin' component={Login} />
              <Route exact path='/login' component={Login} />
              <Route exact path='/' component={Login} />
            </>
          )}
          <Route exact path='/password-reset/:token' component={ResetPassword} />
        </Switch>
      </UserContext.Provider>
    </ThemeProvider>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
