import React, { memo } from 'react';
import { Popconfirm } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { deleteOfficialTravelInvoicesFileByFileKey, downloadOfficialTravelInvoicesImage } from '../../services/official-travel';

function ImagesList({ token, employee, sources, handleRefetchRequest }) {
  const downloadImage = async (originalName) => {
    const key = `${employee.firstName.replaceAll(' ', '_')}_${employee.lastName.replaceAll(' ', '_')}_${employee.JMBG}/travel_invoices/${originalName}`;

    try {
      await downloadOfficialTravelInvoicesImage(token, key, originalName);
    } catch (err) {
      console.error(err);
    }
  }

  const removeImage = async (originalName, key) => {
    try {
      await deleteOfficialTravelInvoicesFileByFileKey(token, originalName, key);
      handleRefetchRequest && handleRefetchRequest();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="image-lists">
      {sources && sources.length ? sources.map((src, idx) => {
        return (
          <div key={src._id} className='list-item'>
            <p className='text'>#{idx + 1} - {src.originalname}</p>

            <div className='right'>
              <button
                type="button"
                className='btn btn-download'
                onClick={() => downloadImage(src.originalName)}
                >
                <DownloadOutlined />
              </button>

              <Popconfirm
                title="Are you sure you want to delete this image?"
                onConfirm={() => removeImage(src.originalName, src.key)}
                okText="Yes"
                cancelText="No"
              >
                <button
                  type="button"
                  className='btn btn-delete'
                  >
                  <DeleteOutlined />
                </button>
              </Popconfirm>
            </div>
          </div>
        )
      }) : (
        <p style={{ textAlign: 'center', fontWeight: '600', fontSize: '16px' }}>No files to display</p>
      )}
    </div>
  );
}

export default memo(ImagesList);
