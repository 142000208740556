import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Select, Result, Radio, Checkbox, Tooltip } from 'antd';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';
import EmailSettings from '../EmailSettings';
import AdminEmailsDrawer from '../AdminEmailsDrawer';
import { QuestionCircleOutlined } from '@ant-design/icons';

const tailLayout = {
  wrapperCol: { span: 24 },
};

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const initialValues = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  password: undefined,
  phone: '',
  legalType: 'PRIVATE',
  address: undefined,
  city: undefined,
  zip: undefined,
  country: undefined,
  avatar: undefined,
  role: [],
  statuaryAuthorization: false,
  jmbg: '',
  legalId: '',
};

const UserForm = ({ data, updateHandler, createHandler, result, setResult, SERVER_URL, token, loggedUser }) => {
  const [form] = Form.useForm();
  const [legalType, setLegalType] = useState(data ? data.legalType : 'PRIVATE');
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  const [roleForClient, setRoleForClient] = useState('');
  const [statuaryAuthorizationChecked, setStatuaryAuthorizationChecked] = useState(false);
  const [adminEmailsDrawer, setAdminEmailsDrawer] = useState(false);
  const [timesheetSubmission, setTimesheetSubmission] = useState(true);
  const [leavesDecisions, setLeavesDecisions] = useState(true);
  const [terminationExtensionEmails, setTerminationExtensionEmails] = useState(true);
  const [workPermissionExpireEmails, setWorkPermissionExpireEmails] = useState(true);
  const [paymentOrdersEmails, setPaymentOrdersEmails] = useState(true);
  const [newEmployeeEmails, setNewEmployeeEmails] = useState(true);
  const [officialTravelEmails, setOfficialTravelEmails] = useState(true);

  let user = data ? { ...data } : initialValues;
  const isNew = !data;

  useEffect(() => {
    if (
      !roleForClient ||
      roleForClient.length === 0 ||
      !roleForClient.includes('admin') ||
      !form.getFieldValue('role').includes('admin')
    ) {
      setStatuaryAuthorizationChecked(false);
      form.setFieldValue('statuaryAuthorization', false);
    }

    if (form.getFieldValue('role').includes('admin')) {
      setRoleForClient('admin');
    }
  }, [roleForClient, setStatuaryAuthorizationChecked, form.getFieldValue('role')]);

  useEffect(() => {
    if (data && data.role.includes('admin') && data.statuaryAuthorization === true) {
      setRoleForClient('admin');
      setStatuaryAuthorizationChecked(true);
      form.setFieldValue('statuaryAuthorization', true);
      if (data.jmbg) {
        form.setFieldValue('jmbg', data.jmbg);
      }
    }
  }, [data, form, setStatuaryAuthorizationChecked, setRoleForClient]);

  useEffect(() => {
    if (data?.avatar) setImage(data.avatar.url);
    if (data?.adminEmailsSettings) {
      const adminEmailsSettings = data?.adminEmailsSettings;
      setTimesheetSubmission(adminEmailsSettings.timesheetSubmission);
      setLeavesDecisions(adminEmailsSettings.leavesDecisions);
      setTerminationExtensionEmails(adminEmailsSettings.terminationExtensionEmails);
      setWorkPermissionExpireEmails(adminEmailsSettings.workPermissionExpireEmails);
      setPaymentOrdersEmails(adminEmailsSettings.paymentOrdersEmails);
      setNewEmployeeEmails(adminEmailsSettings.newEmployeeEmails);
      setOfficialTravelEmails(adminEmailsSettings.officialTravelEmails);
    }
  }, [data]);

  const onFinish = (values) => {
    if (isNew) {
      createHandler(values);
    } else {
      values.adminEmailsSettings = {
        timesheetSubmission,
        leavesDecisions,
        terminationExtensionEmails,
        workPermissionExpireEmails,
        paymentOrdersEmails,
        newEmployeeEmails,
        officialTravelEmails
      };
      setAdminEmailsDrawer(false);
      updateHandler(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onClose = () => {
    setAdminEmailsDrawer(false);
  };

  const handleLegalType = (e) => {
    setLegalType(e.target.value);
    form.setFieldsValue({ legalType: e.target.value });
  };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  return (
    <div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          {/* eslint-disable-next-line no-useless-concat */}
          <Card title={`${isNew ? 'NEW CLIENT' : "CLIENT'S DETAILS"}`} bordered={false}>
            {!result && (
              <Form
                {...layout}
                name='basic'
                initialValues={user}
                onFinish={(values) => onFinish(values, isNew)}
                onFinishFailed={onFinishFailed}
                layout='horizontal'
                form={form}
              >
                {isNew && (
                  <Form.Item name='legalType' label='Legal type'>
                    <Radio.Group onChange={handleLegalType}>
                      <Radio value='PRIVATE'>Private</Radio>
                      <Radio value='COMPANY'>Company</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}

                <Form.Item
                  label='Email'
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter email!',
                    },
                  ]}
                >
                  <Input placeholder='Email' />
                </Form.Item>

                <Form.Item
                  label='Name'
                  name='firstName'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter name!',
                    },
                  ]}
                >
                  <Input placeholder='Name' />
                </Form.Item>

                <Form.Item
                  label='Surname'
                  name='lastName'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter surname!',
                    },
                  ]}
                >
                  <Input placeholder='Surname' />
                </Form.Item>

                <Form.Item label='Phone number' name='phone'>
                  <Input placeholder='Phone number' />
                </Form.Item>

                <Form.Item label='Address' name='address'>
                  <Input placeholder='Address' />
                </Form.Item>

                <Form.Item
                  label='City'
                  name='city'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter city!',
                    },
                  ]}
                >
                  <Input placeholder='City' />
                </Form.Item>

                <Form.Item
                  label='State'
                  name='country'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter state!',
                    },
                  ]}
                >
                  <Input placeholder='State' />
                </Form.Item>

                {legalType === 'COMPANY' && (
                  <>
                    <Form.Item
                      label='Company name'
                      name='companyName'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter name of company!',
                        },
                      ]}
                    >
                      <Input placeholder='Name of company' />
                    </Form.Item>

                    <Form.Item
                      label='TIN'
                      name='taxId'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter TIN!',
                        },
                      ]}
                    >
                      <Input placeholder='TIN' />
                    </Form.Item>
                  </>
                )}

                {isNew && (
                  <Form.Item
                    label='Password'
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: 'Please enter password!',
                      },
                    ]}
                  >
                    <Input.Password placeholder='Password' />
                  </Form.Item>
                )}
                {!isNew && (
                  <div>
                    <Form.Item label='New password' name='newPassword'>
                      <Input.Password placeholder='New password' />
                    </Form.Item>
                    <Form.Item label='Confirm password' name='confirmPassword'>
                      <Input.Password placeholder='Confirm password' />
                    </Form.Item>
                  </div>
                )}

                {loggedUser && (loggedUser.role.includes('SuperAdmin') || loggedUser.role.includes('admin')) && (
                  <>
                    <Form.Item
                      label='Role'
                      name='role'
                      rules={[
                        {
                          required: true,
                          message: 'Please select role!',
                        },
                      ]}
                    >
                      <Select mode='multiple' placeholder='Role' onChange={(value) => setRoleForClient(value)}>
                        <Select.Option value='admin'>Admin</Select.Option>
                        <Select.Option value='user'>Client</Select.Option>
                        <Select.Option value='SuperAdmin'>Super Admin</Select.Option>
                        <Select.Option value='employee'>Employee</Select.Option>
                        <Select.Option value='hrOfficer'>HR Officer</Select.Option>
                        <Select.Option value='eInvoiceOfficer'>E Invoice Officer</Select.Option>
                        <Select.Option value='userOfficer'>Client Officer</Select.Option>
                      </Select>
                    </Form.Item>
                    {roleForClient && roleForClient.length && roleForClient.includes('admin') ? (
                      <Form.Item
                        name='statuaryAuthorization'
                        valuePropName='checked'
                        label='Statuary authorization'
                        wrapperCol={{
                          span: 1,
                        }}
                      >
                        <Checkbox
                          onChange={(e) => setStatuaryAuthorizationChecked(e.target.checked)}
                          checked={statuaryAuthorizationChecked}
                        >
                          <Tooltip
                            title='Here you can specify if admin has statuary authorization.'
                            color='#b1b1b1'
                            placement='bottom'
                            autoAdjustOverflow={true}
                          >
                            <QuestionCircleOutlined style={{ marginTop: '5px', marginLeft: '3px', color: '#b1b1b1' }} />
                          </Tooltip>
                        </Checkbox>
                      </Form.Item>
                    ) : null}
                    {roleForClient &&
                    roleForClient.length &&
                    roleForClient.includes('admin') &&
                    statuaryAuthorizationChecked ? (
                      <>
                        <Form.Item
                          label='JMBG'
                          name='jmbg'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter JMBG!',
                            },
                          ]}
                        >
                          <Input placeholder='JMBG' />
                        </Form.Item>
                        <Form.Item
                          label='Legal ID'
                          name='legalId'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter legal ID!',
                            },
                          ]}
                        >
                          <Input placeholder='Legal ID' />
                        </Form.Item>
                      </>
                    ) : null}
                  </>
                )}

                {!isNew &&
                  loggedUser &&
                  (loggedUser.role.includes('SuperAdmin') || loggedUser.role.includes('admin')) && (
                    <>
                      <Form.Item
                        label='Status'
                        name='status'
                        rules={[
                          {
                            required: true,
                            message: 'Please select status!',
                          },
                        ]}
                      >
                        <Select>
                          <Select.Option value='ACTIVE'>ACTIVE</Select.Option>
                          <Select.Option value='SUSPENDED'>SUSPENDED</Select.Option>
                          <Select.Option value='DELETED'>DELETED</Select.Option>
                          <Select.Option value='WAITING_FOR_ACTIVATION'>WAITING_FOR_ACTIVATION</Select.Option>
                        </Select>
                      </Form.Item>
                      <EmailSettings
                        adminEmailsDrawer={adminEmailsDrawer}
                        setAdminEmailsDrawer={setAdminEmailsDrawer}
                      />
                      <AdminEmailsDrawer
                        onClose={onClose}
                        adminEmailsDrawer={adminEmailsDrawer}
                        timesheetSubmission={timesheetSubmission}
                        setTimesheetSubmission={setTimesheetSubmission}
                        leavesDecisions={leavesDecisions}
                        setLeavesDecisions={setLeavesDecisions}
                        terminationExtensionEmails={terminationExtensionEmails}
                        setTerminationExtensionEmails={setTerminationExtensionEmails}
                        workPermissionExpireEmails={workPermissionExpireEmails}
                        setWorkPermissionExpireEmails={setWorkPermissionExpireEmails}
                        paymentOrdersEmails={paymentOrdersEmails}
                        setPaymentOrdersEmails={setPaymentOrdersEmails}
                        newEmployeeEmails={newEmployeeEmails}
                        setNewEmployeeEmails={setNewEmployeeEmails}
                        officialTravelEmails={officialTravelEmails}
                        setOfficialTravelEmails={setOfficialTravelEmails}
                      />
                    </>
                  )}

                <Form.Item label='Photo' className='upload-wrapper right' name='avatar'>
                  <UploadBox
                    editHandler={avatarHandler}
                    deleteHandler={deleteAvatarHandler}
                    image={image}
                    index={0}
                    name='avatar'
                  />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
                    SUBMIT
                  </Button>
                </Form.Item>
              </Form>
            )}
            {result && (
              <Result
                title='Client created and verification mail is sent.'
                extra={
                  <Button
                    type='primary'
                    key='console'
                    onClick={() => {
                      setResult(false);
                      form.resetFields();
                    }}
                  >
                    Ok
                  </Button>
                }
              />
            )}
          </Card>

          {modal.visible && (
            <GalleryModal
              visible={modal.visible}
              limit={modal.limit}
              formKey={modal.formKey}
              imageSavePath='public/images/users/'
              imageType='data'
              imageHeight={200}
              SERVER_URL={SERVER_URL}
              token={token}
              form={form}
              onCancel={() => setModal({ ...modal, visible: false })}
              onInsert={(values) => onInsert(values, modal.formKey)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserForm;
