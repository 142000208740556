import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import Data from '../pages/data/Data';
import EditData from '../pages/data/EditData';
import ViewData from '../pages/data/ViewData';
import Company from '../pages/company/Company';
import EditCompany from '../pages/company/EditCompany';
import ViewCompany from '../pages/company/ViewCompany';
import Sectors from '../pages/sectors/Sectors';
import EditSector from '../pages/sectors/EditSector';
import ViewSector from '../pages/sectors/ViewSector';
import TerminateEmployee from '../pages/data/TerminateEmployee';
import ExtendEmployment from '../pages/data/ExtendEmployment';
import UnlockEmployees from '../pages/data/UnlockEmployees';
import HrDisplay from '../components/HrDisplay';
import HrEdit from '../components/HrEdit';
import HrPlatformEmployee from '../pages/HrPlatform/HrPlatformEmployee';
import Salary from '../pages/salary/Salary';
import Export from '../pages/export/Export';
import UploadEmployees from '../pages/data/UploadEmployees';
import SalaryUpload from '../pages/salary/SalaryUpload';
import {
  AddEmployeeIcon,
  AddSectorIcon,
  AllClientsIcon,
  AllEmployeesIcon,
  BookIcon,
  ClientLogo,
  CompaniesIcon,
  CompanyIcon,
  EmployeeIcon,
  ExcelIcon,
  ExportIcon,
  NewClientIcon,
  NewCompanyIcon,
  NewRequestIcon,
  SectorIcon,
  TimeSheetIcon,
  TimeSheetSubIcon,
  TimeSheetUploadIcon,
  UploadEmployeesIcon,
} from '../components/icons';

const routes = [
  {
    label: 'Clients',
    icon: <ClientLogo />,
    allowed: ['SuperAdmin', 'admin'],
    children: [
      {
        icon: <AllClientsIcon className='sub-menu__icon' />,
        label: 'All clients',
        path: '/admin/users',
        component: Users,
        allowed: ['SuperAdmin', 'admin'],
        showInMenu: true,
      },
      {
        icon: <NewClientIcon className='sub-menu__icon' />,
        label: 'New client',
        path: '/admin/new-user',
        component: EditUser,
        allowed: ['SuperAdmin', 'admin'],
        showInMenu: true,
      },
      {
        label: 'Edit client',
        path: '/admin/edit-user/:userId',
        component: EditUser,
        allowed: ['SuperAdmin', 'admin', 'employee', 'hrOfficer'],
        showInMenu: false,
      },
      {
        label: 'View client',
        path: '/admin/view-user/:id',
        component: ViewUser,
        allowed: ['SuperAdmin', 'admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Company',
    icon: <CompanyIcon />,
    allowed: ['SuperAdmin', 'admin', 'user', 'eInvoiceOfficer', 'hrOfficer', 'userOfficer'],
    children: [
      {
        icon: <CompaniesIcon className='sub-menu__icon' />,
        label: 'All companies',
        path: '/admin/companies',
        component: Company,
        allowed: ['SuperAdmin', 'admin', 'eInvoiceOfficer', 'hrOfficer', 'user', 'userOfficer'],
        showInMenu: true,
      },
      {
        icon: <NewCompanyIcon className='sub-menu__icon' />,
        label: 'New company',
        path: '/admin/new-company',
        component: EditCompany,
        allowed: ['SuperAdmin', 'admin'],
        showInMenu: true,
      },
      {
        label: 'Edit company',
        path: '/admin/edit-company/:companyId',
        component: EditCompany,
        allowed: ['SuperAdmin', 'admin'],
        showInMenu: false,
      },
      {
        label: 'View company',
        path: '/admin/view-company/:companyId',
        component: ViewCompany,
        allowed: ['SuperAdmin', 'admin', 'user', 'eInvoiceOfficer', 'hrOfficer', 'userOfficer'],
        showInMenu: false,
      },
      {
        icon: <SectorIcon className='sub-menu__icon' />,
        label: 'All sectors',
        path: '/admin/sectors',
        component: Sectors,
        allowed: ['SuperAdmin', 'admin', 'user', 'hrOfficer', 'userOfficer'],
        showInMenu: true,
      },
      {
        icon: <AddSectorIcon className='sub-menu__icon' />,
        label: 'New sector',
        path: '/admin/add-sectors',
        component: EditSector,
        allowed: ['SuperAdmin', 'admin', 'user', 'userOfficer'],
        showInMenu: true,
      },
      {
        label: 'Edit sector',
        path: '/admin/edit-sector/:companyId',
        component: EditSector,
        allowed: ['SuperAdmin', 'admin', 'user', 'userOfficer'],
        showInMenu: false,
      },
      {
        label: 'View sector',
        path: '/admin/view-sector/:companyId',
        component: ViewSector,
        allowed: ['SuperAdmin', 'admin', 'user', 'hrOfficer', 'userOfficer'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Employees',
    icon: <EmployeeIcon />,
    allowed: ['SuperAdmin', 'admin', 'user', 'hrOfficer', 'userOfficer'],
    children: [
      {
        icon: <AllEmployeesIcon className='sub-menu__icon' />,
        label: 'All employees',
        path: '/admin/data',
        component: Data,
        allowed: ['SuperAdmin', 'admin', 'user', 'hrOfficer', 'userOfficer'],
        showInMenu: true,
      },
      {
        icon: <AddEmployeeIcon className='sub-menu__icon' />,
        label: 'New employee',
        path: '/admin/new-data',
        component: EditData,
        allowed: ['SuperAdmin', 'admin', 'user', 'userOfficer'],
        showInMenu: true,
      },
      {
        label: 'Edit employee',
        path: '/admin/edit-data/:dataId',
        component: EditData,
        allowed: ['SuperAdmin', 'admin', 'user', 'hrOfficer', 'userOfficer'],
        showInMenu: false,
      },
      {
        label: 'View employee',
        path: '/admin/view-data/:dataId',
        component: ViewData,
        allowed: ['SuperAdmin', 'admin', 'user', 'hrOfficer', 'userOfficer'],
        showInMenu: false,
      },
      {
        label: 'Terminate employee',
        path: '/admin/terminate-employee',
        component: TerminateEmployee,
        allowed: ['SuperAdmin', 'admin', 'user', 'hrOfficer', 'userOfficer'],
        showInMenu: false,
      },
      {
        label: 'Extend employment',
        path: '/admin/extend-employment',
        component: ExtendEmployment,
        allowed: ['SuperAdmin', 'admin', 'user', 'hrOfficer', 'userOfficer'],
        showInMenu: false,
      },
      {
        label: 'Unlock',
        path: '/admin/unlock-employees',
        component: UnlockEmployees,
        allowed: ['SuperAdmin', 'admin'],
        showInMenu: false,
      },
      {
        label: 'Edit client',
        path: '/admin/edit-user/:userId',
        component: EditUser,
        allowed: ['SuperAdmin', 'admin', 'employee', 'user', 'userOfficer'],
        showInMenu: false,
      },
      {
        label: 'View request',
        path: '/admin/view-request/:requestId',
        component: HrDisplay,
        allowed: ['SuperAdmin', 'admin', 'user', 'employee', 'hrOfficer', 'userOfficer'],
        showInMenu: false,
      },
      {
        label: 'Edit request',
        path: '/admin/edit-request/:requestId',
        component: HrEdit,
        allowed: ['SuperAdmin', 'admin', 'user', 'employee', 'hrOfficer', 'userOfficer'],
        showInMenu: false,
      },
      {
        label: 'Upload employees',
        icon: <UploadEmployeesIcon className='sub-menu__icon' />,
        path: '/admin/upload-employees',
        component: UploadEmployees,
        allowed: ['SuperAdmin'],
        // allowed: ['SuperAdmin', 'admin', 'user', 'userOfficer'],
        showInMenu: false,
      },
    ],
  },

  // TODO: Check if this will ever be needed
  // {
  //   label: 'Sectors',
  //   icon: <TeamIcon />,

  //   allowed: ['hrOfficer'],
  //   children: [
  //     {
  //       icon: <SectorIcon className='sub-menu__icon' />,
  //       label: 'All sectors',
  //       path: '/admin/sectors',
  //       component: Sectors,
  //       allowed: ['hrOfficer'],
  //       showInMenu: true,
  //     },
  //   ],
  // },

  {
    label: 'Create request',
    icon: <BookIcon />,
    allowed: ['hrOfficer'],
    children: [
      {
        icon: <NewRequestIcon className='sub-menu__icon' />,
        label: 'Create request',
        path: '/admin/hr-platform-employee',
        component: HrPlatformEmployee,
        allowed: ['hrOfficer'],
        showInMenu: true,
      },
    ],
  },

  {
    label: 'Timesheet',
    icon: <TimeSheetIcon />,
    allowed: ['SuperAdmin', 'admin', 'user', 'userOfficer', 'hrOfficer'],
    children: [
      {
        icon: <TimeSheetSubIcon className='sub-menu__icon' />,
        label: 'Timesheet',
        path: '/admin/salary',
        component: Salary,
        allowed: ['SuperAdmin', 'admin', 'user', 'userOfficer', 'hrOfficer'],
        showInMenu: true,
      },
      {
        icon: <TimeSheetUploadIcon className='sub-menu__icon' />,
        label: 'Upload',
        path: '/admin/salary-upload',
        component: SalaryUpload,
        allowed: ['SuperAdmin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Export',

    icon: <ExportIcon />,

    allowed: ['SuperAdmin', 'admin'],
    children: [
      {
        icon: <ExcelIcon className='sub-menu__icon' />,
        label: 'Export to Excel',
        path: '/admin/export',
        component: Export,
        allowed: ['SuperAdmin', 'admin'],
        showInMenu: true,
      },
    ],
  },
];

export default routes;
