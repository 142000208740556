import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { notification, Button, Modal, Form } from 'antd';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/CompanyTable';
import CustomerInvoiceForm from '../../components/forms/CustomerInvoiceForm';
import { SERVER_URL } from '../../config';
import { formatEInvoiceDate } from '../../helpers/dates';

const TABLE_COLUMN_KEYS = [
  '_id',
  'userCompany',
  'postalAddress',
  'city',
  'zipCode',
  'vat',
  '__v',
  'phoneNumber',
  'companyNumber',
  'customerCode',
    'companyAdmins'
];

const Buyers = () => {
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const [allCompanies, setAllCompanies] = useAxios('', null, currentuser.data.token, 'get');
  const [customers, fetchCustomers] = useAxios('', [], currentuser.data.token, 'get');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const history = useHistory();

  const selectedEInvoiceCompanyId = (() => {
    const storedCompanyId = localStorage.getItem('selectedEInvoiceCompany');
    const companies = Array.isArray(currentuser?.data?.company) ? currentuser.data.company : [currentuser?.data?.company].filter(Boolean);
    const companyIds = companies.length > 0 ? companies.map(item => item._id) : [];

    return companyIds.includes(storedCompanyId)
        ? storedCompanyId
        : companies.length > 0 ? companies[0]?._id : null;
  })();

  useEffect(() => {
      if (selectedEInvoiceCompanyId) {
        setAllCompanies(`${SERVER_URL}/eCompanies`, []);
      } else {
        notification.error({
          message: 'Please set all required data before use of E-Invoices platform',
          placement: 'bottomRight',
        });
        setTimeout(() => {
          window.location.href = '/admin';
        }, 1200);
      }
  }, []);

  const getData = (data) => {
    setModalData(data);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteCustomer = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/customer/${id}?filter=${JSON.stringify(selectedEInvoiceCompanyId)}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'You have successfully deleted the customer.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        history.go(0);
      }, 1200);
    } catch (error) {
      notification.error({
        message: 'Problem with deleting the customer. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    if (currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin') || currentuser.data.role.includes('eInvoiceOfficer')) {
      fetchCustomers(`${SERVER_URL}/customer?filter=${JSON.stringify(selectedEInvoiceCompanyId)}`, []);
    } else if (currentuser.data.role.includes('user')) {
      if (customers.data.length === 0) {
        fetchCustomers(`${SERVER_URL}/customer?filter=${JSON.stringify(selectedEInvoiceCompanyId)}`, []);
      }
    }
  }, [fetchCustomers, selectedEInvoiceCompanyId, currentuser.data.role, customers]);

  let columnKeys;
  let newColumnKeys;
  if (customers?.data && customers?.data?.length > 0) {
    const keys = Object.keys(customers?.data[0]);
    columnKeys = keys?.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    let alternativeNames = [];
    for (const columnKey of columnKeys) {
      if (columnKey === 'customerType') {
        alternativeNames.push('Customer Type');
      } else if (columnKey === 'customerNumber') {
        alternativeNames.push('Customer Number');
      } else if (columnKey === 'customerName') {
        alternativeNames.push('Customer Name');
      } else if (columnKey === 'iban') {
        alternativeNames.push('IBAN');
      } else if (columnKey === 'email') {
        alternativeNames.push('Email');
      } else if (columnKey === 'contactPerson') {
        alternativeNames.push('Contact person');
      } else if (columnKey === 'country') {
        alternativeNames.push('Country');
      } else if (columnKey === 'createdAt') {
        alternativeNames.push('Created At');
      } else if (columnKey === 'updatedAt') {
        alternativeNames.push('Updated At');
      }
    }
    let keyArray = columnKeys;
    newColumnKeys = columnKeys?.map((item, index) => ({
      originalName: keyArray[index],
      alternativeName: alternativeNames[index],
    }));
  }

  let tableData = [];
  if (customers?.data && customers?.data?.length > 0) {
    tableData = customers?.data.map((item) => {
      const createdAt = formatEInvoiceDate(item.createdAt);
      const updatedAt = formatEInvoiceDate(item.updatedAt);
      const email = item.email.length > 1 ? `${item.email[0]}...` : item.email[0];
      return { ...item, createdAt: createdAt, updatedAt: updatedAt, email };
    });
  }

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Button
          type='primary'
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          Add new customer
        </Button>
      </div>
      <Modal
          title='Add new customer'
          width={1000}
          visible={isModalOpen}
          onOk={form.submit}
          onCancel={handleCancel}
          style={{ top: 20 }}
      >
        <div></div>
        <CustomerInvoiceForm
            customerForm={form}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            SERVER_URL={SERVER_URL}
            allCompanies={allCompanies}
        />
      </Modal>
      <Table
        data={tableData}
        columnKeys={newColumnKeys}
        editPath='/admin/customer/'
        getData={getData}
        deleteHandler={deleteCustomer}
        type={'buyers'}
      />
    </div>
  );
};

export default Buyers;
