import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../App';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../config';
import ProtectedRoute from './ProtectedRoute';
import Home from '../../pages/Home';
import Guidelines from '../../pages/guidelines/Guidelines';
import Questionnaire from '../../pages/questionnaires/Questionnaire';
import SalaryCalculator from '../../pages/calculator/SalaryCalculator';
import EditGuideline from '../../pages/guidelines/EditGuideline';
import GuidelineCategory from '../../pages/guidelineCategory/GuidelineCategory';
import EditGuidelineCategory from '../../pages/guidelineCategory/EditGuidelineCategory';
import HrPlatformEmployee from '../../pages/HrPlatform/HrPlatformEmployee';
import AllRequests from '../../pages/HrPlatform/AllRequests';
import AllHrRequestsByHrOfficer from '../../pages/HrPlatform/AllRequestsByHrOfficer';
import EditUser from '../../pages/users/EditUser';
import HrDisplay from '../HrDisplay';
import HrEdit from '../HrEdit';
import ResetPassword from '../../pages/ResetPassword';
import CreateNewInvoice from '../../pages/e-invoices/CreateNewInvoice';
import OutgoingInvoices from '../../pages/e-invoices/OutgoingInvoices';
import IncomingInvoices from '../../pages/e-invoices/IncomingInvoices';
import Buyers from '../../pages/e-invoices/Buyers';
import EditBuyer from '../../pages/e-invoices/EditBuyer';
import InvoiceDetail from '../../pages/e-invoices/InvoiceDetail';
import Archive from '../../pages/e-invoices/Archive';
import EditInvoice from '../../pages/e-invoices/EditInvoice';
import Products from '../../pages/products/Products';
import EditProduct from '../../pages/products/EditProduct';
import UploadProducts from '../../pages/products/UploadProducts';

const Routes = () => {
  const user = useContext(UserContext);
  const [canSeeEInvoicesMenu, setCanSeeEInvoicesMenu] = useState(true);
  let filteredRoutes = ROUTES.filter(
    (route) => user.data && user.data.role && route.allowed.some((role) => user.data.role.includes(role)),
  );
  filteredRoutes.map((item) => {
    if (!user.data.role.includes('admin')) {
      item.path = item.children[0]?.path;
      item.component = item.children[0]?.component;
      for (const child of item.children) {
        child.showInMenu = false;
      }
    }
    return item;
  });

  // checking if company has EInvoices Officer in which case 'user' role can't access e-invoices routes
  // REVISION March 2024 --> this is set again to be like opposite to above
  useEffect(() => {
    if (
      (user?.data?.role?.includes('user') && !user?.data?.company?.eInvoiceOfficer) ||
      (!user?.data?.role?.includes('user') &&
        user?.data?.company?.eInvoiceOfficer &&
        user?.data?.id === user?.data?.company?.eInvoiceOfficer) ||
      //** this allows User to have access at the same time as eInvoice Officer
      (user?.data?.role?.includes('user') &&
        user?.data?.company?.eInvoiceOfficer &&
        user?.data?.id !== user?.data?.company?.eInvoiceOfficer) ||
      //**
      (user?.data?.role?.includes('user') && user?.data?.role?.includes('eInvoiceOfficer'))
    ) {
      setCanSeeEInvoicesMenu(true);
    }
    if (!user?.data?.role?.includes('user') && !user?.data?.role?.includes('eInvoiceOfficer')) {
      setCanSeeEInvoicesMenu(false);
    }
  }, [user]);

  return (
    <Switch>
      <Route exact path='/password-reset/:token' component={ResetPassword} />
      <ProtectedRoute user={user.data} exact path='/admin' component={Home} allowed={[]} />
      <ProtectedRoute
        user={user.data}
        exact
        path='/admin/hr-platform-employee'
        component={HrPlatformEmployee}
        allowed={[]}
      />
      {(user?.data?.role?.includes('SuperAdmin') ||
        user?.data?.role?.includes('admin') ||
        user?.data?.role?.includes('user') ||
        user?.data?.role?.includes('employee') ||
        user?.data?.role?.includes('userOfficer') ||
        user?.data?.role?.includes('hrOfficer')) && (
        <ProtectedRoute
          user={user.data}
          exact
          path='/admin/hr-platform-requests'
          component={AllRequests}
          allowed={[]}
        />
      )}
      {user?.data?.role?.includes('hrOfficer') && user?.data?.role?.includes('employee') && (
        <ProtectedRoute
          user={user.data}
          exact
          path='/admin/hr-officer-requests'
          component={AllHrRequestsByHrOfficer}
          allowed={[]}
        />
      )}
      {(user?.data?.role?.includes('SuperAdmin') ||
        user?.data?.role?.includes('admin') ||
        user?.data?.role?.includes('user') ||
        user?.data?.role?.includes('employee') ||
        user?.data?.role?.includes('userOfficer') ||
        user?.data?.role?.includes('hrOfficer')) && (
        <ProtectedRoute
          user={user.data}
          exact
          path='/admin/view-request/:requestId'
          component={HrDisplay}
          allowed={[]}
        />
      )}
      {(user?.data?.role?.includes('SuperAdmin') || canSeeEInvoicesMenu) && (
        <ProtectedRoute
          user={user.data}
          exact
          path='/admin/create-new-invoice'
          component={CreateNewInvoice}
          allowed={[]}
        />
      )}

      {(user?.data?.role?.includes('SuperAdmin') || canSeeEInvoicesMenu) && (
        <ProtectedRoute user={user.data} exact path='/admin/edit-invoice/:_id' component={EditInvoice} allowed={[]} />
      )}

      {(user?.data?.role?.includes('SuperAdmin') || canSeeEInvoicesMenu) && (
        <ProtectedRoute
          user={user.data}
          exact
          path='/admin/accounts-receivable'
          component={OutgoingInvoices}
          allowed={[]}
        />
      )}

      {(user?.data?.role?.includes('SuperAdmin') || canSeeEInvoicesMenu) && (
        <ProtectedRoute
          user={user.data}
          exact
          path='/admin/accounts-payable'
          component={IncomingInvoices}
          allowed={[]}
        />
      )}

      {(user?.data?.role?.includes('SuperAdmin') || canSeeEInvoicesMenu) && (
        <ProtectedRoute user={user.data} exact path='/admin/customers' component={Buyers} allowed={[]} />
      )}

      {(user?.data?.role?.includes('SuperAdmin') || canSeeEInvoicesMenu) && (
        <ProtectedRoute user={user.data} exact path='/admin/customer/:customerId' component={EditBuyer} allowed={[]} />
      )}

      {(user?.data?.role?.includes('SuperAdmin') || canSeeEInvoicesMenu) && (
        <ProtectedRoute
          user={user.data}
          exact
          path='/admin/purchase-invoice-detail/:_id'
          component={InvoiceDetail}
          allowed={[]}
        />
      )}

      {(user?.data?.role?.includes('SuperAdmin') || canSeeEInvoicesMenu) && (
        <ProtectedRoute
          user={user.data}
          exact
          path='/admin/sales-invoice-detail/:_id'
          component={InvoiceDetail}
          allowed={[]}
        />
      )}

      {(user?.data?.role?.includes('SuperAdmin') || canSeeEInvoicesMenu) && (
        <ProtectedRoute user={user.data} exact path='/admin/products' component={Products} allowed={[]} />
      )}

      {(user?.data?.role?.includes('SuperAdmin') || canSeeEInvoicesMenu) && (
        <ProtectedRoute user={user.data} exact path='/admin/new-product' component={EditProduct} allowed={[]} />
      )}

      {(user?.data?.role?.includes('SuperAdmin') || canSeeEInvoicesMenu) && (
        <ProtectedRoute
          user={user.data}
          exact
          path='/admin/edit-product/:productId'
          component={EditProduct}
          allowed={[]}
        />
      )}

      {(user?.data?.role?.includes('SuperAdmin') || canSeeEInvoicesMenu) && (
        <ProtectedRoute
          user={user.data}
          exact
          path='/admin/view-product/:productId'
          component={EditProduct}
          allowed={[]}
        />
      )}

      {(user?.data?.role?.includes('SuperAdmin') || canSeeEInvoicesMenu) && (
        <ProtectedRoute user={user.data} exact path='/admin/upload-products' component={UploadProducts} allowed={[]} />
      )}

      {(user?.data?.role?.includes('SuperAdmin') ||
        user?.data?.role?.includes('user') ||
        user?.data?.role?.includes('eInvoiceOfficer')) && (
        <ProtectedRoute user={user.data} exact path='/admin/archive' component={Archive} allowed={[]} />
      )}

      {(user?.data?.role?.includes('SuperAdmin') ||
        user?.data?.role?.includes('admin') ||
        user?.data?.role?.includes('user') ||
        user?.data?.role?.includes('employee') ||
        user?.data?.role?.includes('userOfficer') ||
        user?.data?.role?.includes('hrOfficer')) && (
        <ProtectedRoute user={user.data} exact path='/admin/edit-request/:requestId' component={HrEdit} allowed={[]} />
      )}
      {(user?.data?.role?.includes('SuperAdmin') ||
        user?.data?.role?.includes('admin') ||
        user?.data?.role?.includes('user') ||
        user?.data?.role?.includes('eInvoiceOfficer') ||
        user?.data?.role?.includes('userOfficer') ||
        user?.data?.role?.includes('hrOfficer')) && (
        <ProtectedRoute
          user={user.data}
          exact
          path='/admin/salary-calculator'
          component={SalaryCalculator}
          allowed={[]}
        />
      )}

      <ProtectedRoute user={user.data} exact path='/admin/guidelines' component={Guidelines} allowed={[]} />
      <ProtectedRoute user={user.data} exact path='/admin/questionnaire' component={Questionnaire} allowed={[]} />
      {!user?.data?.role?.includes('admin') && (
        <ProtectedRoute user={user.data} exact path='/admin/edit-user/:userId' component={EditUser} allowed={[]} />
      )}

      <ProtectedRoute
        user={user.data}
        exact
        path='/admin/new-guideline'
        component={EditGuideline}
        allowed={['admin']}
      />
      <ProtectedRoute
        user={user.data}
        exact
        path='/admin/edit-guideline/:guidelineId'
        component={EditGuideline}
        allowed={['admin']}
      />
      <ProtectedRoute
        user={user.data}
        exact
        path='/admin/guideline-categories'
        component={GuidelineCategory}
        allowed={['admin']}
      />
      <ProtectedRoute
        user={user.data}
        exact
        path='/admin/new-category'
        component={EditGuidelineCategory}
        allowed={['admin']}
      />
      <ProtectedRoute
        user={user.data}
        exact
        path='/admin/edit-category/:id'
        component={EditGuidelineCategory}
        allowed={['admin']}
      />
      {/* routes from routeConfig file */}
      {filteredRoutes.map((route) =>
        route.children.map((item, i) => (
          <ProtectedRoute
            exact
            user={user.data}
            key={`R_${i}`}
            path={item.path}
            component={item.component}
            allowed={[]}
            showInMenu={
              (item.showInMenu = !!(
                user.data &&
                !item.label.includes('Edit') &&
                !item.label.includes('View') &&
                !item.label.includes('Terminate') &&
                !item.label.includes('Extend') &&
                !item.label.includes('Unlock') &&
                item.allowed.some((role) => user.data.role.includes(role))
              ))
            }
          />
        )),
      )}
    </Switch>
  );
};

export default Routes;
