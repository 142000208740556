import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Select, Result } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';

const tailLayout = {
  wrapperCol: { span: 24, style: { textAlign: 'right', marginTop: '20px' } },
};

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const initialValues = {
  code: '',
  title: '',
  description: '',
  unit: 'pcs',
    tax: '',
  priceEUR: '',
  priceRSD: '',
};

const requiredFields = ['title', 'description', 'unit', 'priceEUR', 'tax'];

const ProductForm = ({ data, updateHandler, createHandler, result, setResult }) => {
  const [form] = Form.useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const history = useHistory();
  const location = useLocation();

  const isViewMode = location.pathname.includes('/admin/view-product/');

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
      });

      const requiredFieldsFilled = requiredFields.every((field) => {
        const value = data[field];
        return value !== undefined && value !== '' && value !== null;
      });

      setIsButtonDisabled(!requiredFieldsFilled);
    }
  }, [data, form]);

  const isNew = !data;

  const onValuesChange = (_, allValues) => {
    const requiredFieldsFilled = requiredFields.every((field) => {
      const value = allValues[field];
      return value !== undefined && value !== '' && value !== null;
    });

    setIsButtonDisabled(!requiredFieldsFilled);
  };

  const onFinish = (values) => {
    if (isNew) {
      const created = createHandler(values);
      if (created) {
        form.resetFields();
      }
    } else {
      updateHandler(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper'>
        <Card title={`${isNew ? 'NEW PRODUCT' : "PRODUCT'S DETAILS"}`} bordered={false}>
          {!result && (
            <Form
              {...layout}
              name='basic'
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout='horizontal'
              form={form}
              initialValues={initialValues}
              onValuesChange={onValuesChange}
              labelAlign='left'
            >
              <Form.Item label='Title' name='title' rules={[{ required: true, message: 'Please enter title!' }]}>
                <Input placeholder='Title' disabled={isViewMode} />
              </Form.Item>

              <Form.Item
                label='Description'
                name='description'
                rules={[{ required: true, message: 'Please enter description!' }]}
              >
                <Input placeholder='Description' disabled={isViewMode} />
              </Form.Item>

              <Form.Item label='Unit' name='unit' rules={[{ required: true, message: 'Please select unit!' }]}>
                <Select
                  placeholder='Select unit'
                  defaultValue='pcs'
                  options={[
                    { value: 'pc', label: 'pc' },
                    { value: 'kwh', label: 'kwh' },
                    { value: 'kg', label: 'kg' },
                    { value: 'km', label: 'km' },
                    { value: 'g', label: 'g' },
                    { value: 'm', label: 'm' },
                    { value: 'l', label: 'l' },
                    { value: 't', label: 't' },
                    { value: 'm2', label: 'm2' },
                    { value: 'm3', label: 'm3' },
                    { value: 'min', label: 'min' },
                    { value: 'h', label: 'h' },
                    { value: 'd', label: 'd' },
                    { value: 'M', label: 'M' },
                    { value: 'y', label: 'y' },
                  ]}
                  disabled={isViewMode}
                />
              </Form.Item>

                <Form.Item label='Tax %' name='tax' rules={[{ required: true, message: 'Please enter tax %!' }]}>
                    <Input placeholder='Tax %' disabled={isViewMode} />
                </Form.Item>

              <Form.Item label='Price EUR' name='priceEUR' rules={[{ required: true, message: 'Please enter price!' }]}>
                <Input placeholder='Price EUR' disabled={isViewMode} />
              </Form.Item>

              <Form.Item label='Price RSD' name='priceRSD'>
                <Input placeholder='Price RSD' disabled={isViewMode} />
              </Form.Item>

              {!isViewMode && (
                <Form.Item {...tailLayout}>
                  <Button type='primary' htmlType='submit' disabled={isButtonDisabled}>
                    {isNew ? 'Create Product' : 'Update Product'}
                  </Button>
                </Form.Item>
              )}
            </Form>
          )}
          {result && (
            <Result
              title='Product created.'
              extra={
                <Button
                  type='primary'
                  key='console'
                  onClick={() => {
                    setResult(false);
                    form.resetFields();
                  }}
                >
                  Ok
                </Button>
              }
            />
          )}
          {isViewMode && (
            <Form.Item {...tailLayout}>
              <Button type='primary' onClick={() => history.push('/admin/products')}>
                BACK
              </Button>
            </Form.Item>
          )}
        </Card>
      </div>
    </div>
  );
};

export default ProductForm;
