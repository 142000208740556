import React, { useState, useEffect } from 'react';
import { Button, Drawer, Input, Form, DatePicker, List, Typography, Space, Select, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { scrollToTop } from '../helpers';

const initialValues = {
  type: 'owner',
  ownerName: '',
  ownerDateOfBirth: '',
  ownerPlaceOfBirth: '',
  ownerCountryOfBirth: '',
  ownerPlaceAndCountryOfResidence: '',
  ownerJMBG: '',
  ownerTypeNumberAndIssuerOfDocument: '',
  ownerPlaceAndDateOfIdDocIssue: '',
  ownerPercentageOfOwnership: '',
};

const CompanyOwnershipDrawer = ({ data, onClose, open, onSubmit }) => {
  const [drawerForm] = Form.useForm();
  const [owners, setOwners] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    if (data && data.length > 0) {
      setOwners(data);
    }
  }, [data]);

  const handleSubmit = () => {
    drawerForm
      .validateFields()
      .then((values) => {
        const formattedValues = {
          ...values,
          ownerDateOfBirth: values.ownerDateOfBirth ? values.ownerDateOfBirth.format('DD/MM/YYYY') : '',
        };

        if (editingIndex !== null) {
          const updatedOwners = [...owners];
          updatedOwners[editingIndex] = formattedValues;
          setOwners(updatedOwners);
          setEditingIndex(null);
        } else {
          setOwners([...owners, formattedValues]);
        }

        drawerForm.resetFields();
        drawerForm.setFieldsValue({ type: 'owner' });
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });

    // Reset scroll postion after submit
    scrollToTop(".company-owner-details .ant-drawer-body");
  };

  const handleEdit = (index) => {
    const ownerToEdit = owners[index];
    drawerForm.setFieldsValue({
      ...ownerToEdit,
      ownerDateOfBirth: ownerToEdit.ownerDateOfBirth ? moment(ownerToEdit.ownerDateOfBirth, 'DD/MM/YYYY') : null,
    });
    setEditingIndex(index);
  };

  const handleDelete = (index) => {
    const updatedOwners = owners.filter((_, i) => i !== index);
    setOwners(updatedOwners);

    if (editingIndex === index) {
      drawerForm.resetFields();
      setEditingIndex(null);
    }
  };

  const handleCancelEdit = () => {
    drawerForm.resetFields();
    setEditingIndex(null);
    // Reset scroll postion after cancel edit
    scrollToTop(".company-owner-details .ant-drawer-body");
  };

  const isFounderDisabled = owners.some((owner) => owner.type === 'founder');

  return (
    <Drawer
      title={<span style={{ fontWeight: 'bold' }}>Company Owner Details</span>}
      width={520}
      closable={false}
      onClose={onClose}
      open={open}
      afterOpenChange={(visible) => {
        if (visible) {
          document.body
            .querySelector(".company-owner-details .ant-drawer-body")
            .scrollTo(0, 0);
        }
      }}
      className="company-owner-details"
    >
      <div style={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
        <p style={{ marginBottom: '10px' }}>Here you can set owner's personal details</p>

        <List
          bordered
          dataSource={owners.sort((a, b) => (a.type === 'founder' ? -1 : 1))}
          renderItem={(item, index) => (
            <List.Item
              actions={[
                <Typography.Text>{item.type}</Typography.Text>,
                <EditOutlined key='edit' onClick={() => handleEdit(index)} />,
                <DeleteOutlined key='delete' onClick={() => handleDelete(index)} />,
              ]}
            >
              <Tooltip title={item.ownerName}>
                <Typography.Text ellipsis>{item.ownerName}</Typography.Text>
              </Tooltip>
            </List.Item>
          )}
        />
        <Form form={drawerForm} layout='vertical' initialValues={initialValues} style={{ marginTop: '20px' }}>
          <Form.Item name='type' 
            className='custom-label'
            label={
            <div>
              <span>Type:</span>

              <Tooltip
                title='You can create only one Founder!'
                color='#b1b1b1'
                placement='bottom'
                autoAdjustOverflow={true}
                >
                <QuestionCircleOutlined
                    style={{
                      marginLeft: '8px',
                      color: '#b1b1b1',
                      cursor: 'pointer',
                    }}
                />
              </Tooltip>
            </div>
            } 
          >
            <Select>
              <Select.Option value='founder' disabled={isFounderDisabled}>
                Founder
              </Select.Option>
              <Select.Option value='owner'>Owner</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='ownerName' label='Full name and surname:' className='custom-label'>
            <Input />
          </Form.Item>
          <Form.Item name='ownerDateOfBirth' label='Date of birth:' className='custom-label'>
            <DatePicker
              format='DD/MM/YYYY'
              placeholder='DD/MM/YYYY'
              style={{ width: '100%' }}
              defaultPickerValue={moment('1980', 'YYYY')}
            />
          </Form.Item>
          <Form.Item name='ownerPlaceOfBirth' label='Place of birth:' className='custom-label'>
            <Input />
          </Form.Item>
          <Form.Item name='ownerCountryOfBirth' label='Country of birth:' className='custom-label'>
            <Input />
          </Form.Item>
          <Form.Item
            name='ownerPlaceAndCountryOfResidence'
            label='Address, place and country of residence:'
            className='custom-label'
          >
            <Input />
          </Form.Item>
          <Form.Item name='ownerJMBG' label='JMBG or other ID number:' className='custom-label'>
            <Input />
          </Form.Item>
          <Form.Item
            name='ownerTypeNumberAndIssuerOfDocument'
            label='Type, number, and issuer of the personal identification:'
            className='custom-label'
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='ownerPlaceAndDateOfIdDocIssue'
            label='Place and date of issue of the personal identification document:'
            className='custom-label'
          >
            <Input />
          </Form.Item>
          <Form.Item name='ownerPercentageOfOwnership' label='Percentage of ownership:' className='custom-label'>
            <Input />
          </Form.Item>
          <Form.Item>
            <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {editingIndex !== null && (
                <Button onClick={handleCancelEdit} style={{ marginRight: '8px' }}>
                  Cancel
                </Button>
              )}
              <Button type='primary' onClick={handleSubmit}>
                {editingIndex !== null ? 'Update' : 'Add'}
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <Button
          type='primary'
          block
          style={{ marginTop: '20px' }}
          onClick={() => onSubmit(owners)}
          disabled={owners.length === 0}
        >
          Submit All Owners
        </Button>
      </div>
    </Drawer>
  );
};

export default CompanyOwnershipDrawer;
