import React, { useMemo, useState } from 'react';
import { Button, Drawer, Form, Input, notification, Table, Select, Popconfirm } from 'antd';
import {
  createOfficialTravel,
  updateOfficialTravelById,
  deleteOfficialTravelInvoiceById,
} from '../../../../services/official-travel';
import { formatLocalNumber } from '../../../../helpers/dates'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import UploadImages from '../../../upload-image/UploadImages';
import { hasRequestPermission, useLocalTranslations } from '../../../../utils';
import { useHistory, useLocation } from 'react-router-dom';
import StepControl from '../StepControl';
import ImagesList from '../../../upload-image/ImagesList';

export default function TravelInvoices({
  viewMode,
  formInit,
  token,
  employeeId,
  companyId,
  handleRefetchRequest,
  stepLang,
  currentStep,
  onChangeStep,
}) {
  const [form] = Form.useForm();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);
  const isNew = !formInit;
  const disabled = !!viewMode;
  const { lt } = useLocalTranslations(stepLang);

  const history = useHistory();
  const { pathname } = useLocation();
  const hasViewDisabled = hasRequestPermission(pathname, 'view');

  function handleBack() {
    history.goBack();
  }

  const chosenCurrency = formInit?.travelOrder?.dailyWageForAbroadCurrency || 'RSD';
  const uniqueCurrencyOptions = ['RSD', chosenCurrency].filter(Boolean);
  const currencyOptions = [...new Set(uniqueCurrencyOptions)];

  const invoiceTypeOptions = useMemo(() => {
    if (stepLang === 'rs') {
      return [
        { label: 'Smeštaj', value: 'Accommodation' },
        { label: 'Prevoz', value: 'Transportation' },
        { label: 'Ostalo', value: 'Other' },
      ];
    }

    return [
      { label: 'Accommodation', value: 'Accommodation' },
      { label: 'Transportation', value: 'Transportation' },
      { label: 'Other', value: 'Other' },
    ];
  }, [stepLang]);

  const cardOptions = [
    { label: lt('travelGlobal.isTravelInvoicePaidByCardYes'), value: true },
    { label: lt('travelGlobal.isTravelInvoicePaidByCardNo'), value: false },
  ];

  let columns = [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'index',
      render: (text, record) => (record.isSummary ? '' : text),
    },
    {
      title: 'Invoice Number',
      dataIndex: 'travelInvoiceNumber',
      key: 'travelInvoiceNumber',
    },
    {
      title: 'Invoice Type',
      dataIndex: 'travelInvoiceType',
      key: 'travelInvoiceType',
      render: (row) => {
        const key = row?.toLowerCase();
        const invoiceTypeKey = `travelGlobal.${key}`;
        return lt(invoiceTypeKey);
      } 
    },
    {
      title: 'Description',
      dataIndex: 'travelInvoiceDescription',
      key: 'travelInvoiceDescription',
    },
    {
      title: 'Amount',
      dataIndex: 'travelInvoiceAmount',
      key: 'travelInvoiceAmount',
      render: (amount) => formatLocalNumber(amount),
    },
    {
      title: 'Currency',
      dataIndex: 'travelInvoiceCurrency',
      key: 'travelInvoiceCurrency',
    },
    {
      title: 'Paid by Card',
      dataIndex: 'isTravelInvoicePaidByCard',
      key: 'isTravelInvoicePaidByCard',
      render: (text) => (text ? lt('travelGlobal.isTravelInvoicePaidByCardYes') : lt('travelGlobal.isTravelInvoicePaidByCardNo')),
    },
  ];

  if (!hasViewDisabled) {
     const actionColumns = {
      title: 'Actions',
      key: 'actions',
      render: (text, record) =>
        !record.isSummary && (
          <div className='table-actions' style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <button className='action-button' type='button' onClick={() => openEditDrawer(record)}>
                <EditOutlined title={'Edit invoice'} style={{ textDecoration: 'none', color: 'black' }} />
              </button>
            </div>

            <div style={{ margin: '2px', padding: '4px' }}>
              <Popconfirm
                disabled={disabled}
                placement='left'
                title={'This will delete the invoice. Do you want to delete the invoice?'}
                onConfirm={() => {
                  deleteHandler(record._id);
                }}
                okText='Ok'
                cancelText='Cancel'
              >
                <button className='action-button' type='button'>
                  <DeleteOutlined title={'Delete invoice'} />
                </button>
              </Popconfirm>
            </div>
          </div>
        ),
    }

    columns.push(actionColumns);
  }

  // prepare data
  const processDataSource = () => {
    const sortedData = formInit?.travelInvoices?.sort((a, b) => {
      const order = ['Accommodation', 'Transportation', 'Other'];
      return order.indexOf(a.travelInvoiceType) - order.indexOf(b.travelInvoiceType);
    });

    const groupedData = sortedData.reduce((acc, invoice) => {
      if (!acc[invoice.travelInvoiceCurrency]) {
        acc[invoice.travelInvoiceCurrency] = [];
      }
      acc[invoice.travelInvoiceCurrency].push(invoice);
      return acc;
    }, {});

    const summaryData = {};
    for (const [currency, invoices] of Object.entries(groupedData)) {
      summaryData[currency] = invoices.reduce((acc, invoice) => {
        if (!acc[invoice.travelInvoiceType]) {
          acc[invoice.travelInvoiceType] = 0;
        }
        acc[invoice.travelInvoiceType] += parseFloat(invoice.travelInvoiceAmount || 0);
        return acc;
      }, {});
    }

    const result = [];
    let index = 1;

    for (const [currency, invoices] of Object.entries(groupedData)) {
      let typeOrder = ['Accommodation', 'Transportation', 'Other'];

      typeOrder.forEach((type) => {
        invoices
          .filter((invoice) => invoice.travelInvoiceType === type)
          .forEach((invoice) => {
            result.push({ ...invoice, index: index++ });
          });

        if (summaryData[currency][type] !== undefined) {
          result.push({
            key: `summary-${currency}-${type}`,
            travelInvoiceNumber: lt('travelGlobal.costSummary'),
            travelInvoiceType: `${type}`,
            travelInvoiceDescription: '',
            travelInvoiceAmount: summaryData[currency][type],
            travelInvoiceCurrency: currency,
            isTravelInvoicePaidByCard: '',
            index: '',
            isSummary: true,
          });
        }
      });
    }

    return result;
  };

  const dataSource = processDataSource();
  const currencies = useMemo(() => {
    return [...new Set(formInit?.travelInvoices?.map((invoice) => invoice.travelInvoiceCurrency))];
  }, [formInit?.travelInvoices]);

  const dataByCurrency = currencies.reduce((acc, currency) => {
    acc[currency] = dataSource.filter((item) => item.travelInvoiceCurrency === currency);
    return acc;
  }, {});

  // style for summary rows
  const rowClassName = (record) => {
    if (record.isSummary) {
      return 'highlight-row-color';
    }
    return '';
  };

  const initialValues = {
    travelInvoices: {
      travelInvoiceCurrency: 'RSD',
      travelInvoiceNumber: '',
      travelInvoiceType: 'Accommodation',
      travelInvoiceDescription: '',
      travelInvoiceAmount: '',
      isTravelInvoicePaidByCard: false,
    },
  };

  const onSubmit = (values) => {
    values.travelInvoices._id = invoiceId;
    let payload = {
      ...values,
      employee: employeeId,
      company: companyId,
    };

    if (isNew) {
      createOfficialTravel(token, payload)
        .then(() => {
          notification.success({
            message: 'The data has been saved!',
            placement: 'bottomRight',
          });
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: 'Problem with saving data. Contact administrator.',
            placement: 'bottomRight',
          });
        });
    }

    if (!isNew) {
      updateOfficialTravelById(token, payload, formInit._id)
        .then(() => {
          form.resetFields();
          setDrawerOpen(true);
          handleRefetchRequest && handleRefetchRequest();

          notification.success({
            message: 'The data has been saved!',
            placement: 'bottomRight',
          });
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: 'Problem with saving data. Contact administrator.',
            placement: 'bottomRight',
          });
        })
        .finally(() => {
          setDrawerOpen(false);
          handleRefetchRequest && handleRefetchRequest();
        })
    }
  };

  const openEditDrawer = (invoice) => {
    form.setFieldsValue({
      travelInvoices: {
        travelInvoiceCurrency: invoice.travelInvoiceCurrency,
        travelInvoiceNumber: invoice.travelInvoiceNumber,
        travelInvoiceType: invoice.travelInvoiceType,
        travelInvoiceDescription: invoice.travelInvoiceDescription,
        travelInvoiceAmount: invoice.travelInvoiceAmount,
        isTravelInvoicePaidByCard: invoice.isTravelInvoicePaidByCard,
      },
    });
    setInvoiceId(invoice._id);
    setDrawerOpen(true);
  };

  const deleteHandler = async (id) => {
    try {
      await deleteOfficialTravelInvoiceById(token, id);
      handleRefetchRequest && handleRefetchRequest();
      notification.success({
        message: 'The invoice has been deleted.',
        placement: 'bottomRight',
      });
    } catch (err) {
      console.error('error in delete official travel invoice', err);
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const onClose = () => {
    setDrawerOpen(false);
    setInvoiceId(null);
    form.setFieldsValue({
      travelInvoices: {
        travelInvoiceCurrency: 'RSD',
        travelInvoiceNumber: '',
        travelInvoiceType: 'Accommodation',
        travelInvoiceDescription: '',
        travelInvoiceAmount: '',
        isTravelInvoicePaidByCard: false,
      },
    });
  };

  const filteredCurrencies = useMemo(() => {
    return currencies.filter((curr) => curr !== 'RSD');
  }, [currencies]);

  return (
    <>
      <div className='travel-invoices'>
        <div className='step-header'>
          <h3 className='block-title'>{lt("travelInvoices.stepTitle")}</h3>

          {!hasViewDisabled && (
            <Button onClick={() => setDrawerOpen(true)}>
              {lt('travelInvoices.toggleCreate')}
            </Button>
          )}
        </div>

        {!hasViewDisabled && (
          <UploadImages token={token} employee={formInit.employee} travelInvoiceId={formInit._id} handleRefetchRequest={handleRefetchRequest} />
        )}

        <ImagesList token={token} employee={formInit.employee} sources={formInit?.attachments ?? []} handleRefetchRequest={handleRefetchRequest} />

        <div className='table-invoices-wrapper'>
          {/* table for RSD */}
          {dataByCurrency['RSD'] && dataByCurrency['RSD'].length && (
            <div className='wrapper'>
              <TableTopHeader stepLang={stepLang} />
              
              <Table
                dataSource={dataByCurrency['RSD']}
                columns={columns}
                pagination={false}
                bordered
                rowKey='index'
                rowClassName={rowClassName}
              />
            </div>
          )}

          {/* table for other currencies */}
          {filteredCurrencies && filteredCurrencies.length >= 1 && (
            <div className='wrapper'>
              {filteredCurrencies
                .map((currency) => (
                  <div key={currency}>
                    <TableTopHeader currency={currency} stepLang={stepLang} />

                    <Table
                      dataSource={dataByCurrency[currency]}
                      columns={columns}
                      pagination={false}
                      bordered
                      rowKey='index'
                      rowClassName={rowClassName}
                      />
                  </div>
                ))}
            </div>
          )}
        </div>

        {hasViewDisabled ? (
          <div className="sticky-actions">
            <div className="grid">
              <div className="flex">
                <StepControl currentStep={currentStep} onChangeStep={onChangeStep} />
                
                <Button type='button' onClick={handleBack}>
                  Return
                </Button>                   
              </div>
            </div>
          </div>     
        ) : null}        
      </div>

      <Drawer
        title={lt('travelInvoices.drawerTravelTitle')}
        width={720}
        onClose={onClose}
        open={drawerOpen}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form form={form} initialValues={initialValues} onFinish={onSubmit} layout='vertical'>
          <Form.Item 
            name={['travelInvoices', 'travelInvoiceCurrency']} 
            label={lt('travelInvoices.travelInvoiceCurrency')}
          >
            <Select disabled={disabled}>
              {currencyOptions.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name={['travelInvoices', 'travelInvoiceNumber']}
            label={lt('travelInvoices.travelInvoiceNumber')}
            className='custom-label'
            rules={[{ required: true, message: 'Please input invoice number!' }]}
          >
            <Input placeholder="Invoice number" disabled={disabled} />
          </Form.Item>

          <Form.Item
            name={['travelInvoices', 'travelInvoiceType']}
            label={lt('travelInvoices.travelInvoiceType')}
            className='custom-label'
            rules={[{ required: true, message: 'Please select invoice type!' }]}
          >
            <Select disabled={disabled}>
              {invoiceTypeOptions.map((item) => (
                <Select.Option key={item} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name={['travelInvoices', 'travelInvoiceDescription']}
            label={lt('travelInvoices.travelInvoiceDescription')}
            className='custom-label'
          >
            <Input placeholder="Description" disabled={disabled} />
          </Form.Item>

          <Form.Item
            name={['travelInvoices', 'travelInvoiceAmount']}
            label={lt('travelInvoices.travelInvoiceAmount')}
            className='custom-label'
            rules={[{ required: true, message: 'Please input amount!' }]}
          >
            <Input placeholder="0" type='number' disabled={disabled} />
          </Form.Item>

          <Form.Item
            name={['travelInvoices', 'isTravelInvoicePaidByCard']}
            label={lt('travelInvoices.isTravelInvoicePaidByCard')}
            className='custom-label'
          >
            <Select disabled={disabled} style={{ width: '100%' }}>
              {cardOptions.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit' disabled={disabled}>
              {lt('travelGlobal.save')}
            </Button>
            <Button onClick={onClose} style={{ marginLeft: '10px' }}>
              {lt('travelGlobal.cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>  
    </>
  );
}

function TableTopHeader({ currency, stepLang }) {
  const { lt } = useLocalTranslations(stepLang);

  return (
    <div className='table-header'>
      <h2>
        <span>{lt('travelInvoices.travelInvoiceTitle')} {' '}</span> 
        <strong style={{ fontSize: '15px' }}>{currency ?? 'RSD'}</strong>
      </h2>
    </div>
  )
}